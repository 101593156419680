
import React from "react";
import { withRouter } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from "mdbreact";
import Amplify from "../api.js";
import InfoHide from "../InfoHide";
import Guide from "./Components/Guide.js";
import './Developer.css';
// import ReportIssue from '../ReportIssue';

class Developer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            APIKey: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getAPIKey().then(response => {
            console.log('response', response)
            if (response !== null) {
                this.setState({ APIKey: response.APIKey })
            }
        }).catch(error => {
            console.log('Failed to get data ' + error);
            this.setState({ APIKey: "Key has not been generated" })
        })
    }

    async getAPIKey() {
        let apiName = 'TennantAPI';
        let path = 'manageterminals/apikey'
        let myInit = {
            headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
        }
        return await Amplify.API.get(apiName, path, myInit);
    }


    render() {
        return (
            <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>

                <MDBRow>
                    {/* <ReportIssue /> */}
                    <MDBCol md="7">
                        <InfoHide
                            info1="Developers:"
                            info2="Place an order to generate an API Key"
                        />
                        {/* <InfoBlock
                            CardHeader="Developers"
                            CardTitle="Getting started with SCP"
                            CardText="You can find your API key and relevant package downloaded on this page. Below is a set of instructions on how to get SCP set up."
                        /> */}
                    </MDBCol>

                    <MDBCol md="5" end>
                        <div style={{ padding: "10px" }}>
                            <p className="api"><MDBIcon icon="code" /> APIKEY: <span className="apiKey">{this.state.APIKey}</span></p>
                            <p className="downloadLinks"><MDBIcon  fab icon="windows" /> Windows 32 bit <a href="bin/windows_32/scanpay.zip" download><MDBBtn id="win32-btn" color="warning" size="sm">Download Client Software</MDBBtn></a></p>
                            <p className="downloadLinks"><MDBIcon  fab icon="windows" /> Windows 64 bit <a href="bin/windows_amd64/scanpay.zip" download><MDBBtn  id="win64-btn" color="warning" size="sm">Download Client Software</MDBBtn></a></p>
                            <p className="downloadLinks"><MDBIcon  fab icon="apple" /> MAC O/S (Darwin) <a href="bin/darwin_amd64/scanpay.zip" download><MDBBtn  id="mac-btn" color="warning" size="sm">Download Client Software</MDBBtn></a></p>
                            <p className="downloadLinks"><MDBIcon  fab icon="android" /> Android <a href="bin/android/scanpay.zip" download><MDBBtn id="android" color="warning" size="sm">Download Client Software</MDBBtn></a></p>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr className='mb-0' />
                <Guide />
            </MDBContainer >

        );
    };
}

export default withRouter(Developer);