import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBBtn, MDBIcon, MDBCollapse } from "mdbreact";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Amplify from "../api.js";
import { DefaultTextInput } from "../formik.js";
import InfoHide from "../InfoHide";
import StockButton from "./StockButton";
import { LinkContainer } from "react-router-bootstrap";
import routes from "../../config/routes";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./StockButton.css";
import "./GP.scss";

const Generate = async (setMessage, values, { setSubmitting }) => {
      let apiName = "TennantAPI";
      let path = "manageterminals/generatepin/" + values.tid + "/" + values.sn;
      let myInit = {
            headers: {
                  Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                        .getIdToken()
                        .getJwtToken()}`
            },
            responseType: "text"
      };
      console.log("Calling generate PIN", values);
      return await Amplify.API.post(apiName, path, myInit)
            .then(response => {
                  console.log("Generate PIN returned ", response);
                  setMessage(response, values.sn);
                  setSubmitting(false);
            })
            .catch(error => {
                  console.log("generatePIN Error:", error);
                  console.log("generatePIN Response:", error.response.data);
                  setMessage("Cannot Generate PIN " + error.response.data);
                  setSubmitting(false);
            });
};

class GeneratePIN extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  message: "",
                  serial: "",
                  Tid: "",
                  iv: { tid: "Loading...", sn: "Loading..." }
            };
            this.setMessage = this.setMessage.bind(this);
            this.getTerminalInfo = this.getTerminalInfo.bind(this);
            this.RemoveSerial = this.RemoveSerial.bind(this);
            this.removeSN = this.removeSN.bind(this);
            this.confirm = this.confirm.bind(this);
            this.alertDelete = this.alertDelete.bind(this);
            this.Submit = this.Submit.bind(this);
      }
      setMessage(message, serial) {
            this.setState({ message: message });
            this.setState({ serial: serial });
      }

      Submit = async (setMessage, values, { setSubmitting }) => {
            //Check if the serial Number is valid
            if (values.sn === "") {
                  setMessage("Serial Number cannot be blank", values.sn);
                  setSubmitting(false);
                  return;
            }
            let apiName = "TennantAPI";
            let path = "manageterminals/checkterminal/" + values.sn;
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                              .getIdToken()
                              .getJwtToken()}`
                  },
                  responseType: "text"
            };
            return await Amplify.API.get(apiName, path, myInit)
                  .then(response => {
                        switch (response) {
                              case "Found":
                                    Generate(setMessage, values, { setSubmitting });
                                    setMessage("", values.sn)// Needs to change
                                    this.setState({ iv: { ...this.state.iv, sn: values.sn } }, function () { console.log("IV state", this.state.iv) })
                                    break;
                              default:
                                    setMessage("Serial Not found");
                                    setSubmitting(false);
                        }
                  })
                  .catch(function (error) {
                        console.log("generatePIN Error:", error);
                        setSubmitting(false);
                  });
      };

      confirm() {
            confirmAlert({
                  customUI: ({ onClose }) => {
                        return (
                              <div className='conf-check'>
                                    <h1>Remove Serial</h1>
                                    <p>Removing this serial from a live terminal will stop it from trading. Do you wish to proceed?</p>
                                    <MDBBtn
                                          color="success"
                                          onClick={() => {
                                                this.removeSN();
                                                onClose();
                                          }}
                                    >
                                          Yes
                                    </MDBBtn>
                                    <MDBBtn color="danger" onClick={onClose}>No</MDBBtn>
                              </div>
                        );
                  }
            });
      }

      alertDelete() {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const user = params.get("user");
            return (
                  <MDBContainer>
                        <MDBAlert color="warning" dismiss>
                              <strong>Confirmation</strong>
                              {user} has been deleted
                        </MDBAlert>
                  </MDBContainer>
            );
      }

      async getTerminal() {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const Tid = params.get("terminal");
            let apiName = "TennantAPI";
            let path = "manageterminals/tids/" + Tid;
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.get(apiName, path, myInit);
      }

      async RemoveSerial() {
            let apiName = 'TennantAPI';
            let path = 'manageterminals/removefromtid/' + this.state.serial;
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },

            }
            console.log('Calling ', apiName, path, myInit)
            return await Amplify.API.post(apiName, path, myInit).catch(error => {
                  console.log("Error" + error)
            });
      }

      removeSN() {
            this.RemoveSerial().then(this.setState({ iv: { ...this.state.iv, sn: "Removed" } }, function () { this.setMessage(""), console.log("SN State Removed", this.state.iv) }));
      }

      getTerminalInfo() {
            this.getTerminal()
                  .then(response => {
                        this.setState({
                              Tid: response.Tid,
                              serial: response.SerialNum,
                        }, function () { console.log("SERIAL", this.state.serial) })
                        this.setState({ iv: { ...this.state.iv, sn: this.state.serial, tid: this.state.Tid } }, function () { console.log("IV state", this.state.iv) })
                  })
                  .catch(error => {
                        console.log("Failed to get TID data " + error);
                  });

      }

      componentDidMount() {
            window.scrollTo(0, 0)
            this.getTerminalInfo();
      }

      toggleCollapse = collapseID => () => {
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));
      };

      render() {
            return (
                  <MDBContainer
                        style={{ paddingTop: "50px", minHeight: "73.65vh" }}
                  >
                        <MDBRow>
                              <InfoHide info1="Ensure there is a Serial No. then press submit for a PIN" />
                              <MDBBtn
                                    id="more-info-btn"
                                    className="InfoButton"
                                    color="info"
                                    onClick={this.toggleCollapse(
                                          "basicCollapse"
                                    )}
                                    style={{ marginBottom: "1rem" }}
                              >
                                    <MDBIcon
                                          icon="plus-circle"
                                          className="buttonIcons"
                                    />
                                    More Info
                              </MDBBtn>
                              <StockButton />
                              {this.state.serial !== "" ? (<MDBBtn
                                    color="danger"
                                    className="RemoveButton"
                                    onClick={() => {
                                          this.confirm();
                                    }}

                              ><MDBIcon icon="minus-circle" /><sp /> Remove Serial </MDBBtn>)
                                    : (<p></p>)}
                              <MDBCollapse
                                    id="basicCollapse"
                                    isOpen={this.state.collapseID}
                              >
                                    <MDBContainer>
                                          <p>
                                                The correct process for removing a serial from a terminal:
                                          </p>
                                          <ol>
                                                <li>Press the remove serial button and press yes when prompted</li>
                                                <li>Disconnect the old PED</li>
                                                <li>Delete the contents of the PED database</li>
                                                <ul>
                                                      <li>On Android the name of the PED database is 'emv'</li>
                                                      <li>On Windows the name of the PED database is 'db'</li>
                                                </ul>
                                                <li>Plug in the new PED</li>                                                
                                                <li>Enter in the new serial in the Serial Number field below</li>
                                                <li>Press SUBMIT and get your OTP</li>
                                                <li>Enter your OTP into the PED when prompted</li>
                                          </ol>
                                    </MDBContainer>
                              </MDBCollapse>
                        </MDBRow>

                        <MDBRow>
                              <Formik
                                    initialValues={this.state.iv}
                                    enableReinitialize={true}
                                    validationSchema={Yup.object({})}
                                    onSubmit={(values, { setSubmitting }) => {
                                          setTimeout(() => {
                                                this.Submit(this.setMessage, values, {
                                                      setSubmitting
                                                });
                                          }, 400);
                                    }}
                              >
                                    <Form style={{ display: "grid", paddingLeft: "15px" }}>
                                          {this.state.iv.sn === "" || this.state.iv.sn === "Removed" ? (
                                                <DefaultTextInput
                                                      label="Serial Number"
                                                      name="sn"
                                                      type="text"
                                                />
                                          ) : (
                                                <DefaultTextInput
                                                      label="Serial Number"
                                                      name="sn"
                                                      type="text"
                                                      disabled={true}
                                                />
                                          )}
                                          <DefaultTextInput
                                                label="TID"
                                                name="tid"
                                                type="text"
                                                disabled={true}
                                          />
                                          <MDBRow
                                                center
                                                style={{
                                                      display: "inline",
                                                      marginLeft: "0px",
                                                      paddingLeft: "0px"
                                                }}
                                          >
                                                <MDBBtn
                                                      color="success"
                                                      type="submit"
                                                      style={{ marginLeft: "0px" }}
                                                >
                                                      Submit
                                                </MDBBtn>
                                                <LinkContainer to={routes.manageTerminals} >
                                                      <MDBBtn color="danger">
                                                            <MDBIcon icon="angle-left" /> Back
                                                      </MDBBtn>
                                                </LinkContainer>
                                          </MDBRow>
                                    </Form>
                              </Formik>

                              <p>{this.state.message}</p>
                        </MDBRow>
                  </MDBContainer>
            );
      }
}

export default withRouter(GeneratePIN);
