import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import './MyOrganisationStyle.css';
import Amplify from "../api.js";
import SCPTable from "../SCPTable.js";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";
import InfoHide from '../InfoHide';

class MyOrganisation extends Component {
      constructor(props) {
            super(props);
            this.props.silence()  //Clear Error Messages
            this.state = {
                  data: [],
            };
      }

      componentDidMount() {
            window.scrollTo(0, 0)
            this.getUsers();
      }

      async getAllUsers() {
            let apiName = 'TennantAPI';
            let path = 'manageuserpool';
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      getUsers() {
            this.getAllUsers().then(response => {
                  console.log('response', response)
                  if (response === null) {
                        this.setState({ data: [] })
                  } else {
                        var array = [], item = [];
                        var i, j;
                        for (i = 0; i < response.length; i++) {
                              var permissions = ""
                              for (j = 0; j < response[i].permissions.length; j++) {
                                    permissions = permissions + response[i].permissions[j] + " ";
                              }
                              item = [
                                    { text: response[i].emailAddress },
                                    { text: response[i].status },
                                    { text: permissions },
                                    { text: response[i].userName },
                                    { text: "Edit", link: routes.editUser + '?user=' + response[i].userName },
                              ];
                              array.push(item);

                        }


                        this.setState({ data: array })
                  }
            }).catch(error => { console.log('Failed to get data ' + error) })
      }

      async deleteUser(username) {
            let apiName = 'TennantAPI';
            let path = 'manageuserpool/user/' + username;
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
            }
            console.log('Calling ', apiName)
            return await Amplify.API.delete(apiName, path, myInit).then(() => { console.log('refresh'); }).catch(function (error) {
                  console.log(JSON.stringify(error))
            }
            )
      }

      render() {
            const header = ["Email", "Status", "Permissions", "Username", ""];
            return (
                  <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
                              <MDBRow>
                                    <InfoHide
                                          info1="Manage Users: "
                                          info2="Manage, edit and view your users"
                                    />
                                    <LinkContainer className="NavText" to={routes.editUser} >
                                          <MDBBtn id="add-user-btn" className="AddNewUserButton" color="green" size="sm" type="button" value="Submit"><MDBIcon icon="user-plus" style={{paddingRight:"2px"}}/>Add</MDBBtn>
                                    </LinkContainer>
                              </MDBRow>
                        <MDBRow style={{ marginBottom: "10px" }}>
                              <MDBCol>
                                    <SCPTable header={header} table={this.state.data} />
                              </MDBCol>
                        </MDBRow>
                  </MDBContainer >
            );
      }
};

export default MyOrganisation
