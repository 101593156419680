import React from "react";
import { MDBContainer, MDBIcon } from "mdbreact";

export const Component = () => (
      <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
            <div className="NotFound">
                  <MDBIcon icon="ban" size="5x" className="red-text pr-3"/>
                  <br></br>
                  <br></br>
                  <h3>
                        Sorry, you have insufficient permissions to view this
                        page
                  </h3>
            </div>
      </MDBContainer>
);

export default Component;
