import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Component from "./AppContainer";

const Connected = connect(
      ({ loading }) => ({ loading }),
      ({ auth: { isAuthenticated } }) => ({ isAuthenticated })
)(Component);

export default withRouter(Connected);
