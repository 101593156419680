import React, { Component } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
      DefaultTextInput,
      DefaultTimePicker,
      DefaultDateInput
} from "../formik.js";
import {
      MDBBtn,
      MDBRow,
      MDBCol,
      MDBCollapse,
      MDBCard,
      MDBCardBody,
      MDBCollapseHeader,
      MDBTooltip,
      MDBIcon
} from "mdbreact";
import "./Transactions.css";

const validationSchema = Yup.object().shape({
      eposTransactionNumber: Yup.string().min(
            1,
            "EPOS TXN must be longer than 1 character"
      )
            .max(6, "EPOS TXN cannot be longer than 6 characters"),
      authCode: Yup.string().max(
            6,
            "Auth code cannot be longer than 6 characters"
      ),
      tid: Yup.string().length(8, "Terminal ID must be 8 characters long"),
      maskedPAN: Yup.string().max(16, "Masked PAN cannot be longer than 16 characters"),
      transType: Yup.string().max(10, "Trans Type cannot be longer than 10 characters"),
      transResult: Yup.string().max(14, "Trans Result cannot be longer than 14 characters"),
      txnAmt: Yup.string().max(10, "Amount cannot be longer than 10 characters"),

      merchantName: Yup.lazy(() => Yup.string().when(['endTime', 'startTime', 'startDate', 'endDate'], {
            is: (endTime, startTime, startDate, endDate) => endDate && endDate.length > 0 || endTime && endTime.length > 0 || startTime && startTime.length > 0 || startDate && startDate.length > 0,
            then: Yup.string().required("Needed when doing an advanced search"),
            otherwise: Yup.string().max(
                  50,
                  "Max 50 Characters"
            )
      })),
      startTime: Yup.string().when(['endDate', 'startDate', 'endTime'], {
            is: (endDate, startDate, endTime) => endDate && endDate.length > 0 || startDate && startDate.length > 0 || endTime && endTime.length > 0,
            then: Yup.string().required("Needed when doing an advanced search"),
            otherwise: Yup.string().max(
                  50,
                  "Max 50 Characters"
            )

      }),
      startDate: Yup.string().when(['endDate', 'startTime', 'endTime'], {
            is: (endDate, startTime, endTime) => endDate && endDate.length > 0 || startTime && startTime.length > 0 || endTime && endTime.length > 0,
            then: Yup.string().required("Needed when doing an advanced search"),
            otherwise: Yup.string().max(
                  50,
                  "Max 50 Characters"
            )
      }),
      endTime: Yup.lazy(() => Yup.string().when(['endDate', 'startTime', 'startDate'], {
            is: (endDate, startTime, startDate) => endDate && endDate.length > 0 || startTime && startTime.length > 0 || startDate && startDate.length > 0,
            then: Yup.string().required("Needed when doing an advanced search"),
            otherwise: Yup.string().max(
                  14,
                  "Max 14 Characters"
            )
      })),
      endDate: Yup.lazy(() => Yup.string().when(['endTime', 'startTime', 'startDate', 'endDate'], {
            is: (endTime, startTime, startDate, endDate) => endTime && endTime.length > 0 || startTime && startTime.length > 0 || startDate && startDate.length > 0 && startDate && startDate === endDate,
            then: Yup.string().required("Needed when doing an advanced search"),
            otherwise: Yup.string().max(
                  14,
                  "Max 14 Characters"
            )
      })),


}, [['startTime', 'startDate', 'endTime'], ['endDate', 'startTime', 'startDate'], ['endDate', 'startTime', 'startDate'], ['endTime', 'startTime', 'startDate', 'endDate']])
class Filtering extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  iv: {},
                  endDate: "",
                  advanced: false
            };
            this.setValues = this.setValues.bind(this);
      }

      componentDidMount() {
            this.setValues();
      }

      setValues() {
            let iv = this.props.params;
            this.setState({ iv: iv }, function () {
                  console.log("IV", this.state.iv);
            });
      }

      toggleCollapse = collapseID => () =>
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));

      render() {
            const { collapseID } = this.state;
            return (
                  <div className="FilterBar">

                        <Formik
                              enableReinitialize
                              initialValues={this.state.iv}
                              validationSchema={validationSchema}
                              // validationSchema={function(){if(startDate !== ""){validateYupSchema=validationSchemaNoAdvanced}}}
                              // onReset={(values, { resetForm }) => resetForm()}
                              onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                          this.props.action(values, {
                                                setSubmitting
                                          });
                                    }, 400);
                              }}
                        >
                              <Form>
                                    <MDBRow>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="epos-txn-field"
                                                      label="EPOS TXN"
                                                      name="eposTransactionNumber"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="auth-code-txn-field"
                                                      label="Auth Code"
                                                      name="authCode"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="merchant-name-field"
                                                      label="Merchant Name"
                                                      name="merchantName"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="terminal-id-field"
                                                      label="Terminal ID"
                                                      name="tid"
                                                      type="text"
                                                />
                                          </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="masked-PAN-field"
                                                      label="Masked PAN"
                                                      name="maskedPAN"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="txn-amt-field"
                                                      label="Txn Amt"
                                                      name="txnAmt"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="transaction-type-field"
                                                      label="Trans Type"
                                                      name="transType"
                                                      type="text"
                                                />
                                          </MDBCol>
                                          <MDBCol>
                                                <DefaultTextInput
                                                      id="trans-result-field"
                                                      label="Trans Result"
                                                      name="transResult"
                                                      type="text"
                                                />
                                          </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                          <MDBCol md="9">
                                                <MDBCard className="mt-3">
                                                      <MDBCollapseHeader
                                                            className="av-header"
                                                            onClick={this.toggleCollapse(
                                                                  "collapse1"
                                                            )}
                                                      >
                                                            Advanced Search <span></span>
                                                            <i
                                                                  className={
                                                                        collapseID ===
                                                                              "collapse1"
                                                                              ? "fa fa-angle-down rotate-icon"
                                                                              : "fa fa-angle-down"
                                                                  }
                                                            />
                                                            {" "}
                                                            <MDBTooltip
                                                                  domElement
                                                                  tag="span"
                                                                  material
                                                                  placement="top"
                                                            >
                                                                  <span><MDBIcon icon="info-circle" size="lg" /></span>
                                                                  <span>
                                                                        <p>Please enter all advanced search fields and Merchant Name for Advanced Search</p>
                                                                  </span>
                                                            </MDBTooltip>
                                                      </MDBCollapseHeader>
                                                      <MDBCollapse
                                                            id="collapse1"
                                                            isOpen={collapseID}
                                                      >
                                                            <MDBCardBody>
                                                                  <MDBRow className="as-row">
                                                                        <MDBCol>
                                                                              <DefaultTimePicker
                                                                                    id="start-time"
                                                                                    label="*Start Time"
                                                                                    name="startTime"
                                                                              />
                                                                        </MDBCol>
                                                                        <MDBCol>
                                                                              <DefaultDateInput
                                                                                    id="start-Date"
                                                                                    label="*Start Date"
                                                                                    name="startDate"
                                                                                    format="DD-MM-YYYY"
                                                                              />
                                                                        </MDBCol>
                                                                        <MDBCol>
                                                                              <DefaultTimePicker
                                                                                    id="end-time"
                                                                                    label="*End Time"
                                                                                    name="endTime"
                                                                              />
                                                                        </MDBCol>
                                                                        <MDBCol>
                                                                              <DefaultDateInput
                                                                                    id="end-Date"
                                                                                    label="*End Date"
                                                                                    name="endDate"
                                                                                    format="DD-MM-YYYY"
                                                                              />
                                                                        </MDBCol>
                                                                  </MDBRow>
                                                            </MDBCardBody>
                                                      </MDBCollapse>
                                                </MDBCard>
                                          </MDBCol>
                                          <MDBCol className="button-row" md="3" middle>
                                                <div className="btn-group">
                                                      <MDBBtn
                                                            id="submit-btn"
                                                            color="success"
                                                            type="submit"
                                                            size="sm"
                                                            className="SubmitButton"
                                                      >
                                                            Submit
                                                      </MDBBtn>
                                                      <MDBBtn
                                                            id="submit-btn-clear"
                                                            color="danger"
                                                            type="reset"
                                                            size="sm"
                                                            className="ClearButton"
                                                      >
                                                            Clear
                                                      </MDBBtn>
                                                      <MDBBtn
                                                            id="submit-btn-reset"
                                                            color="danger"
                                                            size="sm"
                                                            className="reset-button"
                                                            id="second-button"
                                                            onClick={e => {
                                                                  this.props.clear(e);
                                                            }}
                                                      >
                                                            Reset
                                                      </MDBBtn>
                                                </div>
                                          </MDBCol>
                                    </MDBRow>
                                    <br />
                              </Form>
                        </Formik>
                  </div>
            );
      }
}

export default Filtering;
