import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import SignUp from "./SignUp";
import validationSchema from "./validationSchema";
import SignUpConfirmation from "./SignUpConfirmation";

export class SignUpContainer extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  organisationName: "",
                  username: "",
                  email: "",
                  password: "",
                  confirmationCode: "",
                  awaitingConfirmation: false
            };
            this.onSuccess = this.onSuccess.bind(this);
      }


      handleChange = ({ target: { id, value } }) => {
            this.setState({ [id]: value });
      };

      onSuccess = () => {   //Call back sent to signup to set if signup call is successfull
            this.setState({
                  awaitingConfirmation: true
            });
      }

      // Added for username for ucognito
      onSubmit = async values => {
            this.setState(values)  // Store values for onSubmit conformation
            values['onSuccess'] = this.onSuccess
            await this.props.signUp(values);
      };
      // Added for username for ucognito
      onSubmitConfirmation = async event => {
            event.preventDefault();
            const { organisationName, username, email, password, confirmationCode } = this.state;
            this.props.confirmSignUp({ organisationName, username, email, password, confirmationCode });
      };

      validateConfirmationForm() {
            return this.state.confirmationCode.length > 0;
      }

      render() {
            return this.state.awaitingConfirmation ? (
                  <SignUpConfirmation
                        formIsValid={this.validateConfirmationForm()}
                        handleSubmit={this.onSubmitConfirmation}
                        handleChange={this.handleChange}
                        confirmationCode={this.state.confirmationCode}
                  />
            ) : (
                        <Formik
                              onSubmit={this.onSubmit}
                              component={SignUp}
                              initialValues={{
                                    organisationName: "",
                                    username: "",
                                    email: "",
                                    password: "",
                                    confirmPassword: ""
                              }}
                              validationSchema={validationSchema}
                        />
                  );
      }
}

SignUpContainer.propTypes = {
      signUp: PropTypes.func.isRequired,
      confirmSignUp: PropTypes.func.isRequired
};

export default SignUpContainer;