import React from "react";
import { withRouter } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCollapse } from "mdbreact";
import Amplify from "../api.js";
import SCPTable from "../SCPTable.js";
import routes from "../../config/routes";
import "./Batches.css";
import InfoHide from "../InfoHide";

class Batches extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  index: "",
                  pages: ""
            };
            this.exportCSV = this.exportCSV.bind(this);
            this.getTransactions = this.getTransactions.bind(this);
      }

      // exportCSV sends an API request and updates state.data with the result
      exportCSV = () => {
            this.getAllTransactions(true).then(response => {
                  const url = window.URL.createObjectURL(new Blob([response]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "file.csv");
                  document.body.appendChild(link);
                  link.click();
            });
      };

      componentDidMount() {
            this.getTransactions();
      }

      componentDidUpdate = prevProps => {
            if (this.props.location.search !== prevProps.location.search) {
                  this.getTransactions();
            }
      };

      async getAllTransactions(csv) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const settlement = params.get("settlement");
            const acquirer = params.get("acquirer");
            const mid = params.get("mid");
            let apiName = "TennantAPI";
            let path = "manageterminals/batch";
            if (settlement !== null) {
                  path += `/${settlement}`;
                  if (acquirer !== null) {
                        path += `/${acquirer}`;
                        if (mid !== null) {
                              path += `/${mid}`;
                        }
                  }
            }
            var myInit;
            if (csv === true) {
                  myInit = {
                        headers: {
                              Authorization: `Bearer ${(
                                    await Amplify.Auth.currentSession()
                              )
                                    .getIdToken()
                                    .getJwtToken()}`
                        },
                        queryStringParameters: {
                              csv: "true"
                        },
                        responseType: "text"
                  };
            } else {
                  myInit = {
                        headers: {
                              Authorization: `Bearer ${(
                                    await Amplify.Auth.currentSession()
                              )
                                    .getIdToken()
                                    .getJwtToken()}`
                        },
                        queryStringParameters: { max: "1000" }
                  };
            }
            console.log("Calling ", path);
            return await Amplify.API.get(apiName, path, myInit);
      }

      getTransactions() {
            this.getAllTransactions(false)
                  .then(response => {
                        this.setState({ index: response.PageIndex });
                        this.setState({ pages: response.PageCount });
                        if (response === null) {
                              this.setState({ data: [] });
                        } else {
                              var array = [],
                                    item = [];
                              var i;
                              for (i = 0; i < response.Data.length; i++) {
                                    let mid = {};
                                    let tid = {};
                                    let transactions = {};
                                    if (response.Data[i].Mid === "") {
                                          mid = {
                                                text: "Batches by Mid",
                                                link: `${routes.batches}?acquirer=${response.Data[i].Acquirer}&settlement=${response.Data[i].SettlementNum}`
                                          };
                                          transactions = {
                                                text: "Transactions",
                                                link: `${routes.transactions}?acquirer=${response.Data[i].Acquirer}&settlement=${response.Data[i].SettlementNum}`
                                          };
                                    } else {
                                          mid = { text: response.Data[i].Mid };
                                          if (response.Data[i].Tid === "") {
                                                tid = {
                                                      text: "Batches by Tid",
                                                      link: `${routes.batches}?acquirer=${response.Data[i].Acquirer}&settlement=${response.Data[i].SettlementNum}&mid=${response.Data[i].Mid}`
                                                };
                                                transactions = {
                                                      text: "Transactions",
                                                      link: `${routes.transactions}?acquirer=${response.Data[i].Acquirer}&settlement=${response.Data[i].SettlementNum}&mid=${response.Data[i].Mid}`
                                                };
                                          } else {
                                                tid = {
                                                      text: response.Data[i].Tid
                                                };
                                                transactions = {
                                                      text: "Transactions",
                                                      link: `${routes.transactions}?acquirer=${response.Data[i].Acquirer}&settlement=${response.Data[i].SettlementNum}&mid=${response.Data[i].Mid}&tid=${response.Data[i].Tid}`
                                                };
                                          }
                                    }
                                    item = [
                                          {
                                                text:
                                                      response.Data[i]
                                                            .SettlementNum
                                          },
                                          { text: response.Data[i].Acquirer },
                                          mid,
                                          tid,
                                          { date: response.Data[i].UpdateDate },
                                          {
                                                currency:
                                                      response.Data[i].NetAmount
                                          },
                                          {
                                                currency:
                                                      response.Data[i]
                                                            .DebitValue
                                          },
                                          {
                                                currency:
                                                      response.Data[i]
                                                            .CreditValue
                                          },
                                          transactions
                                          // { text: response[i].Data[j].TransTime.slice(4, 6) + "/" + response[i].Data[j].TransTime.slice(2, 4) + "/" + response[i].Data[j].TransTime.slice(0, 2) + " " + response[i].Data[j].TransTime.slice(6, 8) + ":" + response[i].Data[j].TransTime.slice(8, 10) },
                                    ];
                                    array.push(item);
                              }
                              this.setState({ data: array });
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get data " + error);
                  });
      }

      toggleCollapse = collapseID => () => {
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));
      };

      render() {
            const header = [
                  "SettlementNum",
                  "Acquirer",
                  "Mid",
                  "Tid",
                  "Update Date",
                  "Net Amount",
                  "Debit Value",
                  "Credit Value",
                  ""
            ];

            return (
                  <MDBContainer
                        style={{ paddingTop: "50px", minHeight: "73.65vh" }}
                  >
                        <MDBRow>
                              <InfoHide
                                    info1="Batches: "
                                    info2="View and search your batches, with the option to export"
                              />
                              <MDBBtn
                                    id="more-info-btn"
                                    className="InfoButton"
                                    color="primary"
                                    onClick={this.toggleCollapse(
                                          "basicCollapse"
                                    )}
                                    style={{ marginBottom: "1rem" }}
                              >
                                    <MDBIcon
                                          icon="plus-circle"
                                          className="buttonIcons"
                                    />
                                    More Info
                              </MDBBtn>
                              <MDBBtn
                                    id="export-csv-btn"
                                    color="warning"
                                    size="sm"
                                    className="ExportButton"
                                    onClick={this.exportCSV}
                              >
                                    <MDBIcon
                                          icon="file-export"
                                          style={{ paddingRight: "3px" }}
                                    />
                                    Export CSV
                              </MDBBtn>
                        </MDBRow>
                        <MDBRow>
                              <MDBCollapse
                                    id="basicCollapse"
                                    isOpen={this.state.collapseID}
                              >
                                    <MDBContainer>
                                          <p>
                                                The table below shows a list of
                                                settled batches of transactions.
                                                To view unsettled transactions,
                                                navigate to the “Transactions”
                                                tab. You can use the “Export
                                                CSV” button to export all the
                                                visible batches as a
                                                spreadsheet. To view all the
                                                transactions inside a singular
                                                batch, press the “Transactions”
                                                button on the batch line you
                                                wish to view. You can also view
                                                the Mid and Tid of each batch by
                                                using the “Batches by Mid” and
                                                “Batches by Tid” buttons
                                                respectively.
                                          </p>
                                    </MDBContainer>
                              </MDBCollapse>
                        </MDBRow>
                        <MDBRow>
                              <MDBCol>
                                    <SCPTable
                                          header={header}
                                          table={this.state.data}
                                    />
                              </MDBCol>
                        </MDBRow>
                  </MDBContainer>
            );
      }
}

export default withRouter(Batches);
