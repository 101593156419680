import React from "react";
import Amplify from "../../components/api";
import SCPTablePages from "../SCPTablePages.js";
import {
      MDBModalBody,
      MDBModalHeader,
      MDBBtn,
      MDBModal,
      MDBIcon
} from "mdbreact";
import "./GP.scss";


class Stock extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  terminal: "",
                  enteredSerial: false,
                  limit: "5",
                  modal: false
            };
            this.getTerminalsList = this.getTerminalsList.bind(this);
      }

      async getTerminalALL() {
            let apiName = "TennantAPI";
            let path = "manageterminals/listterminals";

            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            return await Amplify.API.get(apiName, path, myInit);
      }

      getTerminalsList() {
            this.getTerminalALL()
                  .then(response => {
                        // console.log("response", response);
                        if (response === null) {
                              this.setState({ date: [] });
                        } else {
                              let array = [],
                                    item = [];
                              var i;
                              for (i = 0; i < response.length; i++) {
                                    item = [
                                          { text: response[i].SerialNum },
                                          {
                                                text: response[
                                                      i
                                                ].CreateDate.slice(0, 10)
                                          },
                                          { text: response[i].TerminalType },
                                          {
                                                text: response[i].PINDate.slice(
                                                      0,
                                                      10
                                                )
                                          }
                                    ];
                                    array.push(item);
                              }
                              this.setState({ data: array });
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get terminal" + error);
                  });
      }

      scrollToTop = () => window.scrollTo(0, 0);

      toggle = () => {
            this.getTerminalsList();
            this.setState({
                  modal: !this.state.modal
            });
      };

      render() {
            const header = [
                  "SerialNum",
                  "Create Date",
                  "TerminalType",
                  "PIN Date"
            ];

            return (
                  <>
                        <MDBBtn
                              color="info"
                              onClick={this.toggle}
                              className="AddButton"
                        >
                              
                              <MDBIcon
                                    icon="plus-circle"
                                    className="buttonIcons"
                              /><sp />Show Stock
                        </MDBBtn>

                        <MDBModal
                              isOpen={this.state.modal}
                              toggle={this.toggle}
                              backdrop={false}
                              full-height
                              position="right"
                        >
                              <MDBModalHeader toggle={this.toggle}>
                                    PED Stock
                              </MDBModalHeader>
                              <MDBModalBody>
                                    <SCPTablePages
                                          header={header}
                                          table={this.state.data}
                                    />
                              </MDBModalBody>
                        </MDBModal>
                  </>
            );
      }
}

export default Stock;
