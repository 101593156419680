// import "./Products.css";
import React from "react";

import { withRouter } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import TeamPage from './components/TeamPage';

class Products extends React.Component {

      render() {

            return (
                  <React.Fragment>
                        <MDBContainer style={{ minHeight: "73.65vh" }} >
                              <MDBRow >
                                    <MDBCol>
                                          <TeamPage />
                                    </MDBCol>
                              </MDBRow >
                        </MDBContainer>
                  </React.Fragment>
            );
      }
}

export default withRouter(Products);


