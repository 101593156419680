import React, { Component } from 'react';
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from 'mdbreact';

class FAQPEDloggedout extends Component {
  state = {
    collapseID: 'collapse1'
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
  
  render() {
    
    return (
        <MDBContainer
          className='accordion md-accordion accordion-3 z-depth-1-half'
          style={{ marginBottom: "15px"}}
        >
          <div className='d-flex justify-content-center pt-5'>
            <MDBIcon far icon="question-circle" className='mx-3' size='2x' />
            <MDBIcon icon="laptop" className='mx-3' size='2x' />
          </div>
          <h2 className='text-center text-uppercase py-4 px-3'  id="Website">
            Website FAQ
          </h2>

          <hr className='mb-0' />

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse2')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              How do I get access to the API?
        
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse2'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse2' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                You can access the API by signing up for an account and logging in to this website. Once you are logged in, navigate to the Developer page and find the most appropriate download for you depending on the operating system you are using. Click on the download link and extract the contents of the folder to somewhere on your system. Finally, run the API exe and you can get started!
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        
          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse10')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I have forgotten my login password. How can I get a new one? 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse10'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse10' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                On the login screen, just beneath the big blue login button is a “Forgot Password” link. Click it and it will take you to a password where you can enter your organisation name and username. A reset password link will then be sent to the email address associated with that username. 
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard> 
        </MDBContainer>
    );
  }
}

export default FAQPEDloggedout;