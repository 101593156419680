import React, { Component } from "react";
import {
      MDBContainer,
      MDBCollapse,
      MDBCard,
      MDBCardBody,
      MDBCollapseHeader,
      MDBIcon
} from "mdbreact";

class Training extends Component {
      state = {
            collapseID: "collapse1"
      };

      toggleCollapse = collapseID => () =>
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));

      render() {
            return (
                  <MDBContainer
                        style={{
                              minHeight: "73.65vh",
                              marginTop: "15px",
                              marginBottom: "15px"
                        }}
                  >
                        <div className="d-flex justify-content-center pt-5">
                              <MDBIcon
                                    far
                                    icon="question-circle"
                                    className="mx-3"
                                    size="2x"
                              />
                              <MDBIcon
                                    icon="laptop"
                                    className="mx-3"
                                    size="2x"
                              />
                        </div>
                        <h2
                              className="text-center text-uppercase py-4 px-3"
                              id="Website"
                        >
                              Training
                        </h2>

                        <hr className="mb-0" />

                        <MDBCard>
                              <MDBCollapseHeader
                                    onClick={this.toggleCollapse("collapse2")}
                                    tag="h3"
                                    tagClassName="blue-text d-flex justify-content-between align-items-center"
                              >
                                    POS Tamper Training Program
                                    <MDBIcon
                                          icon={
                                                this.state.collapseID ===
                                                "collapse2"
                                                      ? "angle-up"
                                                      : "angle-down"
                                          }
                                          className="blue-text"
                                          size="2x"
                                    />
                              </MDBCollapseHeader>
                              <MDBCollapse
                                    id="collapse2"
                                    isOpen={this.state.collapseID}
                              >
                                    <MDBCardBody className="pt-0">
                                          <p>
                                                Merchants should ensure their
                                                respective payment systems and
                                                infrastructures are secure.
                                                Merchants are the first line of
                                                defence for POS fraud. Merchants
                                                can achieve appropriate security
                                                and trust levels at the point of
                                                sale by considering all the
                                                factors that can influence
                                                overall security in their
                                                terminal environment and taking
                                                the necessary countermeasures
                                                detailed in the document below.{" "}
                                          </p>
                                          <p>
                                                <a
                                                      href="/POS-Tampering-Training-Program.pdf"
                                                      download
                                                >
                                                      POS Tamper Training Program
                                                      <MDBIcon style={{marginLeft:"5px"}} icon="file-download" />
                                                </a>
                                          </p>
                                    </MDBCardBody>
                              </MDBCollapse>
                        </MDBCard>

                        <MDBCard>
                              <MDBCollapseHeader
                                    onClick={this.toggleCollapse("collapse10")}
                                    tag="h3"
                                    tagClassName="blue-text d-flex justify-content-between align-items-center"
                              >
                                    Portal Training Documents:
                                    <MDBIcon
                                          icon={
                                                this.state.collapseID ===
                                                "collapse10"
                                                      ? "angle-up"
                                                      : "angle-down"
                                          }
                                          className="blue-text"
                                          size="2x"
                                    />
                              </MDBCollapseHeader>
                              <MDBCollapse
                                    id="collapse10"
                                    isOpen={this.state.collapseID}
                              >
                                    <MDBCardBody className="pt-0">
                                          <p>In Development <MDBIcon style={{marginLeft:"5px"}} icon="hammer" /></p>
                                    </MDBCardBody>
                              </MDBCollapse>
                        </MDBCard>
                  </MDBContainer>
            );
      }
}

export default Training;
