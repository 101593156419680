import React, { Component } from 'react';
import {
  MDBContainer,
  MDBIcon,
} from 'mdbreact';
import Markdown from 'react-markdown';
import { cdpreadme } from '../../../scp-exports.js';
import './Guide.css';

import HeadingRenderer from './HeadingRenderer'
import ImgRenderer from './ImgRenderer'

class Guide extends Component {
  state = {
    collapseID: 'collapse0'
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  render() {
    return (
      <MDBContainer>
        <div className='d-flex justify-content-center pt-5'>
          <MDBIcon icon="info-circle" size="3x" />
        </div>
        <h2 className='text-center text-uppercase py-4 px-3'>
          Getting Started
          </h2>

        <hr className='mb-0' />


         <Markdown
          children={cdpreadme}
          components={{ heading: HeadingRenderer, img: ImgRenderer 
          }}
        />




      </MDBContainer>
    );
  }
}

export default Guide;