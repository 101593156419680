import * as yup from "yup";
import { passwordRegex, orgNameRegex } from "../../config";
import {
      tooShort,
      tooLong,
      requiredField,
      invalidFormat,
      doesNotMatch,
      invalidPassword,
      invalidOrgName,
      invalidUsername
} from "../../config/messages";


export default yup.object().shape({
      organisationName: yup
            .string()
            .required(requiredField)
            .min(4, tooShort(4))
            .max(50, tooLong(50))
            .matches(orgNameRegex, invalidOrgName),
      username: yup
            .string()
            .required(requiredField)
            .min(3, tooShort(3))
            .max(50, tooLong(50))
            .matches(orgNameRegex, invalidUsername),
      email: yup
            .string()
            .email(invalidFormat)
            .required(requiredField)
            .max(255),
      confirmPassword: yup
            .mixed()
            .required(requiredField)
            .oneOf([yup.ref("password")], doesNotMatch("password")),
      password: yup
            .string()
            .required(requiredField)
            .min(8, tooShort(8))
            .max(255, tooLong(255))
            .matches(passwordRegex, invalidPassword)
});

