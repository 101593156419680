import "./NotFound.css";
import React from "react";
import { MDBContainer } from 'mdbreact';

export const Component = () => (
      <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
            <div className="NotFound">
                  <h3>Sorry, page not found!</h3>
            </div>
      </MDBContainer>
);

export default Component;
