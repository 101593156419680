import "./App.css";
import { Route, Switch } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import Alert from "../Alert";
import Home from "../Home";
import Loader from "../Loader";
import Header from "../Header";
import Dimmer from "../Dimmer";
import NotFound from "../NotFound";
import RequireAuth from "../RequireAuth";
import RequireUnauth from "../RequireUnauth";
import Footer from "../Footer";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
// import "rsuite/dist/styles/rsuite-default.css";



const App = ({ loading }) => (
      <div className="AppContainer">
            {loading && (
                  <div>
                        <Dimmer />
                        <Loader />
                  </div>
            )}
            <Header />
            <Alert />

            <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/index.html" exact component={Home} />
                  <Route path="/auth" component={RequireAuth} />
                  <Route path="/unauth" component={RequireUnauth} />
                  <Route component={NotFound} />
            </Switch>

            <Footer />
      </div>
);

App.defaultProps = {
      loading: false
};

App.propTypes = {
      loading: PropTypes.bool
};

export default App;
