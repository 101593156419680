import React from "react";
import { MDBJumbotron, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import { Link } from "react-router-dom";



const FooterJumb = () => {
    return (
        <MDBContainer className="mt-5 text-center">
            <MDBRow>
                <MDBCol>
                    <MDBJumbotron>
                        <MDBCardBody>
                            <MDBCardTitle className="h2">
                                So what are you waiting for?
                            </MDBCardTitle>
                            <p className="blue-text my-4 font-weight-bold">SureCanPay</p>
                            <MDBCardText>
                                Sign up or Sign In and get started with SureCanPay
                            </MDBCardText>
                            <hr className="my-4" />
                            <div className="pt-2">
                                <Link to="/unauth/signup">
                                    <MDBBtn
                                        color="primary"
                                        className="waves-effect"
                                    >
                                        Create Account <MDBIcon icon="user-plus" />
                                    </MDBBtn>
                                </Link>
                                <Link to="/unauth/login">
                                    <MDBBtn
                                        outline
                                        color="primary"
                                        className="waves-effect"
                                    >
                                        Login <MDBIcon icon="sign-in-alt" />
                                    </MDBBtn>
                                </Link>
                            </div>
                        </MDBCardBody>
                    </MDBJumbotron>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default FooterJumb;