import React from "react";
import { MDBBtn, MDBSmoothScroll, MDBIcon } from "mdbreact";
import { Component } from "react";
import './ScrollButton.css';

class ScrollButton extends Component {
      render() {
            return (
                  <MDBSmoothScroll to={this.props.to} className="SmoothScroll">
                        <MDBBtn color="info" size="sm" className="ScrollButton" > <MDBIcon icon="chevron-circle-down" style={{ paddingRight: "3px" }} />{this.props.text}</MDBBtn>
                  </MDBSmoothScroll>
            );
      }
}

export default ScrollButton;
