
import { execEffect } from "./index";
import { API } from 'aws-amplify';
import { Auth } from "aws-amplify";

export default {
    state: {},
    reducers: {},

    effects: dispatch => ({
        async addUser(payload) {
            await execEffect(dispatch, async () => {
                const myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    body: { 'emailAddress': payload.emailAddress, 'permissions': payload.permissions }
                }
                const apiName = 'TennantAPI';
                const path = 'manageuserpool/user/' + payload.userName;
                if (payload.addUser) {
                    await API.post(apiName, path, myInit)
                } else {
                    await API.patch(apiName, path, myInit)
                }
                payload.complete()

            },

                e => { console.log("Error", e.response); dispatch.alert.error(e.response.data.error) }
            );
        },

        async deleteUser(payload) {
            await execEffect(dispatch, async () => {
                const myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                }
                console.log("PayLoad Username", payload.userName)
                const apiName = 'TennantAPI';
                const path = 'manageuserpool/user/' + payload.userName;                
                await API.del(apiName, path, myInit)
                payload.complete()
            },
                e => { console.log("Error", e.response); dispatch.alert.error(e.response.data.error) }
            );
        },

        async parameters(payload) {
            await execEffect(dispatch, async () => {
                const myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    body: { 'SettlementTime': payload.settlementTime, 'Flags': payload.checkboxes,'PenniesAccessToken':payload. PenniesAccessToken,'PenniesMerchantID':payload.PenniesMerchantID}
                }
                const apiName = 'TennantAPI';
                const path = 'manageterminals/parameters';


                payload.complete(await API.post(apiName, path, myInit))

            },

                e => { console.log("Error", e.response); dispatch.alert.error(e.response.data.error) }
            );
        }
    }),

};
