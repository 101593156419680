import React, { Component } from 'react';
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from 'mdbreact';

class FAQPED extends Component {
  state = {
    collapseID: 'collapse1'
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
  
  render() {

    return (
        <MDBContainer
          className='accordion md-accordion accordion-3 z-depth-1-half'
        >
          <div className='d-flex justify-content-center pt-5'>
            <MDBIcon far icon="question-circle" className='mx-3' size='2x' />
            <MDBIcon far icon="credit-card" className=' mx-3' size='2x' />
          </div>
          <h2 className='text-center text-uppercase py-4 px-3'>
            PED FAQ
          </h2>

          <hr className='mb-0' />

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse1')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
              id="PED"
            >
              Where do I order the PIN entry devices?
 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse1'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse1' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                  Once you have signed up for and verified your account, you can log in at any time and navigate to the “Orders” tab of the website. From here, you can create a new order for PIN entry devices by pressing the “New Order” button and following the on-screen instructions to completion.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse4')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I’m getting an “Invalid Merchant” error on the PED when I try to run a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse4'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse4' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This indicates that your Merchant ID, or “MID” that you assigned when setting up your terminal is incorrect. Navigate to Manage > Terminals and click the Edit button next to the PED which is giving you the error, which you can identify by its serial number. In the field labelled “MID”, you must ensure that the entry is correct, and matches with the one that was supplied to you by your acquirer (e.g. Barclays) when you signed up.                
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse5')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I’m getting a “Network Error” message on the PED when I try to run a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse5'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse5' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This error indicates that the PED is not able to connect to the network to complete the transaction. Your first port of call should be to ensure that your device is connected to the internet. The procedure for this will differ depending on the device itself, so refer to documentation regarding your particular Operating System or Device to ensure that you are connected. If you can confirm you are successfully connected to the network and are still receiving the “Network Error” message, get in contact with us at scp-support@wonderlane.co.uk.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse6')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I am getting a “transactionUnauthorized” message on the POS when I try to run a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse6'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse6' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This error could possibly indicate that your API key is incorrect. Refer to step 15 of the setup guide to change your API key. Ensure that the API key you have set in the Test EPOS is the same as the one at the top of the “Developers” page on the SCP website. Only if the API keys match up exactly will you be able to overcome this error. To avoid any doubt, copy and paste the key in.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse7')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              No receipts are produced when I perform a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse7'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse7' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                Depending on the type of transaction you are performing, this could be intended. If you are following the supplied integration scripts, double check in the “Expected Result” column that receipts are expected to be produced. If not, make sure you are expecting to receive receipts for this transaction. 
                </p>
                <p>
                If you are certain you should be expecting receipts for this transaction, Go to File>Options in the Test EPOS. Ensure that both “Show Customer Receipt” and “Show Merchant Receipt” are both ticked, and that you click “Save” to ensure that the changes are saved. Retest the transaction and ensure receipts are produced.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse9')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I am getting an “Invalid Amount” error message 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse9'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse9' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This occurs when the transaction value has gone over one or more of several limits. These will differ depending on the merchant, but could either be a cashback limit, sale limit or contactless limit. Check with your merchant settings to see which limit is being breached and adjust your transaction value accordingly.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>


          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse11')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              Where can I find a guide to get set up with my payment device? 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse11'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse11' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                There is a full guide explaining how to get everything set up and start processing transactions on the Developer page. Look for section 1.1: Getting Started.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse13')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              How long do I have to enter my confirmation code once it has been generated?
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse13'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse13' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                There is no time limit on the confirmation code you are emailed when logging in for the first time. You can enter it whenever you are ready.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse17')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I am getting a “Auto Report Timed Out” message on my PED
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse17'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse17' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This occurs when you have taken too long to present a card for payment. There is a 60 second timer upon when the PED first starts accepting card payments to perform a card read. If a card read is not performed in this time period, the transaction will automatically cancel with the “Auto Report Timed Out” message, so to avoid it, make sure to perform the card payment within that period of time. 
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse18')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I am getting a “Service Not Allowed” message on my PED
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse18'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse18' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This occurs when you are attempting to perform a payment with a card that is unable to do so. This could be for a variety of reasons, such as the card being blocked from purchasing with cashback, or being cash advance only. In this scenario, the only option is to use another card for payment.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse19')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I am getting a “Card Not Accepted” message on my PED
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse19'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse19' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This occurs when you are attempting to perform a payment with a card that is, in some way or another, not able to perform a payment. This could be for a number of reasons, such as the card having an invalid PAN length. In this scenario, the only option is to use another card for payment. 
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse20')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I accidentally turned off/suspended/unplugged the till during a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse20'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse20' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                Although depending on the specific transaction the results might be different, in all scenarios the transaction will have been cancelled and no payment will have been taken. The transaction will be logged as a void. When you log back on, you will be able to produce a receipt of the transaction that will read “Device Unexpectedly Disconnected” and confirm no payment has been taken. If the scenario at hand involves a situation where the PED did not switch off, such as the till app being closed down, then it should default to the “SureCanPay Ready” screen once disconnected. 
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse21')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              Network connection was lost during a transaction
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse21'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse21' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                In this scenario, the transaction will be cancelled and the payment will not be taken. A receipt should be printed that will say “Network Error”, and confirm that no payment was taken. The transaction will be logged as a void. Once you reconnect the network you should be able to perform the transaction again without issue.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>



        </MDBContainer>
    );
  }
}

export default FAQPED;