import React from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import FAQPED from "./FAQPED";
import FAQWebsite from "./FAQWebsite";
import InfoHide from "../InfoHide";
import ScrollButton from "./ScrollButton";
// import ReportIssue from "../ReportIssue";
import FAQPEDloggedout from "./FAQPEDloggedout";
import FAQWebsiteloggedout from "./FAQWebsiteloggedout";

const Support = ({ loggedIn }) => {
      window.scrollTo(0, 0);
      return (
            <MDBContainer
                  style={{
                        minHeight: "73.65vh",
                        marginTop: "15px",
                        marginBottom: "15px"
                  }}
            >
                  {loggedIn.length === 0 ? (
                        <MDBRow>
                              <InfoHide info1="Troubleshooting:" info2="Click either button, to navigate to that section. Please note, once logged in you will have access to all FAQs." />
                              <ScrollButton to="PED" text="PED"></ScrollButton>
                              <ScrollButton to="Website" text="Website" />
                        </MDBRow>
                  ) : (
                        <MDBRow>
                              {/* <ReportIssue /> */}
                              <InfoHide info1="Troubleshooting:" info2="Click either button, to navigate to that section" />
                              <ScrollButton to="PED" text="PED"></ScrollButton>
                              <ScrollButton to="Website" text="Website" />
                        </MDBRow>
                  )}

                  {loggedIn.length === 0 ? (
                        <MDBRow>
                              <FAQPEDloggedout id="PED" />
                        </MDBRow>
                  ) : (
                        <MDBRow>
                              <FAQPED id="PED" />
                        </MDBRow>
                  )}
                  <br />
                  {loggedIn.length === 0 ? (
                        <MDBRow>
                              <FAQWebsiteloggedout id="Website" />
                        </MDBRow>
                  ) : (
                        <MDBRow>
                              <FAQWebsite id="Website" />
                        </MDBRow>
                  )}
            </MDBContainer>
      );
};

export default Support;
