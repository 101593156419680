import React, { Component } from 'react';
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from 'mdbreact';

class FAQPEDloggedout extends Component {
  state = {
    collapseID: 'collapse1'
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
  
  render() {

    return (
        <MDBContainer
          className='accordion md-accordion accordion-3 z-depth-1-half'
        >
          <div className='d-flex justify-content-center pt-5'>
            <MDBIcon far icon="question-circle" className='mx-3' size='2x' />
            <MDBIcon far icon="credit-card" className=' mx-3' size='2x' />
          </div>
          <h2 className='text-center text-uppercase py-4 px-3'>
            PED FAQ
          </h2>

          <hr className='mb-0' />

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse1')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
              id="PED"
            >
              Where do I order the PIN entry devices?
 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse1'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse1' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                  Once you have signed up for and verified your account, you can log in at any time and navigate to the “Orders” tab of the website. From here, you can create a new order for PIN entry devices by pressing the “New Order” button and following the on-screen instructions to completion.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

        </MDBContainer>
    );
  }
}

export default FAQPEDloggedout;