import React, { Fragment } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import routes from "../../config/routes";
import './Footer.css';

const FooterLink = ({ showForRoles, roles, name, route }) => {
  if (roles.filter((role) => showForRoles.includes(role)).length === 0) return null;
  return (
    <Link to={route}>
      {name}
    </Link>
  );
};

export const Footer = ({ loggedIn, logout }) => (
  <div className="Footer">
    <MDBFooter color="stylish-color-dark" className="page-footer font-small pt-4" style={{ paddingTop: "0px!important", marginTop: "0px!important" }}>
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow center middle style={{ textAlign: "center" }}>
          <hr className="clearfix w-100 d-md-none" />
          {loggedIn.length > 0 ? (
            <Fragment>
              <MDBCol>
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Links
            </h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/">
                      Home
              </Link>
                  </li>
                  <li>
                    <Link to="/auth/products">
                      Products
              </Link>
                  </li>
                  <li>
                    <FooterLink route={routes.authSupport} name="Troubleshooting" showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.developer} name="Developer" showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.authTraining} name="Training" showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                  </li>
                </ul>
              </MDBCol>

              <hr className="clearfix w-100 d-md-none" />
              <MDBCol>
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Account
            </h5>
                <ul className="list-unstyled">
                  <li>
                    <FooterLink route={routes.developer} name="Developers" showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                  </li>
                  <li>
                    <Link to="/auth/changepassword">
                      Change Password
              </Link>
                  </li>
                  <li>
                    <MDBBtn id="logout" size="sm" onClick={logout} style={{ marginLeft: "0" }}>Logout</MDBBtn>
                  </li>
                </ul>
              </MDBCol>

              <hr className="clearfix w-100 d-md-none" />
              <MDBCol>
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Manage
            </h5>
                <ul className="list-unstyled">
                  <li>
                    <FooterLink route={routes.myorganisation} name="Users" showForRoles={['Admin']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.manageTerminals} name="Terminal" showForRoles={['Admin','Developer','ManageTerminals']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.orders} name="Orders" showForRoles={['Admin', 'Orders']} roles={loggedIn} />
                  </li>
                  {/* <li>
                    <FooterLink route={routes.stock} name="Stock" showForRoles={['Admin', 'Orders', 'ManageTerminals']} roles={loggedIn} />
                  </li> */}
                </ul>
              </MDBCol>

              <hr className="clearfix w-100 d-md-none" />
              <MDBCol>
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Transactions
            </h5>
                <ul className="list-unstyled">
                  <li>
                    <FooterLink route={routes.transactions} name="Transactions" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.batches} name="Batches" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                  </li>
                  <li>
                    <FooterLink route={routes.parameters} name="Settings" showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                  </li>
                </ul>
              </MDBCol>
            </Fragment>
          ) : (
              <Fragment>
                <MDBCol>
                  <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                    Links
        </h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/">
                        Home
          </Link>
                    </li>
                    <li>
                      <Link to="/unauth/products">
                        Products
          </Link>
                    </li>
                    {/* <li>
                      <Link to="/unauth/Company">
                        Company
          </Link>
                    </li> */}
                    <li>
                      <Link to="/unauth/troubleshooting">
                        Troubleshooting
              </Link>
                    </li>
                  </ul>
                </MDBCol>

                <hr className="clearfix w-100 d-md-none" />
                <MDBCol>
                  <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                    Account
        </h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/unauth/login">
                        Login
          </Link>
                    </li>
                    <li>
                      <Link to="/unauth/signup">
                        Sign up
          </Link>
                    </li>
                  </ul>
                </MDBCol>

              </Fragment>
            )}
        </MDBRow>
      </MDBContainer>
      <hr />
      {loggedIn.length > 0 ? (<div></div>) : (
        <div className="text-center py-3">
          <ul className="list-unstyled list-inline mb-0">
            <li className="list-inline-item">
              <h5 className="mb-1">Register for free</h5>
            </li>

            <li className="list-inline-item">
              <Link to="/unauth/signup">
                <MDBBtn id="sign-up-btn" rounded color="primary">
                  Sign up
                  </MDBBtn>
              </Link>
            </li>
          </ul>
        </div>
      )}

      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:
              <Link to="/">SureCanPay.com</Link>
        </MDBContainer>
      </div>
    </MDBFooter>
  </div >
);


Footer.defaultProps = {
  loggedIn: []
};

Footer.propTypes = {
  logout: PropTypes.func.isRequired,
  loggedIn: PropTypes.arrayOf(PropTypes.string)
};

export default Footer;