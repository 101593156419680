import "./ChangePassword.css";
import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import ChangePassword from "./ChangePassword";
import * as Yup from "yup";
import { MDBContainer, MDBAnimation,  MDBIcon } from "mdbreact"
import {
      invalidPassword,
} from "../../config/messages";
import { passwordRegex } from "../../config";

export class ChangePasswordContainer extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  completed: false
            };
            this.setComplete = this.setComplete.bind(this);
      }
      setComplete = () => {
            this.setState({ completed: true });

      };

      render() {
            return this.state.completed ? (
                  <MDBContainer style={{ minHeight: "73.65vh" }}>
                       
                              <MDBAnimation reveal type="fadeInUp">
                                    <div id="bottomPage">
                                          <br />
                                          <h5 className="text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 font-weight-bold" style={{ color: "white", textAlign: "center" }} >Password Successfully Changed  <MDBAnimation type="tada" delay="2s">
                                                <MDBIcon icon="thumbs-up" />
                                          </MDBAnimation></h5>
                                         
                                          <br />
                                    </div>
                              </MDBAnimation>
                    
                  </MDBContainer>
            ) : (
                        <Formik
                              enableReinitialize
                              onSubmit={(values, { setSubmitting }) => {
                                    values['username'] = this.props.user
                                    values['complete'] = this.setComplete
                                    this.props.changePassword(values)
                                    setSubmitting(false);

                              }
                              }
                              component={ChangePassword}
                              initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
                              validationSchema={
                                    Yup.object({
                                          currentPassword: Yup.string()
                                                .min(8, "Must be at least 8 characters")
                                                .max(100, "Must be shorter than 100 characters in length")
                                                .required("Required"),
                                          newPassword: Yup.string()
                                                .min(8, "Must be at least 8 characters")
                                                .max(100, "Must be shorter than 100 characters in length")
                                                .trim("Must not contain trailing whitespace").strict()
                                                .required("Required")
                                                .matches(passwordRegex, invalidPassword),
                                          confirmPassword: Yup.string()
                                                .min(8, "Must be at least 8 characters")
                                                .max(100, "Must be shorter than 100 characters in length")
                                                .trim("Must not contain trailing whitespace").strict()
                                                .required("Required")
                                                .oneOf([Yup.ref("newPassword")], "Must match the new password"),
                                    })
                              }
                        />
                  );
      }
}

ChangePasswordContainer.propTypes = {
      changePassword: PropTypes.func.isRequired
};

export default ChangePasswordContainer;

