/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "clientite-20191028070753-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://clientite-20191028070753-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-1:5edc8aa5-e118-40f7-b114-0baa15aa001f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ZwB1JGxOl",
    "aws_user_pools_web_client_id": "1joi6896fvu8job6mbpubqkb0v",
    "oauth": {}
};


export default awsmobile;
