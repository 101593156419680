import "./ForceChangePassword.css";
import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import CustomInputComponent from "../CustomInputComponent";
import InfoHide from "../InfoHide";
import { MDBContainer } from "mdbreact";

const ForceChangePassword = ({ dirty, isSubmitting }) => (
      <MDBContainer>
            <div
                  className="ForceChangePassword simple-form"
                  style={{ minHeight: "73.65vh" }}
            >
                  <Form>
                        <InfoHide info1="You must change your password" />
                        <Field
                              autoFocus
                              type="password"
                              name="password"
                              bssize="large"
                              component={CustomInputComponent}
                        />
                        <Field
                              autoFocus
                              type="password"
                              name="confirmPassword"
                              bssize="large"
                              component={CustomInputComponent}
                        />
                        <Button
                              block
                              bssize="large"
                              disabled={!dirty || isSubmitting}
                              type="submit"
                        >
                              Change Password
                        </Button>
                  </Form>
            </div>
      </MDBContainer>
);

ForceChangePassword.propTypes = {
      dirty: PropTypes.bool,
      isSubmitting: PropTypes.bool
};

ForceChangePassword.defaultProps = {
      dirty: false,
      isSubmitting: false
};

export default ForceChangePassword;
