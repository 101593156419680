import React from "react";
import Amplify from "../../components/api";
import SCPTablePages from "../SCPTablePages.js";
import {
      MDBModalBody,
      MDBModalHeader,
      MDBBtn,
      MDBModal,
      MDBIcon
} from "mdbreact";
import "./Stock.css";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";

class Stock extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  terminal: "",
                  enteredSerial: false,
                  limit: "5",
                  modal10: false
            };
            this.getTerminalsList = this.getTerminalsList.bind(this);
      }

      componentDidMount() {
            this.getTerminalsList();
      }

      async getTerminalALL() {
            let apiName = "TennantAPI";
            let path = "manageterminals/listterminals";

            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            return await Amplify.API.get(apiName, path, myInit);
      }

      toggle = nr => () => {
            let modalNumber = "modal" + nr;
            this.setState({
                  [modalNumber]: !this.state[modalNumber]
            });
      };

      getTerminalsList() {
            this.getTerminalALL()
                  .then(response => {
                        console.log("response", response);
                        if (response === null) {
                              this.setState({ date: [] });
                        } else {
                              let array = [],
                                    item = [];
                              var i;
                              for (i = 0; i < response.length; i++) {
                                    item = [
                                          { text: response[i].SerialNum },
                                          {
                                                text: response[
                                                      i
                                                ].CreateDate.slice(0, 10)
                                          },
                                          { text: response[i].TerminalType },
                                          {
                                                text: response[i].PINDate.slice(
                                                      0,
                                                      10
                                                )
                                          },
                                          { text: response[i].Status },
                                          { text: response[i].FirmwareVersion },
                                          { text: response[i].PIN }
                                    ];
                                    array.push(item);
                              }
                              this.setState({ data: array });
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get terminal" + error);
                  });
      }

      scrollToTop = () => window.scrollTo(0, 0);

      toggleCollapse = collapseID => () => {
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));
      };

      render() {
            const header = [
                  "SerialNum",
                  "Create Date",
                  "TerminalType",
                  "PIN Date",
                  "Status",
                  "Firmware Version",
                  "PIN"
            ];

            return (
                  <>
                        <MDBBtn id="show-stock-btn" onClick={this.toggle(4)} color="info">
                              <MDBIcon
                                    icon="plus-circle"
                                    className="buttonIcons"
                              />
                              Show Stock
                        </MDBBtn>

                        <MDBModal
                              isOpen={this.state.modal4}
                              toggle={this.toggle(4)}
                              size="lg"
                        >
                              <MDBModalHeader toggle={this.toggle(4)}>
                                    PED Stock
                                    <LinkContainer
                                          className="PageView"
                                          to={routes.stock}
                                    >
                                          <MDBBtn color="warning">Page View</MDBBtn>
                                    </LinkContainer>
                              </MDBModalHeader>
                              <MDBModalBody>
                                    <SCPTablePages
                                          header={header}
                                          table={this.state.data}
                                    />
                              </MDBModalBody>
                        </MDBModal>
                  </>
            );
      }
}

export default Stock;
