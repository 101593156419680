import React from "react";
import { API } from 'aws-amplify';
import { Field } from "formik";
import CustomInputComponent from "../CustomInputComponent";

class OrgName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: "",
    };
    this.checktenant = this.checktenant.bind(this);
  }


  // handleSubmit sends an API request and updates state.data with the result
  checktenant(event) {
    this.setState({ tenant: event.target.value });
    this.getData(event.target.value).then(response => this.props.onChange(response))
  }

  async getData(tenant) {
    let apiName = 'TennantAPI';
    let path = 'manageuserpool/checkorg';

    let myInit = {
      queryStringParameters: { org: tenant }, //Can add Query string here
      responseType: 'text'
    }
    console.log("Calling", apiName, path)
    return await API.get(apiName, path, myInit); //Make sure you are using correct method get post etc.
  }


  render() {
    return (
      <><b>{this.state.ok}</b>
        <Field
          type="text"
          value={this.state.tenant}
          onChange={this.checktenant}
          autoFocus
          name="organisationName"
          bssize="large"
          component={CustomInputComponent}
        >
          <p className="validation">Name should be lowercase and at least 4 characters</p>
        </Field>
      </>
    );
  }
}

export default OrgName;