import "./Login.css";
import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Login from "./Login";
import ForceChangePassword from "./ForceChangePassword"
import * as Yup from "yup";
import {
      doesNotMatch,
      invalidPassword
} from "../../config/messages";
import { passwordRegex } from "../../config";

export class LoginContainer extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  newPasswordRequired: false
            };
            this.setNewPasswordRequired = this.setNewPasswordRequired.bind(this);
      }
      setNewPasswordRequired = () => {
            this.setState({ newPasswordRequired: true });

      };

      render() {
            return this.state.newPasswordRequired ? (
                  <Formik
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                              values['username'] = this.props.user
                              values['completeNewPassword'] = this.props.callback
                              this.props.forceChangePassword(values)
                              setSubmitting(false);

                        }
                        }
                        component={ForceChangePassword}
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={
                              Yup.object({

                                    password: Yup.string()
                                          .min(8, "Must be at least 8 characters")
                                          .max(100, "Must be shorter than 100 characters in length")
                                          .trim("Must not contain trailing whitespace").strict()
                                          .required("Required")
                                          .matches(passwordRegex, invalidPassword),
                                    confirmPassword: Yup.string()
                                          .min(8, "Must be at least 8 characters")
                                          .max(100, "Must be shorter than 100 characters in length")
                                          .trim("Must not contain trailing whitespace").strict()
                                          .required("Required")
                                          .oneOf([Yup.ref("password")], doesNotMatch("password")),
                              })
                        }
                  />
            ) : (
                        <Formik
                              onSubmit={(values, { setSubmitting }) => {
                                    values['newPasswordRequired'] = this.setNewPasswordRequired
                                    this.props.login(values)
                                    setSubmitting(false);
                              }}
                              component={Login}
                              initialValues={{ organisationName: "", username: "", password: "" }}
                              validationSchema={Yup.object({
                                    organisationName: Yup.string()
                                          .min(3, "Must be at least 3 characters")
                                          .required("Required"),
                                    username: Yup.string()
                                          .min(3, "Must be at least 3 characters")
                                          .required("Required"),
                                    password: Yup.string()
                                          .min(8, "Must be at least 8 characters")
                                          .required("Required"),
                              })}
                        />
                  );
      }
}

LoginContainer.propTypes = {
      login: PropTypes.func.isRequired
};

export default LoginContainer;
