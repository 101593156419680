import React from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { withRouter } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import routes from "../../config/routes";
import Amplify from "../api.js";
import SCPTable from "../SCPTable.js";
import './Orders.css';
import InfoHide from '../InfoHide';

class Orders extends React.Component {
      constructor(props) {
            super(props);
            this.getOrders = this.getOrders.bind(this);
            this.state = {
                  data: [],
            };
      }

      async getData() {
            let apiName = 'TennantAPI';
            let path = 'orders';
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
                  queryStringParameters: { max: '100' }
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      getOrders() {
            this.getData().then(response => {
                  console.log('response', response)
                  if (response === null) {
                        this.setState({ data: [] })
                  } else {
                        var array = [], item = [];
                        var i, j;
                        for (i = 0; i < response.length; i++) {

                              for (j = 0; j < response[i].order.length; j++) {
                                    if (response[i].paymentDetail !== null ){
                                    console.log("if")
                                    item = [
                                          { text: response[i].OrderNum },
                                          { text: response[i].order[j].terminalType },
                                          { text: response[i].order[j].qty },
                                          { text: response[i].CreateDate.slice(0, 10) },
                                          { text: response[i].deliveryAddress.addressLine1 },
                                          { text: response[i].orderDetail[j].status },
                                          { text: response[i].orderedBy },
                                          { text: response[i].paymentDetail.PONum },
                                          { text: "Detail", link: routes.detail + '?order=' + response[i].OrderNum }
                                    ];
                                
                              }
                                    else{
                                        
                                          item = [
                                                { text: response[i].OrderNum },
                                                { text: response[i].order[j].terminalType },
                                                { text: response[i].order[j].qty },
                                                { text: response[i].CreateDate.slice(0, 10) },
                                                { text: response[i].deliveryAddress.addressLine1 },
                                                { text: response[i].orderDetail[j].status },
                                                { text: response[i].orderedBy },
                                                { text: response[i].paymentDetail },
                                                { text: "Detail", link: routes.detail + '?order=' + response[i].OrderNum }
                                          ];

                                    }
                                    array.push(item);
                              }
                        }
                        this.setState({ data: array })
                  }
            }).catch(error => { console.log('Failed to get data ' + error) })
      }

      componentDidMount() {
            window.scrollTo(0, 0)
            this.getOrders();
      }


      render() {
            const header = ["Order", "Product", "Quantity", "Date", "Address", "Status", "Ordered By", "PO No.", ""];
            return (
                  <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
                        <MDBRow>
                              <InfoHide
                                    info1="Orders Page:"
                                    info2="View all your orders, find more detail and make new orders"
                              />
                              <LinkContainer className="NavText" to={routes.neworders} >
                                    <MDBBtn id="new-button" className="NewOrderButton" color="green" size="sm" type="button" value="Submit"><MDBIcon icon="plus-circle" style={{ paddingRight: "3px" }} />New Order</MDBBtn>
                              </LinkContainer>
                        </MDBRow>
                        <MDBRow style={{ marginBottom: "10px" }}>
                              <MDBCol>
                                    <SCPTable header={header} table={this.state.data} />
                              </MDBCol>
                        </MDBRow>
                  </MDBContainer >

            );
      }
}





export default withRouter(Orders);


