import React, { Component } from 'react';
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from 'mdbreact';

class FAQPED extends Component {
  state = {
    collapseID: 'collapse1'
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
  
  render() {
    
    return (
        <MDBContainer
          className='accordion md-accordion accordion-3 z-depth-1-half'
          style={{ marginBottom: "15px"}}
        >
          <div className='d-flex justify-content-center pt-5'>
            <MDBIcon far icon="question-circle" className='mx-3' size='2x' />
            <MDBIcon icon="laptop" className='mx-3' size='2x' />
          </div>
          <h2 className='text-center text-uppercase py-4 px-3'  id="Website">
            Website FAQ
          </h2>

          <hr className='mb-0' />

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse2')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              How do I get access to the API?
        
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse2'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse2' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                You can access the API by signing up for an account and logging in to this website. Once you are logged in, navigate to the Developer page and find the most appropriate download for you depending on the operating system you are using. Click on the download link and extract the contents of the folder to somewhere on your system. Finally, run the API exe and you can get started!
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse8')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              Cancelled/Failed transactions are not showing on the website
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse8'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse8' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                Any transaction where the payment did not succeed will not show on the website until another subsequent successful transaction has been completed. Perform a basic approved transaction to get these transactions to appear.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        
          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse10')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I have forgotten my login password. How can I get a new one? 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse10'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse10' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                On the login screen, just beneath the big blue login button is a “Forgot Password” link. Click it and it will take you to a password where you can enter your organisation name and username. A reset password link will then be sent to the email address associated with that username. 
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse11')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              Where can I find a guide to get set up with my payment device? 
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse11'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse11' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                There is a full guide explaining how to get everything set up and start processing transactions on the Developer page. Look for section 1.1: Getting Started.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

        
          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse12')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              What are the different permission levels for users?
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse12'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse12' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                <strong>Admin:</strong> Can access everything on the website, including managing/adding users and editing parameters, which are exclusive to this permission.
                </p>
                <p>
                <strong>Developer:</strong> Can access the standard account pages and the Developers page, which contains download links for the software to run transactions, and a guide on how to set it all up.
                </p>
                <p>
                <strong>Orders:</strong> Can access the standard account pages and the Orders page, where you can view the details of all current orders, and raise new orders
                </p>
                <p>
                <strong>View Transactions:</strong> Can access the standard account pages and the Transactions and Batches pages, where you can view all transactions processed through your payment devices, both settled and unsettled. You can also export these transactions as .csv documents.
                </p>
                <p>
                <strong>Manage Terminals:</strong> Can access the standard account pages and the Terminals pages, where you can view and edit the details of all the terminals within your organisation, as well as go through the process of setting up a One Time PIN for them
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse13')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              How long do I have to enter my confirmation code once it has been generated?
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse13'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse13' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                There is no time limit on the confirmation code you are emailed when logging in for the first time. You can enter it whenever you are ready.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

              
          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse14')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              What do I do if I lost my confirmation code to log in or entered the email incorrectly?
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse14'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse14' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                In this scenario, we would recommend that you delete the user with the lost confirmation code and create a new one with the same details. If you absolutely must recover that account with the lost code, you must get into contact with us as soon as possible at scp-support@wonderlane.co.uk.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              onClick={this.toggleCollapse('collapse15')}
              tag='h3'
              tagClassName='blue-text d-flex justify-content-between align-items-center'
            >
              I’m getting a “Terminal Not Found” error message when I try to generate a PIN
              <MDBIcon
                icon={
                  this.state.collapseID === 'collapse15'
                    ? 'angle-up'
                    : 'angle-down'
                }
                className='blue-text'
                size='2x'
              />
            </MDBCollapseHeader>
            <MDBCollapse id='collapse15' isOpen={this.state.collapseID}>
              <MDBCardBody className='pt-0'>
                <p>
                This indicates that you have not entered in your serial number correctly when trying to generate your OTP. You must ensure that the serial number you are entering is one that is available on the “Stock” page of the site that has a blank entry under the PIN Date column. Any serial number that is not on this list, or has already had its PIN generated prior to this, will not be eligible to generate a PIN for.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>


        </MDBContainer>
    );
  }
}

export default FAQPED;