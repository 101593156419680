import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import Amplify from 'aws-amplify';
import history from './history';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import { cdpenv } from "./scp-exports.js";
import awsmobile from "./aws-exports.js"

Amplify.configure({
      Auth: {
            identityPoolId: awsmobile.aws_cognito_identity_pool_id,
            region: awsmobile.aws_cognito_region,
            userPoolId: awsmobile.aws_user_pools_id,
            userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
      },
      API: {
            endpoints: [
                  {
                        name: 'TennantAPI',
                        endpoint: cdpenv.TennantAPI
                  },

            ]
      }
});



ReactDOM.render(
      <Provider store={store}>
            <Router history={history}>
                  <App />
            </Router>
      </Provider>,
      document.getElementById("root")
);
