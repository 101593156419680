// Copyright 2019 SureCanPay Ltd.  All Rights Reserved
// auth.js provides the Amplify user management functions wrapped in redux
// Redux manages state and action.  The state for auth is an object 
// { loggedIn: [array of permissions], user: userid, session: CognitoSession}
// this state can only be modifed by actions and all the actions are in this file
// most actions are wrapped in execEffect.  This displays a loading page, whilst the 
// action is being completed and if dispatch.alert.error(message)  or   dispatch.alert.silence()
// is called the error is displayed or the error bar is removed
// Every component that uses the states or actions in this file can be connected to them using
// export default connect(
//       ({ auth: { states (eg loggedIn or user)} }) => ({ name of props passed to component }),
//       ({ auth: { actions(eg. signup) } }) => ({ name of props to be passed to component})
// )(Component);
//
// There are two ways for components to act on the results of actions.  The can either check 
// the state after the action has been called ie. if login is successful loggedIn state will 
// not be empty, or they can pass a function pointer in payload to the action which is called.  
// This callback can set a local stateglobal.fetch = require('node-fetch');
// all actions are passed a payload and state eg. action(payload,state).  Some of the actions do not 
// need state so are defined action(payload)
// For login if NEW_PASSWORD_REQUIRED is returned from the amplify call the session is stored in the
// state as this is needed by the subsequent action forceChangePassword to change the password


import { Auth } from "aws-amplify";
import { execEffect } from "./index";
import { API } from 'aws-amplify';

export default {
      state: { loggedIn: [], user: {}, session: null, serial: "" },
      reducers: {
            set(state, payload) {
                  return { ...state, loggedIn: payload.perm, user: payload.user, session: payload.session };
            }
      },

      effects: dispatch => ({
            async isAuthenticated() {
                  const session = await Auth.currentSession(); //pull group out of session
                  const groups = session.accessToken.payload['cognito:groups']
                  if (groups !== undefined) {
                        groups.push('Guest')
                  }
                  dispatch.alert.silence()
                  dispatch.auth.set({ perm: groups, user: session.accessToken.payload.username });
            },

            async signUp(payload) {
                  await execEffect(dispatch, async () => {
                        let { organisationName, username, email, password } = payload;
                        let myInit = {
                              headers: {},
                              queryStringParameters: { org: organisationName },
                              responseType: 'text'
                        }
                        try {
                              const check = await API.get('TennantAPI', 'manageuserpool/checkorg', myInit)
                              if (check === "Found") {
                                    dispatch.alert.error("Organisation Name Already Taken")
                                    return
                              }
                        } catch (e) {
                              dispatch.alert.error(e.message)
                              return
                        };
                        username = organisationName + '/' + username;
                        try {
                              await Auth.signUp({ username, attributes: { email }, password });
                        } catch (e) {
                              dispatch.alert.error(e.message)
                              return
                        }
                        dispatch.alert.silence()
                        payload.onSuccess()
                  },
                        e => dispatch.alert.error(e.message)
                  );
            },

            async confirmSignUp(payload) {
                  await execEffect(dispatch, async () => {
                        let { organisationName, username, password, confirmationCode } = payload;
                        username = organisationName + '/' + username;
                        await Auth.confirmSignUp(username, confirmationCode);
                        const user = await Auth.signIn(username, password);
                        let groups = user.signInUserSession.idToken.payload['cognito:groups']
                        if (groups !== undefined) {
                              groups.push('Guest')
                        } else {
                              groups = ['Guest']
                        }
                        dispatch.auth.set({ perm: groups, user: username });


                  },
                        e => dispatch.alert.error(e.message)
                  );

            },

            async forceChangePassword(payload, state) {
                  await execEffect(
                        dispatch,
                        async () => {
                              const session = await Auth.completeNewPassword(state.auth.session, payload.password);
                              dispatch.alert.silence();

                              let groups = session.signInUserSession.idToken.payload['cognito:groups']
                              if (groups !== undefined) {
                                    groups.push('Guest')
                              } else {
                                    groups = ['Guest']
                              }
                              dispatch.auth.set({ perm: groups, user: session.signInUserSession.idToken.payload['cognito:username'] });

                        },
                        e => dispatch.alert.error(e.message)
                  );
            },


            async login(payload) {
                  await execEffect(
                        dispatch,
                        async () => {
                              let { organisationName, username, password } = payload;
                              username = organisationName + '/' + username;
                              const user = await Auth.signIn(username, password)
                              if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                    console.log("New Password required")
                                    dispatch.alert.silence()
                                    dispatch.auth.set({ user: username, session: user });
                                    payload.newPasswordRequired()
                                    return
                              }
                              let groups = user.signInUserSession.idToken.payload['cognito:groups']

                              if (groups !== undefined) {
                                    groups.push('Guest')
                              } else {
                                    groups = ['Guest']
                              }
                              dispatch.alert.silence()
                              dispatch.auth.set({ perm: groups, user: user.signInUserSession.idToken.payload['cognito:username'] });

                        },
                        e => dispatch.alert.error(e.message)
                  );
            },

            async changePassword(payload) {
                  await execEffect(
                        dispatch,
                        async () => {

                              await Auth.currentAuthenticatedUser().then(user => {
                                    Auth.changePassword(user, payload.currentPassword, payload.newPassword).then(() => {
                                          payload.complete()
                                          dispatch.alert.silence()

                                    }).catch(
                                          e => dispatch.alert.error(e.message)
                                    )
                              })
                        },
                        e => dispatch.alert.error(e.message)
                  );
            },



            async logout() {
                  await execEffect(dispatch, async () => {
                        await Auth.signOut();
                        dispatch.auth.set({});
                        dispatch.alert.silence()
                  });
            },




      })
};