import "./Login.css";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import routes from "../../config/routes";
import CustomInputComponent from "../CustomInputComponent";
import InfoHide from '../InfoHide';

const Login = ({ dirty, isSubmitting }) => (
      <div className="Login simple-form" style={{ minHeight: "73.65vh" }}>
            <Form>
                  <InfoHide 
                  info1="Login Page"
                  />
                  <Field
                        autoFocus
                        type="text"
                        name="organisationName"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Field
                        autoFocus
                        type="text"
                        name="username"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Field
                        autoFocus
                        type="password"
                        name="password"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Button
                        block
                        bssize="large"
                        disabled={!dirty || isSubmitting}
                        type="submit"
                  >
                        Login
                  </Button>
                  <Link to={routes.forgotPassword} className="pull-right small">
                        Forgot password?
                  </Link>
            </Form>
      </div>
);

Login.propTypes = {
      dirty: PropTypes.bool,
      isSubmitting: PropTypes.bool
};

Login.defaultProps = {
      dirty: false,
      isSubmitting: false
};

export default Login;