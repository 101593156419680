import "./ChangePassword.css";
import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import CustomInputComponent from "../CustomInputComponent";
import InfoHide from "../InfoHide";

const ChangePassword = ({ dirty, isSubmitting }) => (
      <div
            className="ChangePassword simple-form"
            style={{ minHeight: "73.65vh" }}
      >
            <Form>
                  <InfoHide info1="Change Password" />
                  <Field
                        autoFocus
                        type="password"
                        name="currentPassword"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Field
                        autoFocus
                        type="password"
                        name="newPassword"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Field
                        autoFocus
                        type="password"
                        name="confirmPassword"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Button
                        id="change-btn"
                        block
                        bssize="large"
                        disabled={!dirty || isSubmitting}
                        type="submit"
                  >
                        Change Password
                  </Button>
            </Form>
      </div>
);

ChangePassword.propTypes = {
      dirty: PropTypes.bool,
      isSubmitting: PropTypes.bool
};

ChangePassword.defaultProps = {
      dirty: false,
      isSubmitting: false
};

export default ChangePassword;
