import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";

const TeamPage = () => {
  return (
    <MDBCard className="text-center" style={{marginTop:"15px"}}>
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold my-5">
          Our amazing team
        </h2>
        <p className="grey-text w-responsive mx-auto mb-5">
          Our team will help your business to innovate through a unique partnership of practical retail experience and engineering excellence.
        </p>
        <MDBRow>
            <MDBCol>
              {/* <MDBAvatar
                tag="img"
                src="https://mdbootstrap.com/img/Photos/Avatars/img%19(19).jpg"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
              /> */}
              <h5 className="font-weight-bold mt-4 mb-3">David Steed</h5>
              <p className="text-uppercase blue-text">Director</p>
              <p className="grey-text">
              David is a leading industry specialist with over 30 years of experience having architectured several high volumes and high-value systems. 
              </p>
              
            </MDBCol>

            <MDBCol>
              {/* <MDBAvatar
                tag="img"
                src="https://www.wonderlane.co.uk/wp-content/uploads/2018/04/lfv2-1.png"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
              /> */}
              <h5 className="font-weight-bold mt-4 mb-3">Lawry Freeman</h5>
              <p className="text-uppercase blue-text">Director</p>
              <p className="grey-text">
                Lawry has worked at a senior level for a number of the UK top 5 retailers specialising in
                retail transformation through store and supply chain system innovation.
              </p>
             
            </MDBCol>

            <MDBCol>
              {/* <MDBAvatar
                tag="img"
                src="https://www.wonderlane.co.uk/wp-content/uploads/2018/02/mh.png"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
              /> */}
              <h5 className="font-weight-bold mt-4 mb-3">Martin Hartley</h5>
              <p className="text-uppercase blue-text">Director</p>
              <p className="grey-text">
                Martin is a leading industry expert on UK and Global payment systems having successfully
                  architected a number of high value and high volume systems over the past 20 years.
              </p>
             
            </MDBCol>
          </MDBRow>
      </MDBCardBody>
    </MDBCard>
      );
    }
    
export default TeamPage;