import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
      MDBAnimation,
      MDBContainer,
      MDBRow,
      MDBBtn,
} from "mdbreact";
import Amplify from "../api.js";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { DefaultTextInput, DefaultCheckbox } from "../formik.js";
import "./EditUser.css";
import InfoHide from "../InfoHide";
import DeleteUser from "../DeleteUser";
import { nameRegex } from "../../config";
import { invalidUsername } from "../../config/messages";
// import Tips from "../Tips";



const EditUserForm = params => {
      console.log("params", params);
      return (
            <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
                  <MDBAnimation type="fadeInLeft">
                        <MDBRow>
                              <InfoHide
                                    info1={
                                          params.addUser
                                                ? "Please add the user details below."
                                                : "Please edit the user details below."
                                    }
                              />
                              <DeleteUser complete={params.history.goBack} />
                        </MDBRow>

                        <div className="TopSection">
                              <Formik
                                    enableReinitialize
                                    initialValues={params.ivs}
                                    validationSchema={Yup.object({
                                          emailAddress: Yup.string()
                                                .email("Invalid email address")
                                                .required("Required"),
                                          userName: Yup.string()
                                                .min(
                                                      3,
                                                      "Must be a minimum of 3 characters in length"
                                                )
                                                .max(
                                                      25,
                                                      "Cannot be longer than 25 characters in length"
                                                )
                                                .required("Required")
                                                .matches(
                                                      nameRegex,
                                                      invalidUsername
                                                )
                                                .trim(
                                                      "Must not contain any whitespace"
                                                )
                                                .strict()
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                          console.log(
                                                "Submitting",
                                                params.history,
                                                params.ivs
                                          );
                                          Submit(params, values, {
                                                setSubmitting
                                          });
                                    }}
                              >
                                    <Form className="InputForm">
                                          <MDBRow
                                                center
                                                style={{ display: "contents" }}
                                          >
                                                <DefaultTextInput
                                                      label="User Name"
                                                      name="userName"
                                                      type="text"
                                                      disabled={
                                                            !params.ivs.addUser
                                                      }
                                                />

                                                <DefaultTextInput
                                                      label="Email"
                                                      name="emailAddress"
                                                      type="text"
                                                />
                                          </MDBRow>
                                          <MDBRow
                                                center
                                                style={{ display: "contents" }}
                                          >
                                                <DefaultCheckbox
                                                      name="roles"
                                                      value="Admin"
                                                      label="Admin"
                                                />
                                                {/* <Tips
                                                text="The admin permission grants access to all pages and all functions."
                                                /> */}
                                                <DefaultCheckbox
                                                      name="roles"
                                                      value="Developer"
                                                      label="Developer"
                                                />
                                                <DefaultCheckbox
                                                      name="roles"
                                                      value="Orders"
                                                      label="Orders"
                                                />
                                                <DefaultCheckbox
                                                      name="roles"
                                                      value="ViewTransactions"
                                                      label="View Transactions"
                                                />
                                                <DefaultCheckbox
                                                      name="roles"
                                                      value="ManageTerminals"
                                                      label="Manage Terminals"
                                                />
                                                 <DefaultCheckbox
                                                      name="roles"
                                                      value="Guest"
                                                      label="Guest"
                                                />
                                          </MDBRow>

                                          <MDBRow
                                                center
                                                style={{
                                                      display: "inline",
                                                      marginLeft: "0px"
                                                }}
                                          >
                                                <MDBBtn
                                                      color="success"
                                                      type="submit"
                                                >
                                                      Submit
                                                </MDBBtn>
                                                <MDBBtn
                                                      color="danger"
                                                      onClick={() => {
                                                            params.history.goBack();
                                                      }}
                                                >
                                                      Cancel
                                                </MDBBtn>
                                          </MDBRow>
                                    </Form>
                              </Formik>
                        </div>
                  </MDBAnimation>
            </MDBContainer>
      );
};

class EditUser extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  emailAddress: "",
                  status: "",
                  userName: "",
                  roles: ["Admin"],
                  addUser: false //Used to determin if this is being called a edit or add user
            };
      }
      componentDidMount() {
            window.scrollTo(0, 0);
            this.getUser();
      }

      async getUserAPI() {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const user = params.get("user");
            if (user === null) {
                  console.log("Add User");
                  this.setState({ addUser: true });
                  return;
            }
            let apiName = "TennantAPI";
            let path = "manageuserpool/user/" + user;
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.get(apiName, path, myInit);
      }

      getUser() {
            this.getUserAPI()
                  .then(response => {
                        this.setState({
                              emailAddress: response.emailAddress,
                              status: response.status,
                              userName: response.userName,
                              roles: response.permissions
                        });
                        console.log("ROLES", this.state.roles);
                  })
                  .catch(error => {
                        console.log("Failed to get User data " + error);
                  });
      }
      render() {
            return (
                  <EditUserForm
                        history={this.props.history}
                        ivs={this.state}
                        api={this.props.addUser}
                  />
            );
      }
}

const Submit = async (params, values, { setSubmitting }) => {
      console.log("Submit params", params);
      let payload = {
            userName: values.userName,
            emailAddress: values.emailAddress,
            permissions: values.roles,
            addUser: params.ivs.addUser,
            complete: params.history.goBack
      };
      params.api(payload);
      setSubmitting(false);
};

export default withRouter(EditUser);
