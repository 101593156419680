import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { MDBInput, MDBBtn, MDBRow } from "mdbreact";


class NotifyAcq extends Component {
      render() {
            return (
                  <div className="TopSection">
                        <p>
                              If you wish to add more recipients when notifying
                              recipients, then please add them using the input
                              boxes below.
                        </p>
                        <p>Please enter emails in the following format:</p>
                        <code>example@email.com ; example2@email.com ; example3@email.com ;</code>
                        <MDBInput />
                        <MDBRow
                              center
                              style={{ display: "inline", marginLeft: "0px" }}
                        >
                              <MDBBtn >
                                    Submit
                              </MDBBtn>
                              <MDBBtn
                                    color="danger"
                                   
                              >
                                    Cancel
                              </MDBBtn>
                        </MDBRow>
                        
                  </div>
            );
      }
}

export default withRouter(NotifyAcq);
