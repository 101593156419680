import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { MDBRow, MDBBtn } from "mdbreact";
import Amplify from "../api.js";
import { Formik, Form } from "formik";
import {
      DefaultTextInput,
      DefaultTimePicker,
      DefaultCheckbox
} from "../formik.js";
import { cdpenv } from "../../scp-exports.js";
import "./Parameters.css";

class EditParameters extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  settlementTime: "00:00",
                  checkboxes: ["eod"],
                  accesskey: "",
                  PenniesAccessToken:"",
                  PenniesMerchantID:"",
                  submitted: false
            };
      }
      componentDidMount() {
            window.scrollTo(0, 0);
            this.getParams();
      }

      async getParametersAPI() {
            let apiName = "TennantAPI";
            let path = "manageterminals/parameters";
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.get(apiName, path, myInit);
      }

      complete = response => {
            console.log("Submit response", response);
            this.setState({
                  settlementTime: response.SettlementTime,
                  checkboxes: response.Flags,
                  accesskey: response.AccessKey,
                  PenniesAccessToken:response.PenniesAccessToken,
                  PenniesMerchantID:response.PenniesMerchantID,
                  submitted: true
            });
      };
      getParams() {
            this.getParametersAPI()
                  .then(response => {
                        console.log("Response", response);
                        this.setState({
                              settlementTime: response.SettlementTime,
                              checkboxes: response.Flags,
                              PenniesAccessToken:response.PenniesAccessToken,
                              PenniesMerchantID:response.PenniesMerchantID,
                              accesskey: response.AccessKey
                        });
                  })
                  .catch(error => {
                        console.log("Failed to get User data " + error);
                  });
      }
      render() {
            return (
                  <EditParametersForm
                        history={this.props.history}
                        ivs={this.state}
                        api={this.props.parameters}
                        complete={this.complete}
                  />
            );
      }
}

const Submit = async (params, values, { setSubmitting }) => {
      console.log("Submit params", params);

      let payload = {
            settlementTime: values.settlementTime,
            checkboxes: values.checkboxes,
            complete: params.complete,
            PenniesAccessToken:values.PenniesAccessToken,
            PenniesMerchantID: values.PenniesMerchantID
      };

      params.api(payload);
      setSubmitting(false);
      console.log(params,values)
};

const EditParametersForm = params => {
      console.log("params", params);

      return (
            <div className="TopSection">
                  <p>
                        Follow the instructions below to download settlement
                        files:
                  </p>
                  <ol>
                        <li>
                              If you do not have it installed on your computer
                              already, download and install Curl. A windows
                              version is available from{" "}
                              <a href="https://curl.haxx.se/windows/">
                                    https://curl.haxx.se/windows/
                              </a>
                              . Pick either 64 bit or 32 bit depending on your
                              Operating System.
                        </li>
                        <li>Open Command Prompt on your computer</li>
                        <li>
                              On the SCP website, on the Parameters page, tick
                              the box next to “Generate Access Key” and click
                              submit.
                        </li>
                        <li>
                              Copy the key that is generated in the “Access Key”
                              box.
                        </li>
                        <li>
                              In the Command Prompt box, copy in the following,
                              replacing abc with your company name as indicated
                              on the scp website, and xxxx-xxxx-xxxx-xxxx-xxxx
                              with the key you just generated:
                              <b>
                                    curl -O -J --data
                                    org=abc&amp;acquirer=test&amp;accesskey=xxxx-xxxx-xxxx-xxxx-xxxx{" "}
                                    {cdpenv.FilesURL}
                              </b>
                        </li>
                        <li>
                              Once the download has finished, which is indicated
                              by a message saying “curl: Saved to filename”
                        </li>
                  </ol>
                  <p>
                        There are two options for how transactions are settled.
                        You can opt for settlement to be by terminal end of day
                        or at a fixed cut off time. For terminal end of day your
                        EPOS system must send and end of day message when Z
                        totals are run. Otherwise use a fixed settlement time.
                  </p>
                  <Formik
                        enableReinitialize
                        initialValues={params.ivs}
                        onSubmit={(values, { setSubmitting }) => {
                              console.log("Submitting", params, values);
                              Submit(params, values, { setSubmitting });
                        }}
                  >
                        {formik => (
                              <Form className="InputForm">
                                    <MDBRow
                                          center
                                          style={{ display: "contents" }}
                                    >
                                          <DefaultTextInput
                                                id="access-key-input"
                                                label="Access Key"
                                                name="accesskey"
                                                type="text"
                                                disabled={true}
                                          />
                                    </MDBRow>
                                    <MDBRow
                                        center
                                        style={{ display: "contents" }}
                                    >
                                         <DefaultTextInput
                                               id="PenniesAccessToken"
                                               label="Pennies Access Token"
                                               name="PenniesAccessToken"
                                               type="text"
                                              />
                                    </MDBRow>
                                    <MDBRow
                                        center
                                        style={{ display: "contents" }}
                                    >
                                          <DefaultTextInput
                                              id="PenniesMerchantID"
                                              label="Pennies Merchant ID"
                                              name="PenniesMerchantID"
                                              type="text"
                                          />
                                    </MDBRow>
                                    <MDBRow
                                          center
                                          style={{ display: "contents" }}
                                    >
                                          <DefaultTimePicker
                                                id="settlement-time-picker"
                                                label="Settlement Time"
                                                name="settlementTime"
                                                disabled={
                                                      formik.values.checkboxes.includes(
                                                            "eod"
                                                      ) ||
                                                      formik.values.submitted
                                                }
                                          />
                                    </MDBRow>
                             
                                    <MDBRow
                                          center
                                          style={{ display: "contents" }}
                                          className="checkboxes"
                                    >
                                          <DefaultCheckbox
                                                id="terminal-eod-tickbox"
                                                name="checkboxes"
                                                value="eod"
                                                disabled={
                                                      formik.values.submitted
                                                }
                                                label="Terminal End of Day"
                                          ></DefaultCheckbox>
                                    </MDBRow>
                                    
                                    <MDBRow
                                          center
                                          style={{ display: "contents" }}
                                          className="checkboxes"
                                    >
                                          {formik.values.accesskey.length <
                                          1 ? (
                                                <DefaultCheckbox
                                                      id="generate-AK-checkbox"
                                                      name="checkboxes"
                                                      value="newaccesskey"
                                                      disabled={
                                                            formik.values
                                                                  .submitted
                                                      }
                                                      label="Generate Access Key"
                                                ></DefaultCheckbox>
                                          ) : (
                                                <></>
                                          )}
                                    </MDBRow>
                                    {formik.values.submitted ? (
                                          <MDBRow
                                                center
                                                style={{ display: "contents" }}
                                          >
                                                <p>Updated</p>
                                          </MDBRow>
                                    ) : (
                                          <MDBRow
                                                center
                                                style={{
                                                      display: "inline",
                                                      marginLeft: "0px"
                                                }}
                                          >
                                                <MDBBtn
                                                      id="submit-btn"
                                                      onClick={
                                                            formik.submitForm
                                                      }
                                                >
                                                      Submit
                                                </MDBBtn>
                                                <MDBBtn
                                                      id="cancel-btn"
                                                      color="danger"
                                                      onClick={() => {
                                                            params.history.goBack();
                                                      }}
                                                >
                                                      Cancel
                                                </MDBBtn>
                                          </MDBRow>
                                    )}
                              </Form>
                        )}
                  </Formik>
            </div>
      );
};

export default withRouter(EditParameters);
