import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBAnimation } from "mdbreact";

const ApiFeatures = () => {
    return (
        <section className="text-center my-5" style={{ backgroundColor: "white", padding: "20px" }}>
            <h2 className="h1-responsive font-weight-bold my-5">
                Why choose us?
        </h2>
            <p className="lead grey-text w-responsive mx-auto mb-5">
            There are many reasons to choose SureCanPay as your payment provider, below are just a few
        </p>
            <MDBRow>
                <MDBCol md="4">
                    <MDBAnimation type="fadeInLeft">
                        <MDBIcon icon="lock" size="3x" className="red-text" />
                        <h5 className="font-weight-bold my-4">Safe & Secure</h5>
                        <p className="grey-text mb-md-0 mb-5">
                            Payment security and fraud reduction are our top priorities
            </p>
                    </MDBAnimation>
                </MDBCol>
                <MDBCol md="4">
                    <MDBAnimation type="fadeInUp">
                        <MDBIcon icon="chart-line" size="3x" className="cyan-text" />
                        <h5 className="font-weight-bold my-4">Scalability</h5>
                        <p className="grey-text mb-md-0 mb-5">
                            Payment Solutions that scale as your business expands
            </p>
                    </MDBAnimation>
                </MDBCol>
                <MDBCol md="4">
                    <MDBIcon far icon="thumbs-up" size="3x" className="orange-text" />
                    <h5 className="font-weight-bold my-4">Reliability</h5>
                    <p className="grey-text mb-md-0 mb-5">
                        Infrastructure hosted with Amazon's AWS which guarantees certified reliability
            </p>
                </MDBCol>
            </MDBRow>
        </section>
    );
}

export default ApiFeatures;