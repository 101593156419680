import React from "react";
import { useField, Field } from 'formik';
import styled from "@emotion/styled";
import { MDBInput, MDBDatePicker} from "mdbreact";
import './formik.css';

export const DefaultTextInput = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and also replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>

                  <MDBInput className="text-input" label={label} outline {...field} {...props} style={{ marginTop: "0px!important", marginBottom: "0px!important" }} />
                  {meta.touched && meta.error ? (
                        <div className="error">{meta.error}</div>
                  ) : null}

            </>
      );
};

export const DefaultCheckbox = ({ label, ...props }) => {

      return (
            <Field name={props.name}>
                  {({ field, form }) => (
                        <label>
                              <input style={{position:"inherit", marginRight:"10px"}}
                                    type="checkbox"
                                    {...props}
                                    checked={field.value.includes(props.value)}
                                    onChange={() => {
                                          if (field.value.includes(props.value)) {
                                                const nextValue = field.value.filter(
                                                      value => value !== props.value
                                                );
                                                form.setFieldValue(props.name, nextValue);
                                          } else {
                                                const nextValue = field.value.concat(props.value);
                                                form.setFieldValue(props.name, nextValue);
                                          }
                                    }}
                              />
                              {label}
                        </label>
                  )}
            </Field>
      );
};

export const DefaultTimePicker = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>
                  <MDBInput className="text-input" type="time" label={label} outline={true} {...field} {...props} style={{ marginTop: "0px!important", marginBottom: "0px!important" }} />
                  {meta.touched && meta.error ? (
                        <div className="error">{meta.error}</div>
                  ) : null}

            </>
      );
};

export const DefaultDateInput = ({ label,size, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>
                  <MDBInput className="text-input" type="date" label={label} size={size} outline={true} {...field} {...props} style={{ marginTop: "0px!important", marginBottom: "0px!important" }} />
                  {meta.touched && meta.error ? (
                        <div className="error">{meta.error}</div>
                  ) : null}

            </>
      );
};



export const DefaultDatePicker = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>
                  <MDBDatePicker className="text-input" type="date" label={label} outline {...field} {...props} style={{ marginTop: "0px!important", marginBottom: "0px!important" }} />
                  {meta.touched && meta.error ? (
                        <div className="error">{meta.error}</div>
                  ) : null}

            </>
      );
};

// Styled components ....
// const StyledSelect = styled.select`
//       color: var(--blue);
//     `;

const StyledErrorMessage = styled.div`
      font-size: 12px;
      color: var(--red-600);
      width: 400px;
      margin-top: 0.25rem;
      &:before {
        content: "❌ ";
        font-size: 10px;
      }
      @media (prefers-color-scheme: dark) {
        color: var(--red-300);
      }
    `;

const StyledLabel = styled.label`
      margin-top: 0.2rem;
      font-weight: 500;
    `;



export const DefaultSelect = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>
                  <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
                  <select className="browser-default custom-select" {...field} {...props} label={label} />
                  {meta.touched && meta.error ? (
                        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
                  ) : null}
            </>
      );
};

export const ClosableDefaultSelect= ({ label, closable, onClose, selected, index, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    if (closable) {
        return (
            <>
                <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
                <div style={{display: "grid", gridTemplateColumns: "30fr 1fr", justifyContent: "start"}}>
                    <select style={{display: "inline", width: "95%"}}
                            className="browser-default custom-select" value={selected} {...field} {...props} label={label}/>
                    {meta.touched && meta.error ? (
                        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
                    ) : null}
                    <button type="button"
                            style={{
                                border: "none",
                                background: "none",
                                fontSize: "30px",
                                float: "left",
                                horizontalAlign: "left"
                            }}
                            onClick={() => onClose(index)}>
                        <span>&times;</span>
                    </button>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
                <div style={{display: "grid", gridTemplateColumns: "30fr 1fr"}}>
                    <select style={{display: "inline", width: "95%"}}
                            className="browser-default custom-select" value={selected} {...field} {...props} label={label}/>
                    {meta.touched && meta.error ? (
                        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
                    ) : null}
                    <button type="button"
                            style={{
                                border: "none",
                                background: "none",
                                fontSize: "30px",
                                float: "left",
                                horizontalAlign: "left",
                                visibility: "hidden"
                            }}>
                        <span>&times;</span>
                    </button>
                </div>
            </>
        );
    }
};


