import "./Header.css";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import React, { Fragment } from "react";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import routes from "../../config/routes";
import { MDBBtn, MDBIcon, MDBNavItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import SCP from "./SCPLogo.png"


const HeaderItem = ({ showForRoles, roles, name, route }) => {
      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return null;
      return (
            <LinkContainer to={route} style={{ color: "#000" }}>
                  <Nav.Link>{name}</Nav.Link>
            </LinkContainer >
      );
};

const DropDownItem = ({ showForRoles, roles, name, route }) => {
      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return null;
      return (
            <LinkContainer  className="DropItem" to={route} >
                  <NavDropdown.Item>{name}</NavDropdown.Item>
            </LinkContainer >
      );
};

export const Header = ({ loggedIn, logout, user }) => (
      <div className="Header">
            <Navbar collapseOnSelect expand="lg" bg="white" style={{ fixed: "top", paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
                  <Navbar.Brand style={{ margin: "0", padding: "0" }}>
                        <Link id="home" className="Logo" to={routes.home} style={{ listStyle: "none", textDecoration: "none" }}>
                              <img src={SCP} alt="Sure Can Pay Company Logo" style={{ height: "50px", width: "200px" }} />
                        </Link>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav >
                              {loggedIn.length > 0 ? (
                                    <Fragment >

                                          <HeaderItem route={routes.authproducts} name="Products" showForRoles={['Admin', 'Guest']} roles={loggedIn} />
                                          {/* <HeaderItem route={routes.authcompany} name="Company" showForRoles={['Admin', 'Guest']} roles={loggedIn} /> */}


                                          <NavDropdown title="Manage" id="collasible-nav-dropdown">
                                                <DropDownItem route={routes.manageTerminals} name="Terminal" showForRoles={['Admin','Developer', 'ManageTerminals']} roles={loggedIn} />
                                                <DropDownItem route={routes.myorganisation} name="Users" showForRoles={['Admin']} roles={loggedIn} id="manage-users"/>
                                                <DropDownItem route={routes.orders} name="Orders" showForRoles={['Admin', 'Orders']} roles={loggedIn} />
                                                {/* <DropDownItem route={routes.stock} name="Stock" showForRoles={['Admin', 'ManageTerminals', 'Orders']} roles={loggedIn} /> */}
                                          </NavDropdown>

                                          <NavDropdown title="Support" id="collasible-nav-dropdown">
                                                <DropDownItem route={routes.developer} name="Developers" showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                                                <DropDownItem route={routes.authSupport} name="Troubleshooting" showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                                                <DropDownItem route={routes.authTraining} name="Training" showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                                          </NavDropdown>

                                          <NavDropdown title="Transactions" id="collasible-nav-dropdown">
                                          <DropDownItem route={routes.transactions} name="Transactions" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                                                <DropDownItem route={routes.batches} name="Batches" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />                                                
                                                <DropDownItem route={routes.parameters} name="Settings" showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                                          </NavDropdown>
                                          <Nav className="userHeader">
                                          <MDBIcon icon="user-alt" className="user-icon"/>
                                          <NavDropdown title={user} id="collasible-nav-dropdown">
                                          <DropDownItem route={routes.changePassword} name="Change Password" showForRoles={['Admin', 'Guest']} roles={loggedIn} />
                                          </NavDropdown>
                  
                                                <NavItem className="button" onClick={logout}>
                                                      <MDBBtn id="logout" size="sm">Logout</MDBBtn>
                                                </NavItem>
                                          </Nav>                                    

                                           
                                    </Fragment>
                              ) : (
                                          <Fragment>
                                                <LinkContainer id="products" className="NavItem" to={routes.products}>
                                                      <NavItem>Products</NavItem>
                                                </LinkContainer>

                                                {/* <LinkContainer className="NavItem" to={routes.company}>
                                                      <NavItem>Company</NavItem>
                                                </LinkContainer> */}

                                                <LinkContainer id="troubleshooting" className="NavItem" to={routes.support}>
                                                      <NavItem>Troubleshooting</NavItem>
                                                </LinkContainer>

                                                <Nav className="userHeader">
                                                      <LinkContainer id="sign-up" className="NavItem" to={routes.signup}>
                                                            <MDBBtn id="sign-up" size="sm" color="yellow">Sign up</MDBBtn>
                                                      </LinkContainer>

                                                      <LinkContainer id="login" className="NavItem" to={routes.login}>
                                                            <MDBBtn id="login" size="sm">Login</MDBBtn>
                                                      </LinkContainer>
                                                </Nav>

                                          </Fragment>
                                    )}
                        </Nav>
                  </Navbar.Collapse>
            </Navbar>
      </div>
);

Header.defaultProps = {
      loggedIn: [],
};

Header.propTypes = {
      logout: PropTypes.func.isRequired,
      loggedIn: PropTypes.arrayOf(PropTypes.string)
};

export default Header;