import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import { MDBBtn} from "mdbreact";

class PostcodeLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectbox: false,
            addresses: [], //["address1", "address2"],
            hits: []
        }
        this.lookupAddress = this.lookupAddress.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.postcodeLookUp = this.postcodeLookUp.bind(this);
    }

    //When the lookup button is pushed this should display the select box
    lookupAddress(callback, postcode) {
        console.log("Looking up Postcode", postcode)
        //DS do lookup with post code and save hits to state hits is an array with up to 20 entries
        // populate addresses with first line of each hit
        this.postcodeLookUp(postcode);

        this.setState({
            selectbox: true,
            callback: callback
        })
    }
    postcodeLookUp(postcode) {
        console.log("POSTCODE" + postcode)
        var idealPostcodes = require("ideal-postcodes")("ak_k85tsotwTQ7cxmB0iRITEZSQ3KqNz");
        idealPostcodes.lookupAddress({
            query: postcode,  	// required Free test postcode-"ID1 1QD"
            limit: 20,				 	// optional
            page: 0 						// optional
        }, (error, result) => {
            if (result === null) {
                this.setState({ addresses: [] })
            } else {
                var array = [], item = "";
                var i;
                console.log("RESULTS", result)
                for (i = 0; i < result.result.hits.length; i++) {
                    item = `${result.result.hits[i].building_number} ${result.result.hits[i].thoroughfare}`;
                    array.push(item);
                }
                this.setState({ addresses: array })
                this.setState({ hits: result.result.hits })
                console.log("hits", this.state.hits)
            }
        })
    }

    handleAddress(event) {

        const selectedAddress = event.target.value;
        console.log("SELECTED ADDRESS", selectedAddress)

        var hits = this.state.hits;
        var search = hits.filter(function (hits) {
            return `${hits.building_number} ${hits.thoroughfare}` === selectedAddress;
        })[0];

        console.log("hits", this.state.hits)
        console.log("Search", search)

        const address = {
            Line1: search.building_number+ " " + search.thoroughfare,//hit[6].addressline2
            Line2: "",
            townCity: search.post_town,
            County: search.county,
            Country: search.country
        }

        //take the event loop through addresses to find out which one it is ie. it s the third

        //when addresses[i] ===event i is the correct hit.  You have saved the hits to state so take hit[i]

        //set address to be the third hit just line1  line2  towncity and country

        this.state.callback(address)
        this.setState({ selectbox: false })
        console.log("send address to callback ", address)
    }

    render() {
        return (
            <>
                <MDBBtn style={{marginLeft:"0px"}} onClick={() => { this.lookupAddress(this.props.update, this.props.postcode) }}>Search</MDBBtn>
                {this.state.selectbox ? (
                    <select label="postcodeLookup" name="postcodeLookup" onChange={this.handleAddress} >
                        <option key="default">Choose an Address</option>
                        {this.state.addresses.map(function (array, key) { return <option key={key} value={array}  > {array} </option> })}
                    </select>) : (<></>)
                }
            </>
        );
    }
}

export default withRouter(PostcodeLookup);