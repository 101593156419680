import "./SignUp.css";
import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import { Button } from "react-bootstrap";
import CustomInputComponent from "../CustomInputComponent";
import OrgName from "../OrgName/OrgName";
import InfoHide from "../InfoHide";

const SignUp = ({ dirty, isSubmitting }) => (
      <div className="SignUp simple-form" style={{ minHeight: "73.65vh" }}>
            <Form>
                  <InfoHide info1="Sign Up Page" />
                  <OrgName />
                  <Field
                        autoFocus
                        type="text"
                        name="username"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  >
                        {" "}
                        <p className="validation">
                        Username should be lowercase and at least 3 characters. No special characters
                        </p>
                  </Field>

                  <Field
                        autoFocus
                        type="email"
                        name="email"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  >
                        {" "}
                        <p className="validation">Required</p>
                  </Field>
                  <Field
                        autoFocus
                        type="password"
                        name="password"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  >
                        {" "}
                        <p className="validation">
                              Must contain at least 1 uppercase, 1 number & 1
                              special character. Min length 8 characters
                        </p>
                  </Field>
                  <Field
                        autoFocus
                        type="password"
                        name="confirmPassword"
                        bssize="large"
                        component={CustomInputComponent}
                        autoComplete="off"
                  />
                  <Button
                        block
                        type="submit"
                        bssize="large"
                        disabled={!dirty || isSubmitting}
                  >
                        Sign up
                  </Button>
            </Form>
      </div>
);

SignUp.defaultProps = {
      dirty: false,
      isSubmitting: false
};

SignUp.propTypes = {
      dirty: PropTypes.bool,
      isSubmitting: PropTypes.bool
};

export default SignUp;
