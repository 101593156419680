import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import validationSchema from "./validationSchema";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import { Auth } from "aws-amplify";
import routes from "../../config/routes";
import history from "../../history";
import {MDBContainer, MDBRow} from "mdbreact";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./ForgotPassword.css"


export class ForgotPasswordContainer extends React.Component {
      constructor(props) {
            super(props);

            this.state = { userName: "", orgName: "", error: "", awaitingConfirmation: false, submitted: false };
            this.handleUserName = this.handleUserName.bind(this);
            this.handleOrgName = this.handleOrgName.bind(this);

      };

      initialValues = {
            confirmationCode: "",
            newPassword: "",
            confirmNewPassword: ""
      };

      async forgotPassword({ username, onSuccess }) {
            console.log("forgot password")

            Auth.forgotPassword(username).then(code => {
                  console.log('response', code);
                  this.setState({ awaitingConfirmation: true })
                  this.setState({ error: ""})
            }).catch(error => {
                  console.log('Failed to get data ', error);
                  this.setState({ error: error.message})
            });
      };      

      onSubmitUsername = evt => {
            evt.preventDefault();
            console.log("On Sumbit Username")
            const username = this.state.orgName + "/" + this.state.userName
            const onSuccess = () =>
                  this.setState({ awaitingConfirmation: true });
            this.forgotPassword({ username, onSuccess });
      };

      onSubmitNewPassword = ({ confirmationCode, newPassword }) => {
            const username = this.state.orgName + "/" + this.state.userName
            this.resetPassword({
                  username,
                  newPassword,
                  confirmationCode
            });
      };

      handleOrgName(event) {
            this.setState({ orgName: event.target.value });
      }
      handleUserName(event) {
            this.setState({ userName: event.target.value });
      }

      async resetPassword({ username, confirmationCode, newPassword }) {
            console.log("In reset password")

            await Auth.forgotPasswordSubmit(
                  username,
                  confirmationCode,
                  newPassword,
            ).then(this.setState({submitted: true, error: ""}, function() {})).catch(error => {
                  this.setState({ error: error.message, submitted: false}, function(){} )
            })
            if(this.state.error === "" && this.state.submitted === true){
                  this.setState({error: "Password changed, redirecting"})
                  history.push(routes.login) 
            }
           ;
      }

      render() {
            console.log("Awaiting Confirmation", this.state.awaitingConfirmation)
            return(
            <MDBContainer >
                  <MDBRow center>
                        <FormLabel className="error-label">{this.state.error}</FormLabel>
                  </MDBRow>

             {this.state.awaitingConfirmation ? (
                  <Formik
                        onSubmit={this.onSubmitNewPassword}
                        component={ForgotPasswordSubmit}
                        initialValues={this.initialValues}
                        validationSchema={validationSchema}
                  />
            ) : (

                        <div className="ForgotPassword simple-form" style={{ minHeight: "73.65vh" }}>
                              <form>
                                    <FormGroup controlId="username" bssize="large">
                                         
                                          <FormLabel>Enter your Organisation</FormLabel>
                                          <FormControl
                                                autoFocus
                                                type="username"
                                                value={this.state.orgName}
                                                onChange={this.handleOrgName}
                                          />
                                          <FormLabel style={{ paddingTop: "10px" }}>Enter your Username</FormLabel>
                                          <FormControl
                                                autoFocus
                                                type="username"
                                                value={this.state.userName}
                                                onChange={this.handleUserName}
                                          />
                                    </FormGroup>
                                    <Button block bssize="large" type="submit" onClick={this.onSubmitUsername}>
                                          Reset Password
                  </Button>
                              </form>
                        </div>
                  )}
                  </MDBContainer>
            )
      }
}

ForgotPasswordContainer.propTypes = {
      // dispatch: PropTypes.object.isRequired,
      forgotPassword: PropTypes.func.isRequired
};

export default ForgotPasswordContainer;
