export default {
      login: "/unauth/login",
      signup: "/unauth/signup",
      home: "/",
      products: "/unauth/products",
      authproducts: "/auth/products",
      company: "/unauth/company",
      authcompany: "/auth/company",
      pricing: "/unauth/pricing",
      myorganisation: "/auth/myorganisation",
      transactions: "/auth/transactions",
      batches: "/auth/batches",
      product: "/auth/product/",
      support: "/unauth/troubleshooting",
      authSupport: "/auth/troubleshooting",
      forgotPassword: "/unauth/forgotPassword",
      adminproducts: "/admin/products",
      admincompany: "/admin/company",
      adminmyorganisation: "/admin/myorganisation",
      admintransactions: "/admin/transactions",
      orders: "/auth/orders",
      neworders: "/auth/orders/new-orders",
      editTerminal: "/auth/edit-tid",
      manageTerminals: "/auth/manage-terminals",
      detail: "/auth/orderdetail",
      developer: "/auth/developer",
      parameters: "/auth/settings",
      editTid: "/auth/edit-tid",
      generatePIN: "/auth/generate-PIN",
      editUser: "/auth/edit-user",
      changePassword: "/auth/changepassword",
      stock: "/auth/stock",
      authTraining: "/auth/training"

};
