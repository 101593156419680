import React, { Fragment } from 'react';
import { MDBMask, MDBView } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import routes from "../../config/routes";
import ProjectsRow from './Components/ProjectsRow';
import FooterJumb from './Components/FooterJumb';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBSmoothScroll, MDBIcon, MDBAnimation } from "mdbreact";
import { Link } from 'react-router-dom';
import Gradient3 from './gradient3.png';

import './Home.css';
// import Gradient3 from './Components/gradient3.png';
import PropTypes from "prop-types";

const ButtonLink = ({ showForRoles, roles, name, route, color, id }) => {
      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return null;
      return (
            <Link to={route}>
                  <MDBBtn id={id} size="lg" color={color}>{name}</MDBBtn>
            </Link>
      );
};

export const Home = ({ loggedIn }) => (
      <Fragment >
            <MDBContainer fluid style={{ margin: "0", padding: "0", minHeight: "73.65vh" }}>
                  {loggedIn.length === 0 ? (
                        <MDBView src={Gradient3} >
                              <MDBMask className="d-flex justify-content-center align-items-center" overlay="blue-light">
                                    <MDBAnimation reveal type="zoomIn">
                                          <MDBContainer id="section0">
                                                <MDBRow>
                                                      <MDBCol md="12" className="mb-4 text-center" >
                                                      <h1 className="logo-scp"><span className="Sure">SURE</span><span className="Can">CAN</span><span className="Pay" >PAY</span></h1>
                                                            <h5 className="text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 font-weight-bold" >Payment Solutions</h5>
                                                            <h5>Welcome to SureCanPay, the easiest way to integrate point of sale payments to cash registers and EPOS systems</h5>
                                                            <p>SureCanPay provides PIN entry devices and a simple robust API for integrating payments into new and existing systems</p>
                                                      </MDBCol>
                                                </MDBRow>
                                                <MDBRow style={{ justifyContent: "center", align: "center" }}>
                                                      <div className="mb-4 white-text text-center">
                                                            <MDBSmoothScroll to="section1">
                                                                  <MDBAnimation type="pulse" infinite>
                                                                        <MDBIcon icon="chevron-circle-down" size="5x" />
                                                                  </MDBAnimation>
                                                            </MDBSmoothScroll>
                                                      </div>
                                                </MDBRow>
                                          </MDBContainer>
                                    </MDBAnimation>
                              </MDBMask>
                        </MDBView>
                  ) : (
                              <MDBView src={`https://mdbootstrap.com/img/Photos/Others/gradient3.png`} >
                                    <MDBMask className="d-flex justify-content-center align-items-center" overlay="blue-light">
                                          <MDBAnimation reveal type="zoomIn">
                                                <MDBContainer>
                                                      <MDBRow>
                                                            <MDBCol md="12" className="mb-4 text-center">
                                                                  <h1 className="logo-scp"><span className="Sure">SURE</span><span className="Can">CAN</span><span className="Pay" >PAY</span></h1>
                                                                  <h5 className="text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 font-weight-bold">Payment Solutions</h5>
                                                                  <h5>
                                                                  Welcome to SureCanPay, please use the quick navigation links below.
                                                                  </h5>
                                                            </MDBCol>
                                                      </MDBRow>
                                                      <MDBRow style={{ justifyContent: "center", align: "center" }}>
                                                            <div className="mb-4 white-text text-center" style={{ margin: "10px" }}>
                                                                  <MDBRow center>
                                                                  <MDBAnimation type="pulse" delay="2s" count={1}>                                                                       
                                                                              <ButtonLink id="orders-btn" route={routes.authSupport} name="Support" color="warning" showForRoles={['Admin', 'Orders', 'Developers', 'ViewTransactions', 'ManageTerminals', 'Guest' ]} roles={loggedIn} />
                                                                  </MDBAnimation>

                                                                 

                                                                  <MDBAnimation type="pulse" delay="4s" count={2}>
                                                                        <Link to="/auth/orders">
                                                                              <ButtonLink id="orders-btn" route={routes.orders} name="Orders" color="success" showForRoles={['Admin', 'Orders']} roles={loggedIn} />
                                                                        </Link>
                                                                  </MDBAnimation>

                                                                  <MDBAnimation type="pulse" delay="6s" count={2}>
                                                                        <ButtonLink id="developers-btn" route={routes.developer} name="Developers" color="info" showForRoles={['Admin', 'Developers']} roles={loggedIn} />
                                                                  </MDBAnimation>
                                                                  </MDBRow>
                                                                  
                                                                  <MDBRow center>
                                                                  <MDBAnimation type="pulse" delay="8s" count={2}>
                                                                        <ButtonLink id="transactions-btn" route={routes.transactions} name="transactions" color="secondary" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                                                                  </MDBAnimation>

                                                                  <MDBAnimation type="pulse" delay="10s" count={2}>
                                                                        <ButtonLink id="terminals-btn" route={routes.manageTerminals} name="Terminals" color="default" showForRoles={['Admin', 'ManageTerminals']} roles={loggedIn} />
                                                                  </MDBAnimation>

                                                                  <MDBAnimation type="pulse" delay="12s" count={2}>
                                                                        <ButtonLink id="batches-btn" route={routes.batches} name="Batches" color="light-blue" showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                                                                  </MDBAnimation>
                                                                  </MDBRow>
                                                            </div>
                                                      </MDBRow>
                                                </MDBContainer>
                                          </MDBAnimation>
                                    </MDBMask>
                              </MDBView>
                        )}



                  {loggedIn.length === 0 ? (
                        <Fragment>
                              <MDBContainer className="MDBContainer">
                                    <MDBRow id="section1">
                                          <ProjectsRow />
                                    </MDBRow>
                                    <MDBRow style={{ justifyContent: "center", align: "center" }}>
                                          <MDBSmoothScroll to="section2" >
                                                <MDBAnimation type="pulse" infinite>
                                                      <MDBIcon icon="chevron-circle-down" size="5x" style={{ color: "#82b1ff" }} />
                                                </MDBAnimation>
                                          </MDBSmoothScroll>
                                    </MDBRow>
                              </MDBContainer>


                              <MDBAnimation reveal type="fadeInUp">
                                    <div id="bottomPage">
                                          <br />
                                          <br />
                                          <br />
                                    </div>
                              </MDBAnimation>
                        </Fragment>
                  ) : (
                              <p style={{ height: "0px", display: "none", margin: "0" }}>1</p>
                        )}

                  {loggedIn.length === 0 ? (
                        <MDBContainer style={{ paddingTop: "25px" }} id="section2">
                              <MDBRow>
                                    <MDBCol>
                                          <MDBAnimation reveal type="fadeInLeft">
                                                <FooterJumb />
                                          </MDBAnimation>
                                    </MDBCol>
                              </MDBRow>
                              <MDBRow style={{ justifyContent: "center", align: "center" }} >
                                    <MDBSmoothScroll to="section0">
                                          <MDBAnimation type="pulse" infinite id="end">
                                                <MDBIcon icon="chevron-circle-up" size="5x" style={{ color: "#82b1ff" }} />
                                          </MDBAnimation>
                                    </MDBSmoothScroll>
                              </MDBRow>
                        </MDBContainer>
                  ) : (<p style={{ height: "0px", display: "none", margin: "0" }}>2</p>
                        )}
            </MDBContainer>
      </Fragment>

);

Home.defaultProps = {
      loggedIn: []
};

Home.propTypes = {
      logout: PropTypes.func.isRequired,
      loggedIn: PropTypes.arrayOf(PropTypes.string)
};

export default withRouter(Home);
