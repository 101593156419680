import { connect } from "react-redux";
import Component from "./ManageTids";
// import NoAccess from "../NoAccess";
// import React from "react";

export default connect(({ auth: { loggedIn, user } }) => ({ loggedIn, user }))(Component)
// const permissionCheck = ({ showForRoles, roles }) => {
//       if (
//             roles.filter(role => showForRoles.includes(role)) !== "Admin" ||
//             "ManageTerminals"
//       ) {
//             return <NoAccess />;
//       } else {
//             return Component;
//       }
// }
// (permissionCheck)

