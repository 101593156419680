import React from "react";
import Amplify from "../../components/api";
import SCPTable from "../SCPTable.js";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import "./Stock.css";
import InfoHide from "../InfoHide";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";

class Stock extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  terminal: "",
                  enteredSerial: false,
                  limit: "5"
            };
            this.getTerminalsList = this.getTerminalsList.bind(this);
      }

      componentDidMount() {
            this.getTerminalsList();
      }

      async getTerminalALL() {
            let apiName = "TennantAPI";
            let path = "manageterminals/listterminals";

            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            return await Amplify.API.get(apiName, path, myInit);
      }

      getTerminalsList() {
            this.getTerminalALL()
                  .then(response => {
                        console.log("response", response);
                        if (response === null) {
                              this.setState({ date: [] });
                        } else {
                              let array = [],
                                    item = [];
                              var i;
                              for (i = 0; i < response.length; i++) {
                                    item = [
                                          { text: response[i].SerialNum },
                                          {
                                                text: response[
                                                      i
                                                ].CreateDate.slice(0, 10)
                                          },
                                          { text: response[i].TerminalType },
                                          {
                                                text: response[i].PINDate.slice(
                                                      0,
                                                      10
                                                )
                                          },
                                          { text: response[i].Status },
                                          { text: response[i].FirmwareVersion },
                                          { text: response[i].PIN }
                                    ];
                                    array.push(item);
                              }
                              this.setState({ data: array });
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get terminal" + error);
                  });
      }

      scrollToTop = () => window.scrollTo(0, 0);

      render() {
            const header = [
                  "SerialNum",
                  "Create Date",
                  "TerminalType",
                  "PIN Date",
                  "Status",
                  "Firmware Version",
                  "PIN"
            ];

            return (
                  <>
                        <MDBContainer
                              style={{
                                    minHeight: "73.65vh",
                                    paddingTop: "50px"
                              }}
                        >
                              <MDBRow style={{ marginTop: "10px" }}>
                                    <InfoHide
                                          info1="Stock: "
                                          info2="Use the search and filters to manage your stock."
                                    />
                                    <LinkContainer
                                          to={routes.manageTerminals}
                                    >
                                          <MDBBtn
                                                center
                                                className="ReturnButton"
                                                color="red"
                                          >
                                                <MDBIcon icon="arrow-circle-left" className="buttonIcon"/>
                                                Return to Terminal Page
                                          </MDBBtn>
                                    </LinkContainer>
                              </MDBRow>

                              <MDBRow style={{ marginBottom: "10px" }}>
                                    <MDBCol style={{ paddingLeft: "0px" }}>
                                          <SCPTable
                                                header={header}
                                                table={this.state.data}
                                          />
                                    </MDBCol>
                              </MDBRow>
                        </MDBContainer>
                  </>
            );
      }
}

export default Stock;
