import "./SignUpConfirmation.css";
import React from "react";
import PropTypes from "prop-types";
import {
      Button,
      FormText,
      FormGroup,
      FormControl,
      FormLabel
} from "react-bootstrap";

export const Component = ({
      formIsValid,
      handleSubmit,
      handleChange,
      confirmationCode
}) => (
      <div className="SignUpConfirmation simple-form" style={{ minHeight: "73.65vh" }}>
            <form onSubmit={handleSubmit}>
                  <FormGroup controlId="confirmationCode" bssize="large">
                        <FormLabel>Confirmation Code</FormLabel>
                        <FormControl
                              autoFocus
                              type="number"
                              value={confirmationCode}
                              onChange={handleChange}
                        />
                        <FormText>
                              Please enter the confirmation code we sent to your
                              email address.
                        </FormText> 
                  </FormGroup>
                  <Button
                        block
                        bssize="large"
                        disabled={!formIsValid}
                        type="submit"
                  >
                        Verify
                  </Button>
            </form>
      </div>
);

Component.defaultProps = {
      formIsValid: true,
      confirmationCode: ""
};

Component.propTypes = {
      formIsValid: PropTypes.bool,
      handleSubmit: PropTypes.func.isRequired,
      handleChange: PropTypes.func.isRequired,
      confirmationCode: PropTypes.string
};

export default Component;
