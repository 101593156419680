import React from "react";
import { MDBRow, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, MDBAnimation } from "mdbreact";
import { Link } from "react-router-dom";

import P0 from './p0.jpg';
import P2 from './p2.jpg';

const ProjectsRow = () => {
  return (
    <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold my-5">
        Getting Started
      </h2>
      <p className="grey-text w-responsive mx-auto mb-5">
        In this section is a brief introduction to our services and us as a company.
        Additionally, if you're interested in using SureCanPay for your
        payment provider create an account with us
      </p>

      <MDBRow className="text-center">
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBAnimation reveal type="slideInLeft">
            <MDBView className="overlay rounded z-depth-1" waves>
              <img
                src={P0}
                alt=""
                className="img-fluid"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
            <MDBCardBody className="pb-0">
              <h4 className="font-weight-bold my-3">Introduction</h4>
              <p className="grey-text">
                All payment processing is PCI approved and cloud hosted, for maximum
              resilience scalability and speed SureCanPay provides you with a portal
              for analysing payments and managing your POS estate
            </p>
              <MDBAnimation type="pulse" delay="6s" count={2}>
                <Link to="/unauth/products">
                  <MDBBtn color="info">Find out More</MDBBtn>
                </Link>
              </MDBAnimation>
            </MDBCardBody>
          </MDBAnimation>
        </MDBCol>

        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBAnimation reveal type="slideInBottom">
            <MDBView className="overlay rounded z-depth-1" waves>
              <img
                src="https://mdbootstrap.com/img/Photos/Others/project4.jpg"
                alt=""
                className="img-fluid"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
            <MDBCardBody className="pb-0">
              <h4 className="font-weight-bold my-3">Sign Up</h4>
              <p className="grey-text">
                Create an account and explore the documentation. SureCanPay integration examples
              are provided in many languages and support is provided for Android, Windows, Mac and Linux
            </p>
              <MDBAnimation type="pulse" delay="6s" count={2}>
                <Link to="/unauth/signup">
                  <MDBBtn color="info">Sign Up</MDBBtn>
                </Link>
              </MDBAnimation>
            </MDBCardBody>
          </MDBAnimation>
        </MDBCol>

        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBAnimation reveal type="slideInRight">
            <MDBView className="overlay rounded z-depth-1" waves>
              <img
                src={P2}
                alt=""
                className="img-fluid"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>

            <MDBCardBody className="pb-0">
              <h4 className="font-weight-bold my-3">Login</h4>
              <p className="grey-text">
                The SureCanPay development environment provides
              all the tools needed to integrate and test an integrated payment application. Sample PIN entry devices
               can be ordered online
            </p>
              <MDBAnimation type="pulse" delay="6s" count={2}>
                <Link to="/unauth/login">
                  <MDBBtn color="info">Login</MDBBtn>
                </Link>
              </MDBAnimation>
            </MDBCardBody>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </section>
  );
}

export default ProjectsRow;