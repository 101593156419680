import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBAnimation } from "mdbreact";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from 'react-router-dom';
import Amplify from "../api.js";
import SCPTable from "../SCPTable.js";

class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.getOrder = this.getOrder.bind(this);
        this.state = {
            data: [],
        };

    }

    //Data is returned in the following format from get /orders


    async getData() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const ordernum = params.get('order');
        let apiName = 'TennantAPI';
        let path = 'orders/' + ordernum;
        let myInit = {
            headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
        }
        console.log('Calling ', apiName)
        return await Amplify.API.get(apiName, path, myInit);
    }

    getOrder() {

        this.getData().then(response => {
            console.log('response', response)
            if (response === null) {
                this.setState({ data: [] })
            } else {
                var a = [], b = {};
                var j;


                for (j = 0; j < response.orderDetail.length; j++) {
                    b = [
                        { text: response.OrderNum },
                        { text: response.orderDetail[j].terminalType },
                        { text: response.orderDetail[j].TID },
                        { text: response.orderDetail[j].status },
                        { text: response.companyName },
                        { text: response.deliveryAddress.addressLine1 },
                        { text: response.deliveryAddress.addressLine2 },
                        { text: response.deliveryAddress.townCity },
                        { text: response.deliveryAddress.postCode },
                        { text: response.deliveryAddress.county },
                        { text: response.deliveryAddress.country },
                    ];
                    a.push(b);
                }



                this.setState({ data: a })
            }
        }).catch(error => { console.log('Failed to get data ' + error) })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getOrder();
    }


    render() {

        const header = ["Order Number", "Product", "Terminal ID", "Status","Company", "Address Line 1", "Address Line 2", "Town/City", "Postcode", "County", "Country"];


        return (
            <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
                <MDBAnimation type="fadeInLeft">
                    <MDBRow style={{ marginBottom: "10px" }}>
                        <MDBCol>
                            <SCPTable header={header} table={this.state.data} />

                            <LinkContainer className="NavItem" to={routes.orders} >
                                <MDBBtn color="amber" size="sm" style={{}}>
                                    <MDBIcon icon="angle-left" /> Back
                                </MDBBtn>
                            </LinkContainer>

                        </MDBCol>
                    </MDBRow>
                </MDBAnimation>
            </MDBContainer >

        );
    }
}


export default withRouter(OrderDetail);
