import React from "react";
import { withRouter } from "react-router-dom";
import { MDBContainer, MDBRow } from "mdbreact";

import InfoHide from "../InfoHide";
// import ScrollButton from "./ScrollButton";
import FAQPEDloggedout from "./FAQPEDloggedout";
import FAQWebsiteloggedout from "./FAQWebsiteloggedout";

class UnauthSupport extends React.Component {
      render() {
            return (
                  <MDBContainer
                        style={{
                              minHeight: "73.65vh",
                              marginTop: "15px",
                              marginBottom: "15px"
                        }}
                  >
                        <MDBRow>
                              <InfoHide info1="FAQ - Please note, once logged in you will have access to all FAQs." />
                              {/* <ScrollButton to="PED" text="PED"></ScrollButton>
                              <ScrollButton to="Website" text="Website" /> */}
                        </MDBRow>

                        <MDBRow>
                              <FAQPEDloggedout id="PED" />
                        </MDBRow>

                        <br />

                        <MDBRow>
                              <FAQWebsiteloggedout id="Website" />
                        </MDBRow>
                  </MDBContainer>
            );
      }
}

export default withRouter(UnauthSupport);
