import React from "react";
import { withRouter } from "react-router-dom";
import {
      MDBBtn,
      MDBContainer,
      MDBRow,
      MDBCol,
      MDBIcon,
      MDBAnimation,
      MDBSelect,
      MDBAlert,
      MDBModal,
      MDBModalBody,
      MDBModalHeader,
      MDBModalFooter
} from "mdbreact";
import Amplify from "../api.js";
import InfoHide2 from "../InfoHide2";
import "./Transactions.css";
import SCPTableV2 from "../SCPTableV2";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import MoonLoader from "react-spinners/MoonLoader";
import Filtering from "./Filtering.js";
import ReactToPdf from "react-to-pdf";

const ref = React.createRef();

const exportOverride = css`
      margin-left: 5px;
`;

const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
`;


const notnul = (params, param) => {
      const p = params.get(param);
      return p == null ? "" : p;
};

const intNotnul = (params, param) => {
      const p = params.get(param);
      return p == null ? "" : p;
};

class Transactions extends React.Component {
      constructor(props) {
            super(props);
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            this.state = {
                  data: [],
                  query: {
                        settlement: notnul(params, "settlement"),
                        acquirer: notnul(params, "acquirer"),
                        mid: notnul(params, "mid"),
                        tid: notnul(params, "tid")
                  },
                  index: "",
                  pages: "",
                  mid: [],
                  cleanMid: [],
                  selectedMid: "",
                  tid: [],
                  cleanTid: [],
                  selectedTid: "",
                  merchant: [],
                  cleanMerchant: [],
                  selectedMerchant: "",
                  cleanData: [],
                  activeItem: "1",
                  pageIndex: 0,
                  pageCount: 1,
                  showPagination: true,
                  loading: true,
                  exportLoading: false,
                  options: [
                        {
                              checked: true,
                              text: "50",
                              value: "50"
                        },
                        {
                              text: "100",
                              value: "100"
                        }
                  ],
                  resultPages: "50",
                  filters: {
                        eposTransactionNumber: notnul(params, "exposTxn"),
                        authCode: notnul(params, "authCode"),
                        merchantName: notnul(params, "merchantName"),
                        tid: notnul(params, "tid"),
                        txnAmt: intNotnul(params, "txnAmt"),
                        transType: notnul(params, "transType"),
                        transResult: notnul(params, "transResult"),
                        maskedPAN: notnul(params, "maskedPAN"),
                        startTime: notnul(params, "startTime"),
                        endTime: notnul(params, "endTime"),
                        startDate: notnul(params, "startDate"),
                        endDate: notnul(params, "endDate"),
                  },
                  initialFilter: {
                        eposTransactionNumber: "",
                        authCode: "",
                        merchantName: "",
                        tid: "",
                        txnAmt: "",
                        transType: "",
                        transResult: "",
                        maskedPAN: "",
                        startTime: "",
                        endTime: "",
                        startDate: "",
                        endDate: "",
                  },
                  advFilters: {},
                  error: false,
                  errorMessage: "",
                  buttonState: true,
                  exportURL: "",
                  modal: false,
                  selectedRow: "",
                  expand: {
                        transType: "loading",
                        mid: "",
                        tid: "",
                        merchant: "",
                        serial: "",
                        batch: "",
                        number: "",
                        EPOSTxn: "",
                        amount: "",
                        cashback: "",
                        pennies :"",
                        dateTime: "",
                        mPAN: "",
                        rCode: "",
                        authCode: "",
                        transResult: "",
                        cardScheme: "",
                        currency: "",
                        expDate: "",
                        transSrc: "",
                        settleNo: "",
                        acquirer: ""
                  },
                  values: {},
                  clear: false,
            };
            this.exportCSV = this.exportCSV.bind(this);
            this.getTransactions = this.getTransactions.bind(this);
            this.handlePageIndex = this.handlePageIndex.bind(this);
            this.handleResults = this.handleResults.bind(this);
            this.clearPage = this.clearPage.bind(this);
            this.firstPage = this.firstPage.bind(this);
            this.lastPage = this.lastPage.bind(this);
            this.showError = this.showError.bind(this);
            this.hideError = this.hideError.bind(this);
            this.clearFilters = this.clearFilters.bind(this);
            this.onRowClick = this.onRowClick.bind(this);
      }

      // exportCSV sends an API request and updates state.data with the result
      exportCSV = () => {
            if (this.state.loading === false) {
                  this.hideError();
                  this.showExportLoader();
                  let currentPath = window.location.href;
                  let csvExportLink = window.open(currentPath, "_blank");
                  csvExportLink.document.write("Fetching CSV");
                  csvExportLink.location.href = this.state.exportURL;
                  this.hideExportLoader();
            }
      };

      generateCSV = () => {
            if (this.state.loading === false) {
                  this.hideError();
                  this.showExportLoader();
                  this.getAllTransactions(true)
                        .then(response => {
                              this.setState(
                                    { exportURL: response, buttonState: false },
                                    () => this.hideExportLoader()
                              );
                              //console.log("Response", response);
                        })
                        .catch(error => {
                              this.hideLoader();
                              if (this.state.data.length !== 0) {
                                    this.showError();
                                    this.setState({
                                          errorMessage: "Failed to generate CSV"
                                    });
                                    this.hideExportLoader();
                              }
                        });
            }
      };

      submitFilters = async (values, { setSubmitting }) => {
            //console.log("Filters", values);
            //console.log("StartDate + EndDate", values.startDate, values.endDate)

            //Page & filter data set
            this.saveData(values).then(this.cleanData(values))
            this.setState({ pageIndex: 0 });
            //console.log("ADV-FILTERS", this.state.advFilters)
            this.getTransactions();
      };

      saveData = async (values) => {
            const ogValues = values
            //console.log("VALUE CONST", ogValues)
            this.setState({ filters: ogValues }, console.log("CLEAN Filters", this.state.filters))
      }

      cleanData = async (values) => {

            if (values.startTime !== "") {
                  //Format yyyy-MM-ddTHH:mm:ss
                  let value = values;

                  //Add seconds to startTime
                  let startTime = "T" + value.startTime + ":" + "00"

                  //Add seconds to endTime
                  let endTime = "T" + value.endTime + ":" + "00"

                  //startDate and startTime
                  let startDate = value.startDate
                  let startTimeDate = startDate + startTime

                  //endDate and endTime
                  let endDate = value.endDate
                  let endTimeDate = endDate + endTime

                  this.setState({ values: value }, () => { })

                  let valueObject = this.state.values;
                  //console.log("valueObject", valueObject)

                  // delete valueObject.endTime
                  // delete valueObject.startTime

                  //Change Start Time and Date to new values
                  let startTimeDateForm = startTimeDate
                  let endTimeDateForm = endTimeDate
                  let combinedObj = { startTimeDateForm, endTimeDateForm }

                  //console.log("VALUE OBJECT", combinedObj)
                  this.setState({ advFilters: combinedObj });
            }
      }

      clearFilters = async () => {
            this.setState({ pageIndex: 0 });
            let triggerValue = true
            let clearObj = this.state.initialFilter
            let clearAdv = {}
            //Clear Advanced filters as well
            //console.log("advFilters", this.state.advFilters)
            this.setState({ clear: triggerValue, filters: clearObj, advFilters: clearAdv }, () => { this.getTransactions() })
            //.log("advFilters", this.state.advFilters)
      };

      componentDidMount() {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            this.setState({
                  query: {
                        settlement: notnul(params, "settlement"),
                        acquirer: notnul(params, "acquirer"),
                        mid: notnul(params, "mid"),
                        tid: notnul(params, "tid")
                  }
            });
            this.getTransactions();
      }

      componentDidUpdate = prevProps => {
            if (this.props.location.search !== prevProps.location.search) {
                  const search = this.props.location.search;
                  const params = new URLSearchParams(search);
                  this.setState({
                        query: {
                              settlement: notnul(params, "settlement"),
                              acquirer: notnul(params, "acquirer"),
                              mid: notnul(params, "mid"),
                              tid: notnul(params, "tid")
                        }
                  });
                  this.getTransactions();
            }
      };

      async getAllTransactions(csv) {
            let apiName = "TennantAPI";
            let path = "manageterminals/transreporting";
            let pageIndexState = this.state.pageIndex;

            //Filter Row 1
            let eposTxn = this.state.filters.eposTransactionNumber;
            let authCodeState = this.state.filters.authCode;
            let merchantName = this.state.filters.merchantName;
            let tid = this.state.filters.tid;

            //parse string into int
            let beforeTxnAmt = this.state.filters.txnAmt.replace("£", "");
            //console.log("beforeTxnAmt", beforeTxnAmt);
            let removeDPTxnAmt = beforeTxnAmt.replace(".", "");
            //console.log("removeDPTxnAmt", removeDPTxnAmt);
            let txnAmtFloat = parseFloat(removeDPTxnAmt);
            //console.log("txnAmtFloat", txnAmtFloat);
            var txnAmt = Math.trunc(txnAmtFloat);
            //console.log("txnAMT", txnAmt);

            //Forces transType to lowercase for DynamoDB case Sensitivity
            let transTypeState = this.state.filters.transType;
            let transType = transTypeState.toLowerCase();

            //Make First Letter Capital Regardless of user input to bypass DynamoDB Case Sensitivity
            let transResultState = this.state.filters.transResult;
            let transResultBefore = transResultState.toLowerCase()
            let transResult

            if (transResultBefore.includes("notauthorised")) {
                  transResult = "NotAuthorised"
                  //console.log("Not Authorised Value", transResult)
            } else {
                  transResult = transResultBefore.charAt(0).toUpperCase() + transResultBefore.slice(1);
            }

            let maskedPAN = this.state.filters.maskedPAN;

            //Advanced Filters
            let startDate = this.state.advFilters.startTimeDateForm
            //console.log("startDate", startDate)
            let endDate = this.state.advFilters.endTimeDateForm
            //console.log("endDate", endDate)


            let results = this.state.resultPages;
            //console.log("SELECTED RESULTS IN API CALL", this.state.resultPages);

            //console.log("Start-PageINdex", pageIndexState);
            if (
                  this.state.query.settlement !== null &&
                  this.state.query.settlement !== ""
            ) {
                  path += `/${this.state.query.settlement}`;
                  if (
                        this.state.query.acquirer !== null &&
                        this.state.query.acquirer !== ""
                  ) {
                        path += `/${this.state.query.acquirer}`;
                        if (
                              this.state.query.mid !== null &&
                              this.state.query.mid !== ""
                        ) {
                              path += `/${this.state.query.mid}`;
                              if (
                                    this.state.query.tid !== null &&
                                    this.state.query.tid !== ""
                              ) {
                                    path += `/${this.state.query.tid}`;
                              }
                        }
                  }
            }
            let clear = this.state.clear
            var myInit;
            if (csv === true) {
                  myInit = {
                        headers: {
                              Authorization: `Bearer ${(
                                    await Amplify.Auth.currentSession()
                              )
                                    .getIdToken()
                                    .getJwtToken()}`
                        },
                        queryStringParameters: {
                              // OPTIONAL
                              csv: "true"
                        },
                        responseType: "text"
                  };
            } else if (clear === true) {
                  myInit = {
                        headers: {
                              Authorization: `Bearer ${(
                                    await Amplify.Auth.currentSession()
                              )
                                    .getIdToken()
                                    .getJwtToken()}`
                        },
                        queryStringParameters: {
                              pageSize: results,
                              pageIndex: pageIndexState,
                              eposTransactionNumber: "",
                              authCode: "",
                              merchantName: "",
                              tid: "",
                              txnAmt: "",
                              transType: "",
                              transResult: "",
                              maskedPAN: "",
                              startDate: "",
                              endDate: ""
                        }
                  };
                  //console.log("CLEARED")
                  this.setState({ clear: false })

            }
            else {
                  myInit = {
                        headers: {
                              Authorization: `Bearer ${(
                                    await Amplify.Auth.currentSession()
                              )
                                    .getIdToken()
                                    .getJwtToken()}`
                        },
                        queryStringParameters: {
                              pageSize: results,
                              pageIndex: pageIndexState,
                              eposTransactionNumber: eposTxn,
                              authCode: authCodeState,
                              merchantName: merchantName,
                              tid: tid,
                              txnAmt: txnAmt,
                              transType: transType,
                              transResult: transResult,
                              maskedPAN: maskedPAN,
                              startDate: startDate,
                              endDate: endDate
                        }
                  };
            }

            return await Amplify.API.get(apiName, path, myInit);
      }

      getTransactions() {
            this.showLoader();

            this.getAllTransactions(false)
                  .then(response => {
                        this.setState({ index: response.PageIndex });
                        this.setState({ pages: response.PageCount });
                        this.hideError();

                        if (this.state.pages < 1) {
                              this.setState({ showPagination: false });
                        }

                        var array = [];
                        var midsArray = [];
                        var tidsArray = [];
                        var merchantsArray = [];

                        if (response.Data !== null) {
                            var i;

                            for (i = 0; i < response.Data.length; i++) {
                                var item = [
                                    { transType: response.Data[i].TransType },
                                    { mid: response.Data[i].Mid },
                                    { terminalID: response.Data[i].Tid },
                                    { merchantName: response.Data[i].MerchantName },
                                    { serialNumber: response.Data[i].SerialNum },
                                    { batchNumber: response.Data[i].BatchNum },
                                    { transNumber: response.Data[i].TransNum },
                                    { eposTXN: response.Data[i].EPOSTxnNum },
                                    { currency: response.Data[i].TxnAmt },
                                    { cashBack: response.Data[i].CashbackAmt },
                                    { pennies: response.Data[i].DonationAmt },
                                    { dateTime: response.Data[i].TransTime },
                                    { maskedPAN: response.Data[i].MaskedPAN },
                                    { rCode: response.Data[i].ResponseCode },
                                    { authCode: response.Data[i].AuthCode },
                                    { transResult: response.Data[i].TransResult },
                                    { cardSch: response.Data[i].CardScheme },
                                    { text: "Expand", action: this.onRowClick, param: i },
                                    { currencyType: response.Data[i].Currency },
                                    { cardExpiryDate: response.Data[i].ExpiryDate },
                                    { transSrc: response.Data[i].TransSource },
                                    { settleNo: response.Data[i].SettlementNum },
                                    { acquirer: response.Data[i].Acquirer }
                                ];

                                var mid = { text: response.Data[i].Mid };
                                midsArray.push(mid);

                                var tid = { text: response.Data[i].Tid };
                                tidsArray.push(tid);

                                var merchant = { text: response.Data[i].MerchantName };
                                merchantsArray.push(merchant);

                                if (item[0].transType === "reversal" && item[10].transResult === "Void") {
                                    // Don't include voided reversals? Why item[0] and item[10]?
                                } else {
                                    array.push(item);
                                }
                            }
                        }

                        this.setState({ mid: midsArray });
                        this.setState({ tid: tidsArray });
                        this.setState({ merchant: merchantsArray });
                        this.setState({ data: array });
                        this.setState({ cleanData: array });

                        this.hideLoader();
                  })
                  .catch(error => {
                        this.hideLoader();
                        if (this.state.data.length !== 0) {
                              this.showError();
                              this.setState({
                                    errorMessage: "Failed to retrieve data"
                              });
                        }
                  });
      }

      onRowClick(index) {
            () => {
                  this.setState({ rowIndex: index });
            };
            let rowIndex = index;
            let data = this.state.data;
            let selectRow = data[rowIndex];

            if (selectRow !== undefined) {
                  //Gets Trans Type
                  let filteredTransType = selectRow.reduce(
                        (a, o) => (o.transType && a.push(o.transType), a),
                        []
                  );

                  //Gets MID
                  let filteredMID = selectRow.reduce(
                        (a, o) => (o.mid && a.push(o.mid), a),
                        []
                  );

                  //Gets TID
                  let filteredTID = selectRow.reduce(
                        (a, o) => (o.terminalID && a.push(o.terminalID), a),
                        []
                  );

                  //Gets Merchant Name
                  let filteredMerchantName = selectRow.reduce(
                        (a, o) => (o.merchantName && a.push(o.merchantName), a),
                        []
                  );

                  //Gets Serial No.
                  let filteredSerialNumber = selectRow.reduce(
                        (a, o) => (o.serialNumber && a.push(o.serialNumber), a),
                        []
                  );

                  //Gets Masked PAN
                  let filteredMaskedPAN = selectRow.reduce(
                        (a, o) => (o.maskedPAN && a.push(o.maskedPAN), a),
                        []
                  );

                  //Gets Batch
                  let filteredBatch = selectRow.reduce(
                        (a, o) => (o.batchNumber && a.push(o.batchNumber), a),
                        []
                  );

                  //Gets TransNumber
                  let filteredTransNumber = selectRow.reduce(
                        (a, o) => (o.transNumber && a.push(o.transNumber), a),
                        []
                  );

                  //Gets EPOS TXN
                  let filteredEposTXN = selectRow.reduce(
                        (a, o) => (o.eposTXN && a.push(o.eposTXN), a),
                        []
                  );


                  //Gets RCode
                  let filteredRCode = selectRow.reduce(
                        (a, o) => (o.rCode && a.push(o.rCode), a),
                        []
                  );

                  //Gets AuthCode
                  let filteredAuthCode = selectRow.reduce(
                        (a, o) => (o.authCode && a.push(o.authCode), a),
                        []
                  );

                  //Gets transResult
                  let filteredTransResult = selectRow.reduce(
                        (a, o) => (o.transResult && a.push(o.transResult), a),
                        []
                  );

                  //Gets cardScheme
                  let filteredCardSch = selectRow.reduce(
                        (a, o) => (o.cardSch && a.push(o.cardSch), a),
                        []
                  );

                  //Gets Currency
                  let filteredCurrency = selectRow.reduce(
                        (a, o) => (o.currencyType && a.push(o.currencyType), a),
                        []
                  );


                  if (filteredCurrency[0] !== undefined && filteredCurrency[0].includes("826")) {
                        filteredCurrency = "Pound Sterling (£)"
                  }


                  //Gets expDate
                  let filteredExpDate = selectRow.reduce(
                        (a, o) => (o.cardExpiryDate && a.push(o.cardExpiryDate), a),
                        []
                  );

                  //Gets transSrc
                  let filteredTransSrc = selectRow.reduce(
                        (a, o) => (o.transSrc && a.push(o.transSrc), a),
                        []
                  );

                  let formatter = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "GBP"
                  });

                  //Gets cashback
                  let filteredCashback = selectRow.reduce(
                        (a, o) => (o.cashBack && a.push(o.cashBack), a),
                        []
                  );
                  let cashbackFormatted = formatter.format(filteredCashback / 100)
                  let filteredPennies=selectRow.reduce(
                      (a, o)=> (o.pennies && a.push(o.pennies),a),[]
                  );
                  let penniesFormatted=formatter.format(filteredPennies/100)

                  //Gets Amount
                  let filteredAmount = selectRow.reduce(
                        (a, o) => (o.currency && a.push(o.currency), a),
                        []
                  );

                  let amountFormatted = formatter.format(filteredAmount / 100)


                  //Gets Date/Time
                  let filteredDateTime = selectRow.reduce(
                        (a, o) => (o.dateTime && a.push(o.dateTime), a),
                        []
                  );

                  //Format - 2004141312

                  let stringTimeDate = JSON.stringify(filteredDateTime)

                  let timeDateFormatted = stringTimeDate.slice(6, 8)
                        +
                        "/" +
                        stringTimeDate.slice(4, 6) +
                        "/" +
                        stringTimeDate.slice(2, 4) +
                        " " +
                        stringTimeDate.slice(8, 10) +
                        ":" +
                        stringTimeDate.slice(10, 12);

                  //Gets SettleNo
                  let filteredSettleNo = selectRow.reduce(
                        (a, o) => (o.settleNo && a.push(o.settleNo), a),
                        []
                  );

                  //Gets Acquirer
                  let filteredAcquirer = selectRow.reduce(
                        (a, o) => (o.acquirer && a.push(o.acquirer), a),
                        []
                  );

                  //Set states last and all at the same time.
                  this.setState({
                        expand: {
                              transType: filteredTransType[0],
                              mid: filteredMID[0],
                              tid: filteredTID[0],
                              merchant: filteredMerchantName[0],
                              serial: filteredSerialNumber[0],
                              batch: filteredBatch[0],
                              number: filteredTransNumber[0],
                              EPOSTxn: filteredEposTXN[0],
                              mPAN: filteredMaskedPAN[0],
                              rCode: filteredRCode[0],
                              authCode: filteredAuthCode[0],
                              transResult: filteredTransResult[0],
                              cardScheme: filteredCardSch[0],
                              currency: filteredCurrency,
                              expDate: filteredExpDate[0],
                              transSrc: filteredTransSrc[0],
                              amount: amountFormatted,
                              cashback: cashbackFormatted,
                              pennies:penniesFormatted,
                              dateTime: timeDateFormatted,
                              settleNo: filteredSettleNo,
                              acquirer: filteredAcquirer,
                        }
                  }
                        , () => {
                              //console.log("expand", this.state.expand);
                        });


            }

            //toggle modal to load
            this.setState({
                  modal: !this.state.modal
            });

      }

      exportPDF() {

      }

      showError = () => {
            this.setState({ error: true });
      };

      hideError = () => {
            this.setState({ error: false });
      };

      hideLoader = () => {
            this.setState({ loading: false });
      };

      showLoader = () => {
            this.setState({ loading: true });
      };

      hideExportLoader = () => {
            this.setState({ exportLoading: false });
      };

      showExportLoader = () => {
            this.setState({ exportLoading: true });
      };

      decreasePageIndex = () => {
            let pageIndex = this.state.pageIndex;
            let loading = this.state.loading;

            if (pageIndex > 0 && loading === false) {
                  this.setState(
                        { pageIndex: this.state.pageIndex - 1 },
                        function () {
                              //console.log(this.state.pageIndex);
                              this.getTransactions(false);
                        }
                  );
            }
      };

      increasePageIndex = () => {
            let pages = this.state.pages - 1;
            let loading = this.state.loading;

            if (this.state.pageIndex < pages && loading === false) {
                  this.setState(
                        { pageIndex: this.state.pageIndex + 1 },
                        function () {
                              //console.log(this.state.pageIndex);
                              this.getTransactions(false);
                        }
                  );
            }
      };

      handlePageIndex(event) {
            let pIndex = event.target.value;
            //console.log("Pindex", pIndex);
            let pages = this.state.pages - 1;
            let loading = this.state.loading;

            if (loading === false && pIndex > 0 && pIndex <= pages) {
                  this.setState({ pageIndex: event.target.value }, function () {
                        //console.log(this.state.pageIndex);
                        this.getTransactions(false);
                  });
            }
      }

      handleResults(value) {
            const selected = value[0];
            this.setState({ resultPages: selected }, () =>
                  this.getTransactions(false)
            );
      }

      clearPage() {
            this.setState({ pageIndex: "" });
      }

      lastPage() {
            let pages = this.state.pages - 1;
            this.setState({ pageIndex: pages }, function () {
                  //console.log(this.state.pageIndex);
                  this.getTransactions(false);
            });
      }

      firstPage() {
            this.setState({ pageIndex: 0 }, function () {
                  ////console.log(this.state.pageIndex);
                  this.getTransactions(false);
            });
      }

      toggle = () => {
            this.setState({
                  modal: !this.state.modal
            });
      };

      render() {
            const header = [
                  "TransType",
                  "MID",
                  "TID",
                  "Merchant",
                  "Serial",
                  "Batch",
                  "Number",
                  "EPOSTxn",
                  "Amount",
                  "Cashback",
                  "Pennies",
                  "Date/Time",
                  "Masked PAN",
                  "Response Code",
                  "Auth Code",
                  "Trans Result",
                  "Card Scheme",
                  ""
            ];

            return (
                  <MDBContainer
                        style={{ paddingTop: "50px", minHeight: "73.65vh" }}
                        className="TransContainer"
                        fluid
                  >
                        {this.state.error === true ? (
                              <MDBAlert color="warning" dismiss>
                                    <strong>Error:</strong>{" "}
                                    {this.state.errorMessage}
                              </MDBAlert>
                        ) : (
                              <></>
                        )}

                        <MDBRow style={{ margin: "0px" }}>
                              {this.state.query.settlement !== "" ? (
                                    <InfoHide2
                                          info1="Transactions"
                                          info2={
                                                "Settle No.:" +
                                                this.state.query.settlement
                                          }
                                          info3={
                                                "Acquirer:" +
                                                this.state.query.acquirer
                                          }
                                          info4={"MID:" + this.state.query.mid}
                                          info5={"TID:" + this.state.query.tid}
                                    />
                              ) : (
                                    <InfoHide2 info1="Transactions" />
                              )}
                              {this.state.exportLoading ? (
                                    <>
                                          <MDBBtn
                                                id="export-csv-btn"
                                                className="ExportButton"
                                                size="sm"
                                                color="warning"
                                                onClick={this.generateCSV}
                                          >
                                                <MDBIcon
                                                      icon="tools"
                                                      style={{
                                                            paddingRight: "3px"
                                                      }}
                                                />
                                                Generate CSV
                                          </MDBBtn>
                                          <MDBBtn
                                                id="export-csv-btn"
                                                className="ExportButton"
                                                size="sm"
                                                color="warning"
                                                onClick={this.exportCSV}
                                                disabled={
                                                      this.state.buttonState
                                                }
                                          >
                                                <MDBIcon
                                                      icon="file-export"
                                                      style={{
                                                            paddingRight: "3px"
                                                      }}
                                                />
                                                Download CSV
                                          </MDBBtn>
                                          <MoonLoader
                                                css={exportOverride}
                                                size="30px"
                                                color={"#123abc"}
                                                loading={
                                                      this.state.exportLoading
                                                }
                                          />
                                          <p className="exportLoadingText">
                                                <MDBIcon icon="tools" />
                                                Generating CSV
                                          </p>{" "}
                                    </>
                              ) : (
                                    <>
                                          <MDBBtn
                                                id="export-csv-btn"
                                                className="GenerateButton"
                                                size="sm"
                                                color="warning"
                                                onClick={this.generateCSV}
                                          >
                                                <MDBIcon
                                                      icon="tools"
                                                      style={{
                                                            paddingRight: "3px"
                                                      }}
                                                />
                                                Generate CSV
                                          </MDBBtn>
                                          <MDBBtn
                                                id="export-csv-btn"
                                                className="ExportButton"
                                                size="sm"
                                                color="warning"
                                                onClick={this.exportCSV}
                                                disabled={
                                                      this.state.buttonState
                                                }
                                          >
                                                <MDBIcon
                                                      icon="file-export"
                                                      style={{
                                                            paddingRight: "3px"
                                                      }}
                                                />
                                                Download CSV
                                          </MDBBtn>
                                    </>
                              )}
                        </MDBRow>
                        {/* <MDBRow>
                              <p>
                                    <strong>Transaction Filters:</strong>
                              </p>
                        </MDBRow> */}
                        <MDBRow>
                              <MDBCol>
                                    <Filtering
                                          params={this.state.filters}
                                          action={this.submitFilters}
                                          clear={this.clearFilters}
                                    />
                              </MDBCol>
                        </MDBRow>
                        {this.state.loading ? (
                              <>
                                    <div className="sweet-loading">
                                          <p className="LoadingText">
                                                <strong>Fetching Data</strong>
                                                <MDBIcon
                                                      style={{
                                                            marginLeft: "5px"
                                                      }}
                                                      icon="database"
                                                />
                                          </p>
                                          <BarLoader
                                                css={override}
                                                height={8}
                                                width={800}
                                                color={"#123abc"}
                                                loading={this.state.loading}
                                          />
                                    </div>
                              </>
                        ) : (
                              <>
                                    <MDBModal
                                          isOpen={this.state.modal}
                                          toggle={this.onRowClick}
                                    >
                                          <div ref={ref}>
                                                <MDBModalHeader
                                                      toggle={this.toggle}
                                                >
                                                      Expanded Transaction
                                                      Detail
                                                </MDBModalHeader>
                                                <MDBModalBody>
                                                      <div className="expand-text">
                                                            <p>TransType: <span>{this.state.expand.transType}</span></p>
                                                            <p>MID: <span>{this.state.expand.mid}</span> </p>
                                                            <p>TID: <span>{this.state.expand.tid}</span> </p>
                                                            <p>Merchant: <span>{this.state.expand.merchant}</span> </p>
                                                            <p>Serial: <span>{this.state.expand.serial}</span> </p>
                                                            <p>Batch:  <span>{this.state.expand.batch}</span> </p>
                                                            <p>Number: <span>{this.state.expand.number}</span> </p>
                                                            <p>EPOSTxn: <span>{this.state.expand.EPOSTxn}</span> </p>
                                                            <p>Amount: <span>{this.state.expand.amount}</span> </p>
                                                            <p>Cashback: <span>{this.state.expand.cashback}</span> </p>
                                                            <p>Pennies: <span>{this.state.expand.pennies}</span> </p>
                                                            <p>Date/Time: <span>{this.state.expand.dateTime}</span> </p>
                                                            <p>Masked PAN: <span>{this.state.expand.mPAN}</span> </p>
                                                            <p>Response Code: <span>{this.state.expand.rCode}</span> </p>
                                                            <p>Auth Code: <span>{this.state.expand.authCode}</span> </p>
                                                            <p>Trans Result: <span>{this.state.expand.transResult}</span> </p>
                                                            <p>Card Scheme: <span>{this.state.expand.cardScheme}</span> </p>
                                                            <p>Acquirer: <span>{this.state.expand.acquirer}</span></p>
                                                            <p>Expiry Date: <span>{this.state.expand.expDate}</span> </p>
                                                            <p>Currency: <span>{this.state.expand.currency}</span> </p>
                                                            <p>Trans Source: <span>{this.state.expand.transSrc}</span> </p>
                                                            <p>Settlement Number: <span>{this.state.expand.settleNo}</span> </p>
                                                      </div>
                                                </MDBModalBody>
                                          </div>
                                          <MDBModalFooter>
                                                <MDBBtn
                                                      color="danger"
                                                      onClick={this.toggle}
                                                >
                                                      Close
                                                </MDBBtn>
                                                <ReactToPdf
                                                      targetRef={ref}
                                                      filename="TransactionDetails.pdf"
                                                >
                                                      {({ toPdf }) => (
                                                            <MDBBtn
                                                                  color="warning"
                                                                  onClick={
                                                                        toPdf
                                                                  }
                                                            >
                                                                  Export
                                                            </MDBBtn>
                                                      )}
                                                </ReactToPdf>
                                          </MDBModalFooter>
                                    </MDBModal>
                                    <MDBAnimation type="fadeIn">
                                          <MDBRow>
                                                <MDBCol>
                                                      <SCPTableV2
                                                            header={header}
                                                            table={
                                                                  this.state
                                                                        .data
                                                            }
                                                            getRow={
                                                                  this
                                                                        .onRowClick
                                                            }
                                                            id="Transaction-Table"
                                                            className="Transaction-Table"
                                                      />
                                                </MDBCol>
                                          </MDBRow>
                                    </MDBAnimation>
                              </>
                        )}
                        {this.state.pages > 1 && this.state.loading === false ? (
                              <>
                                    <MDBRow className="row-float-right">
                                          <MDBCol >
                                                <MDBSelect
                                                      options={this.state.options}
                                                      selected="Choose your option"
                                                      label="Results per page"
                                                      getValue={this.handleResults}
                                                />
                                          </MDBCol>
                                    </MDBRow>
                                
                                    <MDBRow>
                                          <div className="def-number-input number-input">
                                                {this.state.pageIndex === 0 ? (
                                                      <>
                                                            <p className="pageNumberText">
                                                                  Showing{" "}
                                                                  {this.state.index + 1} of{" "}
                                                                  {this.state.pages} pages
                                                            </p>
                                                      </>
                                                ) : (
                                                      <p className="pageNumberText-after">
                                                            Showing{" "}
                                                            {this.state.index + 1} of{" "}
                                                            {this.state.pages} pages
                                                      </p>
                                                )}
                                                {this.state.pageIndex !== 0 ? (
                                                      <>
                                                            <MDBBtn
                                                                  onClick={this.firstPage}
                                                                  floating
                                                                  size="sm"
                                                                  gradient="blue"
                                                            >
                                                                  <MDBIcon icon="step-backward" />
                                                            </MDBBtn>
                                                            <MDBBtn
                                                                  onClick={
                                                                        this
                                                                              .decreasePageIndex
                                                                  }
                                                                  floating
                                                                  size="sm"
                                                                  gradient="blue"
                                                            >
                                                                  <MDBIcon icon="chevron-left" />
                                                            </MDBBtn>
                                                      </>
                                                ) : (
                                                      <span></span>
                                                )}
                                                <input
                                                      className="PageIndex"
                                                      name="PageIndex"
                                                      // onClick={this.clearPage}
                                                      value={
                                                            this.state
                                                                  .pageIndex + 1
                                                      }
                                                      onChange={
                                                            this.handlePageIndex
                                                      }
                                                      type="number"
                                                      disabled
                                                />


                                                <MDBBtn
                                                      onClick={
                                                            this
                                                                  .increasePageIndex
                                                      }
                                                      floating
                                                      size="sm"
                                                      gradient="blue"
                                                >
                                                      <MDBIcon icon="chevron-right" />
                                                </MDBBtn>
                                                <MDBBtn
                                                      onClick={this.lastPage}
                                                      floating
                                                      size="sm"
                                                      gradient="blue"
                                                >
                                                      <MDBIcon icon="step-forward" />
                                                </MDBBtn>
                                          </div>
                                    </MDBRow>
                              </>
                        ) : (
                              <>
                                    <MDBRow className="row-float-right">
                                          <MDBCol >
                                                <MDBSelect
                                                    options={this.state.options}
                                                    selected="Choose your option"
                                                    label="Results per page"
                                                    getValue={this.handleResults}
                                                />
                                          </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                          <div className="def-number-input number-input">
                                                {this.state.pageIndex === 0  ? (
                                                    <>
                                                          <p className="pageNumberText">
                                                                Showing{" "}
                                                                {this.state.index + 1} of{" "}
                                                                {this.state.pages} pages
                                                          </p>
                                                    </>
                                                ) : (
                                                    <p className="pageNumberText-after">
                                                          Showing{" "}
                                                          {this.state.index + 1} of{" "}
                                                          {this.state.pages} pages
                                                    </p>
                                                )}
                                                {this.state.pageIndex !== 0 ? (
                                                    <>
                                                          <MDBBtn
                                                              onClick={this.firstPage}
                                                              floating
                                                              size="sm"
                                                              gradient="blue"
                                                          >
                                                                <MDBIcon icon="step-backward" />
                                                          </MDBBtn>
                                                          <MDBBtn
                                                              onClick={
                                                                    this
                                                                        .decreasePageIndex
                                                              }
                                                              floating
                                                              size="sm"
                                                              gradient="blue"
                                                          >
                                                                <MDBIcon icon="chevron-left" />
                                                          </MDBBtn>
                                                    </>
                                                ) : (
                                                    <span></span>
                                                )}
                                                <input
                                                    className="PageIndex"
                                                    name="PageIndex"
                                                    // onClick={this.clearPage}
                                                    value={this.state.pageIndex + 1}
                                                    onChange={
                                                          this.handlePageIndex
                                                    }
                                                    type="number"
                                                    disabled
                                                />
                                                <MDBBtn
                                                    onClick={
                                                          this.increasePageIndex
                                                    }
                                                    floating
                                                    size="sm"
                                                    gradient="blue"
                                                >
                                                      <MDBIcon icon="chevron-right" />
                                                </MDBBtn>
                                                <MDBBtn
                                                    onClick={this.lastPage}
                                                    floating
                                                    size="sm"
                                                    gradient="blue"
                                                >
                                                      <MDBIcon icon="step-forward" />
                                                </MDBBtn>
                                          </div>
                                    </MDBRow>

                              </>
                        )}
                  </MDBContainer>
            );
      }
}

export default withRouter(Transactions);
