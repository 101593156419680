import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput } from "mdbreact";
import './NewOrder.css';
import Terms from './Components/SelectBox';
import Amplify from "../api.js";
import PostcodeLookup from "./PostcodeLookup";

//Need to re-do this whole page with Formik , Yup and Redux


class NewOrders extends Component {
  constructor(props) {
    super(props);
    console.log('Constructor start up')
    this.state = {
      device: "",
      quantity: "",
      payment: "",
      po: "",
      terms: false,
      formActivePanel: 1,
      terminalTypes: [],
      companyName:"",
      AD1: "",
      AD2: "",
      TC: "",
      PC: "",
      CS: "",
      Country: "",
      results: [],
      errorAD1:"",
      errorAD2:"",
      errorTC:"",
      errorPC:"",
      errorCS:"",
      errorCountry:"",
      errorCompany:""
    };

    this.handleDevice = this.handleDevice.bind(this);
    this.handleQuantity = this.handleQuantity.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handlePO = this.handlePO.bind(this);
    this.handleTerms = this.handleTerms.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirect = this.redirect.bind(this);

    this.handleCompanyName = this.handleCompanyName.bind(this);
    this.handleAD1 = this.handleAD1.bind(this);
    this.handleAD2 = this.handleAD2.bind(this);
    this.handleTC = this.handleTC.bind(this);
    this.handlePC = this.handlePC.bind(this);
    this.handleCS = this.handleCS.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
  }

  updateAddress(address) {
    console.log(address)
    
      this.setState({
        AD1: address.Line1,
        AD2: address.Line2,
        TC: address.townCity,
        CS: address.County,
        Country: address.Country
      });
    
  }

  ////Orders////
  handleCompanyName(event){
    if(event.target.value.length < 30){
    this.setState({companyName: event.target.value})
    this.setState({errorCompany: ""})
    } else {this.setState({errorCompany: "Company length must be less than 30 characters"})}
  }

  handleAD1(event) {
    if(event.target.value.length < 50){
    this.setState({ AD1: event.target.value })
    this.setState({errorAD1: ""})
    } else {this.setState({errorAD1: "Address Line 1 length must be less than 50 characters"})}
  }

  handleAD2(event) {
    if(event.target.value.length < 50){
    this.setState({ AD2: event.target.value })
    this.setState({errorAD2: ""})
    } else {this.setState({errorAD2: "Address Line 2 length must be less than 50 characters"})}
  }

  handleTC(event) {
    if(event.target.value.length < 20){
    this.setState({ TC: event.target.value })
    this.setState({errorTC: ""})
    } else {this.setState({errorTC: "Town/City length must be less than 20 characters"})}
  }

  handlePC(event) {
    if(event.target.value.length < 8){
    this.setState({ PC: event.target.value.replace(/\s/g, '') })
    this.setState({errorPC: ""})
    } else {this.setState({errorPC: "Postcode length must be less than 8 characters"})}
  }

  handleCS(event) {
    if(event.target.value.length < 20){
    this.setState({ CS: event.target.value })
    this.setState({errorCS: ""})
    } else {this.setState({errorCS: "County/State length must be less than 20 characters"})}
  }

  handleCountry(event) {
    if(event.target.value.length < 20){
    this.setState({ Country: event.target.value })
    this.setState({errorCountry: ""})
    } else {this.setState({errorCountry: "Country length must be less than 20 characters"})}
  }
  /////////////


  handleDevice(event) {
    this.setState({ device: event.target.value });
  }

  handleQuantity(event) {
    this.setState({ quantity: event.target.value });
  }

  handlePayment(event) {
    this.setState({ payment: event.target.value });
  }

  handlePO(event) {
    this.setState({ po: event.target.value.trim() });
  }

  handleTerms() {
    this.setState({ terms: true });
  }



  handleSubmit(event) {
    event.preventDefault();
    this.postData(this.state.device, this.state.quantity, this.state.payment, this.state.po, this.state.companyName, this.state.AD1, this.state.AD2, this.state.TC, this.state.PC, this.state.CS, this.state.Country)
    console.log('Handle Submit!')
  }

  redirect() {
    this.props.history.push("/auth/orders");
  }

  /////Delivery Address/////
  async getDeliveryAddress() {
    let apiName = 'TennantAPI';
    let path = 'defaultDeliveryAddress';
    let myInit = {
      headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
    }
    console.log('Calling ', apiName)
    return await Amplify.API.get(apiName, path, myInit);
  }

  populateDeliveryAddress() {
    this.getDeliveryAddress().then(response => {
      console.log('response', response)
      if (response === null) {
        this.setState({
          companyName: null,
          AD1: null,
          AD2: null,
          TC: null,
          PC: null,
          CS: null,
          Country: null,
        })
      } else {
        this.setState({
          companyName: response.companyName,
          AD1: response.addressLine1,
          AD2: response.addressLine2,
          TC: response.townCity,
          PC: response.postCode,
          CS: response.county,
          Country: response.country,
        })
      }
    }).catch(error => { console.log('Failed to get default delivery address' + error) })
  }




  async postData(device, quantity, payment, po, companyName ,AD1, AD2, TC, PC, CS, Country) {
    let apiName = 'TennantAPI';
    let path = 'orders';
    let deliveryAddress = { 'companyName': companyName , 'addressLine1': AD1, 'addressLine2': AD2, 'townCity': TC, 'postCode': PC, 'county': CS, 'country': Country }
    let line1 = { 'terminalType': device, 'qty': quantity };
    let orders = [line1];
    let Detail = { 'PONum': po };
    console.log("COMPANY NAME", companyName)
    console.log("", companyName)
    let myInit = {
      headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
      body: { order: orders, 'paymentType': payment, paymentDetail: Detail, 'deliveryAddress': deliveryAddress, 'companyName': companyName }
    }
    console.log('Calling ', apiName)
    return await Amplify.API.post(apiName, path, myInit).then(() => { console.log('refresh'); this.redirect() }).catch(function (error) {
      console.log(JSON.stringify(error))
    }
    )
  }

  swapFormActive = a => () => {
    switch (this.state.formActivePanel) {
      case 1:
        if (this.state.quantity <= 0 || this.state.quantity > 1000 || this.state.device === "" || this.state.device === "Choose your device" ) {
          return;
        }
        break;
         case 2:
          if (this.state.companyName=== "" ||this.state.PC === "" || this.state.AD1 === "" || this.state.TC === "" || this.state.CS === "" || this.state.Country === "") {
            return;
          }
          break;
      case 3:
        if (this.state.payment === "" || this.state.payment === "Choose your payment method") {
          return;
        }
        break;
      default:
    }

    this.setState({
      formActivePanel: a
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.getTerminalTypes();
    this.populateDeliveryAddress();
  }

  async getTerminalTypesAPI() {

    let apiName = 'TennantAPI';
    let path = '/terminaltypes';
    return await Amplify.API.get(apiName, path);
  }


  getTerminalTypes() {

    this.getTerminalTypesAPI().then(response => {
      this.setState({
        terminalTypes: response
      });
      console.log(this.state.terminalTypes)
    }
    ).catch(error => { console.log('Failed to get Terminal Types ' + error) });
  }

  render() {
    return (
      <MDBContainer style={{ backgroundColor: "white", paddingTop: "50px", minHeight: "73.65vh", marginBottom: "3rem", padding: "1rem" }}>
        <h2 className="text-center font-weight-bold pt-4 pb-5 mb-2" style={{ marginTop: "3rem", marginBottom: "3rem", backgroundColor: "#71acc0", color: "white" }}><strong>New Order Form</strong></h2>
        <MDBStepper icon>
          <MDBStep icon="info-circle" stepName="Basic Information" onClick={this.swapFormActive(1)}></MDBStep>
          <MDBStep icon="home" stepName="Delivery Address" onClick={this.swapFormActive(2)}></MDBStep>
          <MDBStep icon="shopping-cart" stepName="Payment" onClick={this.swapFormActive(3)}></MDBStep>
          <MDBStep icon="book-open" stepName="Terms and Conditions" onClick={this.swapFormActive(4)}></MDBStep>
          <MDBStep icon="check" stepName="Finish" onClick={this.swapFormActive(5)}></MDBStep>
        </MDBStepper>


        <MDBRow>
          {this.state.formActivePanel === 1 &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4">
                <strong>Device</strong></h3>
              <select className="browser-default custom-select" onChange={this.handleDevice} value={this.state.device} required>
                <option key="default">Choose your device</option>
                {this.state.terminalTypes.map(function (k, v) { return <option key={k.Name} value={k.Name}>{k.Properties.Description}</option> })}
              </select>
              <MDBInput label="Quantity" type="number" className="mt-4" onChange={this.handleQuantity} value={this.state.quantity} required />
              <MDBBtn color="red"  className="float-left" onClick={this.redirect}> Cancel </MDBBtn>
              <MDBBtn color="mdb-color"  className="float-right" onClick={this.swapFormActive(2)}>next</MDBBtn>
            </MDBCol>)}

          {this.state.formActivePanel === 2 &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4">
                <strong>Delivery Address</strong></h3>
                <p className="address-errors">{this.state.errorPC}</p>
              <MDBInput label="Post Code / Zip Code" onChange={this.handlePC} value={this.state.PC} required />
              <PostcodeLookup postcode={this.state.PC} update={this.updateAddress} />
              <br />
              <br />
              <p className="address-errors">{this.state.errorCompany}</p>
              <MDBInput label="Company Name" onChange={this.handleCompanyName} value={this.state.companyName} required />
              <p  className="address-errors">{this.state.errorAD1}</p>
              <MDBInput label="Address Line 1" onChange={this.handleAD1} value={this.state.AD1} required />
              <p  className="address-errors">{this.state.errorAD2}</p>
              <MDBInput label="Address Line 2" onChange={this.handleAD2} value={this.state.AD2} required />
              <p  className="address-errors">{this.state.errorTC}</p>
              <MDBInput label="Town/City" onChange={this.handleTC} value={this.state.TC} required />
              <p  className="address-errors">{this.state.errorCS}</p>
              <MDBInput label="County/State" onChange={this.handleCS} value={this.state.CS} required />
              <p  className="address-errors">{this.state.errorCountry}</p>
              <MDBInput label="Country" onChange={this.handleCountry} value={this.state.Country} required />
              <MDBBtn color="mdb-color"  className="float-left" onClick={this.swapFormActive(1)}> previous </MDBBtn>
              <MDBBtn color="mdb-color"  className="float-right" onClick={this.swapFormActive(3)}>next</MDBBtn>
            </MDBCol>)}

          {this.state.formActivePanel === 3 &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Payment</strong></h3>
              <select className="browser-default custom-select" onChange={this.handlePayment} value={this.state.payment} required>
                <option>Choose your payment method</option>
                <option value="Account">Account</option>
              </select>
              <MDBInput style={{marginTop:"5px"}} label="Please Enter a Purchase Number" onChange={this.handlePO} value={this.state.po} required />
              <MDBBtn color="mdb-color"  className="float-left" onClick={this.swapFormActive(2)}>previous</MDBBtn>
              <MDBBtn color="mdb-color"  className="float-right" onClick={this.swapFormActive(4)}>next</MDBBtn>
            </MDBCol>)}

          {this.state.formActivePanel === 4 &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Terms and conditions</strong></h3>
              <div className="overflow-auto" style={{ maxWidth: "100%", maxHeight: "500px" }}>
                <Terms />
              </div>
              <MDBBtn color="mdb-color"  className="float-left" onClick={this.swapFormActive(3)}>previous</MDBBtn>
              <MDBBtn color="mdb-color"  className="float-right" type="submit" onClick={this.swapFormActive(5)}>next</MDBBtn>
            </MDBCol>)}

          {this.state.formActivePanel === 5 &&
            (<MDBCol md="12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Finish</strong></h3>
              <div className="OrderSum">
                <h2 className="text-center font-weight-bold my-4" style={{ backgroundColor: "#2196f3", color: "white" }}>Order Summary</h2>

                <p className="font-weight-bold">Device:</p>
                <p className="text-muted">{this.state.device}</p>

                <p className="font-weight-bold">Quantity:</p>
                <p className="text-muted">{this.state.quantity}</p>

                <p className="font-weight-bold">Company name:</p>
                <p className="text-muted">{this.state.companyName}</p>

                <p className="font-weight-bold">Delivery Address is:</p>
                <p className="text-muted">{this.state.AD1}</p>
                <p className="text-muted">{this.state.AD2}</p>
                <p className="text-muted">{this.state.TC}</p>
                <p className="text-muted">{this.state.PC}</p>
                <p className="text-muted">{this.state.CS}</p>
                <p className="text-muted">{this.state.Country}</p>

                <p className="font-weight-bold">Payment:</p>
                <p className="text-muted">{this.state.payment}</p>

                <p className="font-weight-bold">Your Purchase Order No. is:</p>
                <p className="text-muted">{this.state.po}</p>

                <div className="custom-control custom-checkbox pl-3">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    checked={this.state.terms}
                    onChange={this.handleTerms}
                    id="invalidCheck"
                    required
                  />
                  <label className="custom-control-label" htmlFor="invalidCheck">
                    Agree to terms and conditions
              </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
              </div>
                </div>
              </div>
              <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.swapFormActive(4)}>previous</MDBBtn>
              {this.state.terms ? (<MDBBtn type="submit" color="success" rounded className="float-right" onClick={this.handleSubmit} >Submit</MDBBtn>) : (<div></div>)}
            </MDBCol>)}
        </MDBRow>

      </MDBContainer>
    );
  };
}

export default NewOrders;