import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { MDBBtn, MDBContainer, MDBAlert, MDBIcon } from "mdbreact";
import { confirmAlert } from "react-confirm-alert";
import Amplify from "../api.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./DeleteUser.css";
import "./GP.scss";
import { Auth } from 'aws-amplify';


class DeleteUser extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  error: "",
                  cognitoUser: "",
            };
            this.deleteUser = this.deleteUser.bind(this);
            this.confirm = this.confirm.bind(this);
            this.alertDelete = this.alertDelete.bind(this);
            this.getUsers = this.getUsers.bind(this);
            this.getCurrentUser = this.getCurrentUser.bind(this);
      }

      confirm() {
            confirmAlert({
                  customUI: ({ onClose }) => {
                        return (
                              <div className='conf-check'>
                                    <h1>Delete User</h1>
                                    <p>This will permanently delete the user from the organisation, do you wish to proceed?</p>
                                    <MDBBtn
                                          color="success"
                                          onClick={() => {
                                                this.deleteUser();
                                                onClose();
                                          }}
                                    >
                                          Yes
                                    </MDBBtn>
                                    <MDBBtn color="danger" onClick={onClose}>No</MDBBtn>
                              </div>
                        );
                  }
            });
      }

      alertDelete() {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const user = params.get("user");
            return (
                  <MDBContainer>
                        <MDBAlert color="warning" dismiss>
                              <strong>Confirmation</strong>
                              {user} has been deleted
                        </MDBAlert>
                  </MDBContainer>
            );
      }

      async getCurrentUser() {
            return await Auth.currentAuthenticatedUser({
                  bypassCache: false
            }).then(response => {
                  console.log(response, "Response")
                  this.setState({
                        cognitoUser: response.username,
                  }, function () { console.log("cognitoUser", this.state.cognitoUser) });

            })
                  .catch(err => console.log(err));
      }

      async deleteUser() {
            await this.getCurrentUser();
            let cognitoUserOrg = this.state.cognitoUser;
            let cognitoUser = cognitoUserOrg.split('/').pop();

            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const user = params.get("user");
            let payload = {
                  userName: user,
                  complete: this.props.complete
            };
            console.log("data.length" + this.state.data.length);
            console.log("cognitoUser", cognitoUser, "user", user)

            if (this.state.data.length >= 2 && cognitoUser !== user) {
                  this.props.deleteUser(payload);
            } else if ((this.state.data.length === 0)) {
                  this.setState({ error: "Error checking user pool before deletion" });
                  console.log("Error checking user pool before deletion");
            } else if (cognitoUser === user) {
                  this.setState({ error: "You cannot delete yourself..." })
            } else {
                  this.setState({ error: "You cannot delete the last user" });
                  console.log("You cannot delete the last user");
            }
      }

      componentDidMount() {
            this.getUsers();
      }

      async getAllUsers() {
            let apiName = "TennantAPI";
            let path = "manageuserpool";
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.get(apiName, path, myInit);
      }



      getUsers() {
            this.getAllUsers()
                  .then(response => {
                        console.log("response", response);
                        if (response === null) {
                              this.setState({ data: [] });
                        } else {
                              var array = [],
                                    item = [];
                              var i, j;
                              for (i = 0; i < response.length; i++) {
                                    var permissions = "";
                                    for (
                                          j = 0;
                                          j < response[i].permissions.length;
                                          j++
                                    ) {
                                          permissions =
                                                permissions +
                                                response[i].permissions[j] +
                                                " ";
                                    }
                                    item = [
                                          { text: response[i].emailAddress },
                                          { text: response[i].status },
                                          { text: permissions },
                                          { text: response[i].userName }
                                    ];
                                    array.push(item);
                              }

                              this.setState({ data: array });
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get data " + error);
                  });
      }

      render() {
            return (
                  <>
                        <MDBBtn
                              className="DeleteButton"
                              size="sm"
                              color="danger"
                              onClick={this.confirm}
                        >
                              <MDBIcon icon="trash-alt" /> Delete
                        </MDBBtn>
                        <p className="error-message">{this.state.error}</p>
                  </>
            );
      }
}

export default withRouter(DeleteUser);
