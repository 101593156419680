import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
      MDBContainer,
      MDBRow,
      MDBCol,
      MDBBtn,
      MDBCollapse,
      MDBIcon
} from "mdbreact";
import Amplify from "../api.js";
import SCPTable from "../SCPTable.js";
import routes from "../../config/routes";
import InfoHide from "../InfoHide";
import "./ManageTids.css";
import StockButton from "../Stock/StockButton";

class ManageTerminals extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  collapseID: "",
                  error: ""
            };
            this.notifyAcq = this.notifyAcq.bind(this);
      }

      toggleCollapse = collapseID => () => {
            this.setState(prevState => ({
                  collapseID:
                        prevState.collapseID !== collapseID ? collapseID : ""
            }));
      };

      getTerminals() {
            this.getAllTerminals()
                  .then(response => {
                        console.log("response", response);
                        if (response === null) {
                              this.setState({ data: [] });
                        } else {
                              let array = [];

                              for (let i = 0; i < response.length; i++) {
                                    let midStrings = [];

                                    if (response[i].Mids !== null) {
                                          for ( const [key, value] of Object.entries(response[i].Mids)) {
                                                midStrings.push(`${key}:
                                                ${value}`);
                                          }
                                          midStrings.reverse()
                                    }

                                    const item = [
                                          {text: response[i].Tid},
                                          {text: response[i].SerialNum},
                                          {text: midStrings.join(`
                                          
                                          `)},
                                          {text: response[i].MerchantName},
                                          {
                                                text:
                                                response[i]
                                                    .MerchantAddress1
                                          },
                                          {
                                                text:
                                                response[i]
                                                    .MerchantAddress2
                                          },
                                          {text: response[i].TownCity},
                                          {text: response[i].Postcode},
                                          {text: response[i].TerminalType},
                                          {text: response[i].Status},
                                          {
                                                text: "Edit",
                                                link:
                                                    routes.editTid +
                                                    "?terminal=" +
                                                    response[i].Tid
                                          },
                                          {
                                                text: "OTP",
                                                link:
                                                    routes.generatePIN +
                                                    "?terminal=" +
                                                    response[i].Tid +
                                                    "&sn=" +
                                                    response[i].SerialNum
                                          }

                                    ];

                                    array.push(item);
                              }
                              this.setState({data: array});
                              console.log("DATA", this.state.data)
                        }
                  })
                  .catch(error => {
                        console.log("Failed to get data " + error);
                  });
      }

      //Calls the getUser
      componentDidMount() {
            window.scrollTo(0, 0)
            this.getTerminals();
      }

      //Gets list of TIDs
      async getAllTerminals() {
            let apiName = "TennantAPI";
            let path = "manageterminals/tids";
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  },
                  queryStringParameters: { max: "2000" }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.get(apiName, path, myInit);
      }

      async postData() {
            let apiName = "TennantAPI";
            let path = "manageterminals/sendconfigchanges";
            let myInit = {
                  headers: {
                        Authorization: `Bearer ${(
                              await Amplify.Auth.currentSession()
                        )
                              .getIdToken()
                              .getJwtToken()}`
                  }
            };
            console.log("Calling ", apiName);
            return await Amplify.API.post(apiName, path, myInit)
                  .then(() => {
                        console.log("refresh");
                        this.getTerminals();
                  })
                  .catch(function (error) {
                        console.log(JSON.stringify(error));
                  });
      }

      notifyAcq() {
            this.postData();
      }

      render() {
            const header = [
                  "Terminal ID",
                  "Serial No.",
                  "Acquirers and Mids",
                  "Merchant Name",
                  "Merchant Address 1",
                  "Merchant Address 2",
                  "Town/City",
                  "Postcode",
                  "Terminal Type",
                  "Status",
                  "",
                  ""
            ];

            return (
                  <MDBContainer
                        style={{ paddingTop: "50px", minHeight: "73.65vh" }}
                  >
                        <MDBRow>
                              <p>{this.state.error}</p>
                              <InfoHide
                                    info1="Terminals:"
                                    info2="View all your terminals, edit terminal details and generate your one time PIN"
                              />
                        </MDBRow>
                        <MDBRow style={{ marginLeft: "0px" }}>
                              <MDBBtn
                                    id="more-info"
                                    color="primary"
                                    onClick={this.toggleCollapse(
                                          "basicCollapse"
                                    )}
                              >
                                    <MDBIcon
                                          icon="plus-circle"
                                          className="buttonIcons"
                                    />
                                    More Info
                              </MDBBtn>
                              {/* Un-comment when backend work is complete for notifying acquirers */}
                              {/* <MDBBtn color="warning" onClick={this.notifyAcq}>
                                    <MDBIcon
                                          icon="envelope"
                                          className="buttonIcons"
                                    />
                                    Notify Acquirers
                              </MDBBtn> */}
                              <StockButton />
                        </MDBRow>

                        <MDBRow>
                              <MDBCollapse
                                    id="basicCollapse"
                                    isOpen={this.state.collapseID}
                              >
                                    <MDBContainer>
                                          <p>
                                                On this page, you can view a
                                                list of terminals that have been
                                                generated from your orders. In
                                                order to get a terminal ready
                                                for use, you must first edit and
                                                enter the relevant details.
                                          </p>
                                          <p>
                                                Once the relevant details have
                                                been entered for the terminal
                                                you will see the status change
                                                to 'Updated'. It is at this
                                                point that you must now press
                                                the 'Notify Acquirers' button.
                                                This will send a pdf to your
                                                acquirer(s) of the newly set up
                                                terminal. Ensure the status has
                                                now changed to 'Acquirer
                                                Notified'.
                                          </p>
                                          <p>
                                                You are now ready to generate
                                                your One Time Pin. Press the PIN
                                                button next to your terminal and
                                                enter a serial code which would
                                                have been given to you once your
                                                order was fulfilled. Serial
                                                numbers can be found in Manage >
                                                Stock.
                                          </p>
                                    </MDBContainer>
                              </MDBCollapse>
                        </MDBRow>

                        <MDBRow style={{ marginBottom: "20px" }}>
                              <MDBCol>
                                    <SCPTable
                                          header={header}
                                          table={this.state.data}
                                    />
                              </MDBCol>
                        </MDBRow>
                  </MDBContainer>
            );
      }
}

export default withRouter(ManageTerminals);
