
import awsmobile from '../aws-exports';

export const filePreviewDim = '80px';
export const maxFileSize = 100000;
export const allowedFileTypes = ['image/png', 'image/jpeg'];

export const passwordRegex = /^(?=.{1,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const nameRegex = /^[a-z0-9_-]*$/;
export const orgNameRegex = /^[a-z0-9_-]*$/;
// [TODO]: Work out the regex for product description
export const descriptionRegex = /.*/;



export const aws = {
      cognito: {
            region: awsmobile.aws_project_region,
            userPoolId: awsmobile.aws_user_pools_id,
            appClientId: awsmobile.aws_user_pools_web_client_id,
            identityPoolId: awsmobile.aws_cognito_identity_pool_id
      },
      s3: {
            region: awsmobile.aws_content_delivery_bucket_region,
            bucket: awsmobile.aws_content_delivery_bucket
      },

};

