import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { MDBBtn, MDBDataTable } from "mdbreact";

var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP"
});

// SCPTable takes two parameters header and table. header is an array containing the headers.  Array is an array of obects
// the objects are {text:"",link:""}  if link is undefined the text is displayed as text.  If link is defined a button is displayed which when
// pressed goes to the link

//Trans Time& Date response looks like: "TransTime": "2009111648",
//First 6 are the date, last 4 are the time
// 20 09 11 16:48

const SCPTableV2 = props => {
      var row = [];
      for (var i = 0; i < props.table.length; i++) {
            var item = {};
            for (var j = 0; j < props.table[i].length; j++) {
                  if (props.table[i][j].link !== undefined) {
                        item[`${j}`] = (
                              <TableButton
                                    link={props.table[i][j].link}
                                    text={props.table[i][j].text}
                              />
                        );
                  } else if (props.table[i][j].date !== undefined) {
                        item[`${j}`] =
                              props.table[i][j].date.slice(2, 4) +
                              "/" +
                              props.table[i][j].date.slice(4, 6) +
                              "/" +
                              props.table[i][j].date.slice(0, 2);
                  } else if (props.table[i][j].dateTime !== undefined) {
                        item[`${j}`] =
                              props.table[i][j].dateTime.slice(4, 6) +
                              "/" +
                              props.table[i][j].dateTime.slice(2, 4) +
                              "/" +
                              props.table[i][j].dateTime.slice(0, 2) +
                              " " +
                              props.table[i][j].dateTime.slice(6, 8) +
                              ":" +
                              props.table[i][j].dateTime.slice(8, 10);
                  } else if (props.table[i][j].currency !== undefined) {
                        item[`${j}`] = formatter.format(
                              props.table[i][j].currency / 100
                        );
                  } else if (props.table[i][j].transType !== undefined) {
                        item[`${j}`] = props.table[i][j].transType;
                  } else if (props.table[i][j].transResult !== undefined) {
                        item[`${j}`] = props.table[i][j].transResult;
                  } else if (props.table[i][j].action !== undefined) {
                        item[`${j}`] = (
                              <TableActionButton
                                    action={props.table[i][j].action}
                                    text={props.table[i][j].text}
                                    param={props.table[i][j].param}
                              />
                        );
                  } else if (props.table[i][j].mid !== undefined) {
                        item[`${j}`] = props.table[i][j].mid;
                  } else if (props.table[i][j].terminalID !== undefined) {
                        item[`${j}`] = props.table[i][j].terminalID;
                  } else if (props.table[i][j].merchantName !== undefined) {
                        item[`${j}`] = props.table[i][j].merchantName;
                  } else if (props.table[i][j].serialNumber !== undefined) {
                        item[`${j}`] = props.table[i][j].serialNumber;
                  } else if (props.table[i][j].batchNumber !== undefined) {
                        item[`${j}`] = props.table[i][j].batchNumber;
                  } else if (props.table[i][j].transNumber !== undefined) {
                        item[`${j}`] = props.table[i][j].transNumber;
                  } else if (props.table[i][j].eposTXN !== undefined) {
                        item[`${j}`] = props.table[i][j].eposTXN;
                  } else if (props.table[i][j].cashBack !== undefined) {
                        item[`${j}`] = formatter.format(
                              props.table[i][j].cashBack / 100
                        );
                  }
                  else if(props.table[i][j].pennies!==undefined){
                        item[`${j}`]=formatter.format(
                            props.table[i][j].pennies /100
                        );
                  }
                  else if (props.table[i][j].maskedPAN !== undefined) {
                        item[`${j}`] = props.table[i][j].maskedPAN;
                  } else if (props.table[i][j].authCode !== undefined) {
                        item[`${j}`] = props.table[i][j].authCode;
                  } else if (props.table[i][j].transResult !== undefined) {
                        item[`${j}`] = props.table[i][j].transResult;
                  } else if (props.table[i][j].cardSch !== undefined) {
                        item[`${j}`] = props.table[i][j].cardSch;
                  } else if (props.table[i][j].currencyType !== undefined) {
                        item[`${j}`] = props.table[i][j].currencyType;
                  } else if (props.table[i][j].cardExpiryDate !== undefined) {
                        item[`${j}`] = props.table[i][j].cardExpiryDate;
                  } else if (props.table[i][j].transSrc !== undefined) {
                        item[`${j}`] = props.table[i][j].transSrc;
                  } else if (props.table[i][j].rCode !== undefined) {
                        item[`${j}`] = props.table[i][j].rCode;
                  } else if (props.table[i][j].settleNo !== undefined) {
                        item[`${j}`] = props.table[i][j].settleNo;
                  } else if (props.table[i][j].acquirer !== undefined) {
                        item[`${j}`] = props.table[i][j].acquirer;
                  } else {
                        item[`${j}`] = props.table[i][j].text;
                  }
            }
            row.push(item);
      }

      const data = {
            columns: props.header.map(function(array, i) {
                  return { label: array, field: i };
            }),
            rows: row
      };
      // console.log("HEADERS", data)
      // console.log("HEADERS+Filter", data.columns[0].label)
      return (
            <MDBDataTable
                  small
                  data={data}
                  paging={false}
                  btn={true}
                  hover
                  searching={false}
                  proSelect
                  sortable={false}
                  theadColor="default-color"
            />
      );
};

class TableButton extends React.Component {
      render() {
            return (
                  <LinkContainer className="NavItem" to={this.props.link}>
                        <MDBBtn color="amber" size="sm" style={{}}>
                              {this.props.text}
                        </MDBBtn>
                  </LinkContainer>
            );
      }
}

class TableActionButton extends React.Component {
      render() {
            return (
                  <MDBBtn
                        color="amber"
                        size="sm"
                        style={{}}
                        onClick={() => this.props.action(this.props.param)}
                  >
                        {this.props.text}
                  </MDBBtn>
            );
      }
}

export default SCPTableV2;
