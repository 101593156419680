import React from "react";
import { withRouter } from 'react-router-dom';

import { MDBContainer, MDBRow, MDBCol, MDBView, MDBMask, MDBAnimation, MDBBtn, MDBSmoothScroll } from "mdbreact";
import FeaturesPage from './components/Features';

import './Products.css';
import ApiFeatures from "./components/ApiFeatures";

class Products extends React.Component {

      render() {

            return (
                  <React.Fragment>
                        <div id="apppage">
                              <MDBView>
                                    <MDBMask className="d-flex justify-content-center align-items-center gradient">
                                          <MDBContainer>
                                                <MDBRow>

                                                      <div className="white-text text-center text-md-left col-md-6 mt-xl-5 mb-5">
                                                            <MDBAnimation reveal type="fadeInLeft">
                                                                  <h1 className="h1-responsive font-weight-bold mt-sm-5">
                                                                        Process sales with our services{" "}
                                                                  </h1>
                                                                  <hr className="hr-light" />
                                                                  <h6 className="mb-4">
                                                                        SureCanPay provides PIN entry devices and a simple
                                                                        robust API for integrating payments into new and
                                                                        existing systems. Supports all major debit & credit cards.
                                                                        Supports high value contactless & mobile payments
                                                                  </h6>
                                                                  <MDBSmoothScroll to="section1">
                                                                        <MDBBtn outline color="white">
                                                                              Learn More
                                                                        </MDBBtn>
                                                                  </MDBSmoothScroll>
                                                            </MDBAnimation>
                                                      </div>

                                                      <MDBCol md="6" xl="5" className="mt-xl-5">
                                                            <img
                                                                  src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </MDBCol>
                                                </MDBRow>
                                          </MDBContainer>
                                    </MDBMask>
                              </MDBView>
                        </div>

                        <MDBContainer>
                              <MDBRow >
                                    <MDBCol id="section1">
                                          <FeaturesPage />
                                    </MDBCol>
                              </MDBRow >
                        </MDBContainer>

                        <MDBAnimation reveal type="fadeInUp">
                              <div id="bottomPage">
                                    <br />
                                    <br />
                                    <br />
                              </div>
                        </MDBAnimation>

                        <MDBContainer>
                              <MDBRow>
                                    <MDBAnimation reveal type="fadeInLeft">
                                          <ApiFeatures />
                                    </MDBAnimation>
                              </MDBRow>
                        </MDBContainer>
                  </React.Fragment >
            );

      }
}

export default withRouter(Products);


