import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Products from "../Products";
import Orders from "../Orders";
import NewOrders from "../NewOrders";
import MyOrganisation from "../MyOrganisation";
import Transactions from "../Transactions"
import Batches from "../Batches"
import OrderDetail from "../OrderDetail"
import Developer from "../Developer"
import EditTid from "../EditTid"
import GeneratePIN from "../GeneratePIN"
import EditUser from "../EditUser"
import ChangePassword from "../ChangePassword"
import Support from "../Support";
import Stock from "../Stock";
import Settings from "../Settings"
import Login from "../Login";
import routes from "../../config/routes";
import {pages} from "../../config/pages";
import Permissions from "../Permissions";
import Training from "../Training";


const ManageTerminalsRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={pages.mTerminals} key={Date.now()}/>          
            </>
      );
};

const EditTIDRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={EditTid} key={Date.now()}/>          
            </>
      );
};

const NewOrderRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={NewOrders} key={Date.now()}/>          
            </>
      );
};

const OrderRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Orders} key={Date.now()}/>          
            </>
      );
};

const MyOrgRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={MyOrganisation} key={Date.now()}/>          
            </>
      );
};

const TransactionRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Transactions} key={Date.now()}/>          
            </>
      );
};

const BatchesRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Batches} key={Date.now()}/>          
            </>
      );
};

const CompanyRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Batches} key={Date.now()}/>          
            </>
      );
};

const ProductRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Products} key={Date.now()}/>          
            </>
      );
};

const OrderDetailRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={OrderDetail} key={Date.now()}/>          
            </>
      );
};

const DeveloperRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Developer} key={Date.now()}/>          
            </>
      );
};

const SettingsRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Settings} key={Date.now()}/>          
            </>
      );
};

const SupportRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Support} key={Date.now()}/>          
            </>
      );
};

const PINRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={GeneratePIN} key={Date.now()}/>          
            </>
      );
};

const EditUserRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={EditUser} key={Date.now()}/>          
            </>
      );
};

const ChangePasswordRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={ChangePassword} key={Date.now()}/>          
            </>
      );
};

const StockRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Stock} key={Date.now()}/>          
            </>
      );
};


const TrainingRoute = ({ showForRoles, roles, route}) => {

      if (roles.filter((role) => showForRoles.includes(role)).length === 0) return <Permissions/>;
      return (
            <>
            <Route path={route} component={Training} key={Date.now()}/>          
            </>
      );
};

const Component = ({ loggedIn, match: { path } }) =>
      loggedIn.length === 0 ? (
            <Switch>
                  <Route path={`/*`} component={Login} />
            </Switch>
      ) : (
                  <Switch>
                        <ManageTerminalsRoute path={routes.manageTerminals} showForRoles={['Admin','Developer','ManageTerminals']} roles={loggedIn}/>
                        {/* <Route path={`${path}/manage-terminals`} component={ManageTerminals} /> */}

                        <EditTIDRoute path={routes.editTid} showForRoles={['Admin','Developer']} roles={loggedIn} />
                        {/* <Route path={`${path}/edit-terminal`} roles={loggedIn} /> */}
                        
                        <NewOrderRoute path={routes.neworders} showForRoles={['Admin','Orders']} roles={loggedIn} />
                        {/* <Route path={`${path}/orders/new-orders`} component={NewOrders} /> */}

                        <OrderRoute path={routes.orders} showForRoles={['Admin','Orders']} roles={loggedIn} />
                        {/* <Route path={`${path}/orders`} component={Orders} /> */}
                        
                        <MyOrgRoute path={routes.myorganisation} showForRoles={['Admin']} roles={loggedIn} />
                        {/* <Route path={`${path}/myorganisation`} component={MyOrganisation} /> */}

                        <TransactionRoute path={routes.transactions} showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                        {/* <Route path={`${path}/transactions`} component={Transactions} /> */}

                        <BatchesRoute path={routes.batches} showForRoles={['Admin', 'ViewTransactions']} roles={loggedIn} />
                        {/* <Route path={`${path}/batches`} component={Batches} /> */}

                        <CompanyRoute path={routes.authcompany} showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                        {/* <Route path={`${path}/company`} component={Company} /> */}
                        
                        <ProductRoute path={routes.authproducts}  showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']} roles={loggedIn} />
                        {/* <Route path={`${path}/products`} component={Products} /> */}

                        <OrderDetailRoute path={routes.detail}  showForRoles={['Admin', 'Orders']} roles={loggedIn} />
                        {/* <Route path={`${path}/orderdetail`} component={OrderDetail} /> */}

                        <DeveloperRoute path={routes.developer}  showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                        {/* <Route path={`${path}/developer`} component={Developer} /> */}
                        
                        <SettingsRoute path={routes.parameters}  showForRoles={['Admin', 'Developer']} roles={loggedIn} />
                        {/* <Route path={`${path}/settings`} component={Settings} /> */}

                        <SupportRoute path={routes.authSupport}  showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']}  roles={loggedIn} />
                        {/* <Route path={`${path}/support`} component={Support} /> */}

                        <PINRoute path={routes.generatePIN}  showForRoles={['Admin', 'Developer','ManageTerminals']} roles={loggedIn} />
                        {/* <Route path={`${path}/generate-PIN`} component={GeneratePIN} /> */}
                        
                        <EditUserRoute path={routes.editUser}  showForRoles={['Admin']} roles={loggedIn} />
                        {/* <Route path={`${path}/edit-user`} component={EditUser} /> */}

                        <ChangePasswordRoute path={routes.changePassword}  showForRoles={['Admin', 'Guest']} roles={loggedIn} />
                        {/* <Route path={`${path}/changepassword`} component={ChangePassword} /> */}

                        <StockRoute path={routes.stock}  showForRoles={['Admin', 'Developer','ManageTerminals']} roles={loggedIn} />
                        {/* <Route path={`${path}/stock`} component={Stock} /> */}

                        <TrainingRoute path={routes.authTraining}  showForRoles={['Admin', 'Developer', 'ViewTransactions', 'ManageTerminals', 'Guest']}  roles={loggedIn} />
                  </Switch>
            );

Component.propTypes = {
      loggedIn: PropTypes.arrayOf(PropTypes.string),
      match: PropTypes.shape({
            path: PropTypes.string
      }).isRequired
};

Component.defaultProps = {
      loggedIn: []
};

export default Component;
