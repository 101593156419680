import {withRouter} from "react-router-dom";
import React, {Component} from "react";
import {MDBAnimation, MDBBtn, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import Amplify from "../api.js";
import InfoHide from "../InfoHide";

import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {ClosableDefaultSelect, DefaultTextInput} from "../formik.js";
import "./EditTid.css";

class EditTid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Tid: "",
            SerialNum: "",
            TerminalType: "",
            MerchantName: "",
            MerchantAddress1: "",
            MerchantAddress2: "",
            Midnums: [],
            Acquirers: [""],
            TownCity: "",
            Postcode: "",
            MerchantCategoryCode: "",
            PenniesEnabled: true,
            County: "",
            Country: "",
            SelectedAcquirers: [],

            // Add exclusive groups of acquirers here - users will be allowed to add up to 1 from each group
            AcquirerGroups: [
                ['bms', 'bmsdev', 'test', 'omnipay', 'omnipaydev', 'omnipaytest', 'gateway', 'gatewaydev', 'gatewaytest'],
                ['amex', 'amexdev', 'amextest', 'gatewayamex', 'gatewayamexdev', 'gatewaytest']
            ],
        };
        this.sortAcquirers = this.sortAcquirers.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);

    }

    handleCheckClick = () => {
        // this.setState({ PenniesEnabled: !this.state.PenniesEnabled });
        this.setState((prevState) => ({
            ...prevState,
            PenniesEnabled: !this.state.PenniesEnabled
        }));
        console.log(this.state.PenniesEnabled);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getTerminalInfo();
    }


    async getTerminal() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const Tid = params.get("terminal");
        let apiName = "TennantAPI";
        let path = "manageterminals/tids/" + Tid;
        let myInit = {
            headers: {
                Authorization: `Bearer ${(
                    await Amplify.Auth.currentSession()
                )
                    .getIdToken()
                    .getJwtToken()}`
            }
        };
        console.log("Calling ", apiName);
        return await Amplify.API.get(apiName, path, myInit);
    }

    ////////////////////////////////////////////////////////////////////Get Acquire///////////////////////////////////
    async getAcquirers() {
        let apiName = "TennantAPI";
        let path = "manageterminals/listacquirers";
        let myInit = {
            headers: {
                Authorization: `Bearer ${(
                    await Amplify.Auth.currentSession()
                )
                    .getIdToken()
                    .getJwtToken()}`
            }
        };
        console.log("Calling ", apiName, "Acquires");
        return await Amplify.API.get(apiName, path, myInit);
    }

    sortAcquirers() {
        const AcqArray = this.state.Acquirers;
        const acqGroups = [];

        this.state.AcquirerGroups.forEach((arr) => {
            let temp = arr.filter(x => AcqArray.includes(x));
            let selectedIndex = 0;

            temp.forEach((value, index) => {
                if (this.state.SelectedAcquirers.includes(value)) {
                    selectedIndex = index
                }
            });

            if (selectedIndex !== 0) {
                temp.unshift(temp.splice(selectedIndex, 1)[0]);
            }

            acqGroups.push(temp)
        })
        this.setState({AcquirerGroups: acqGroups});
    }

    getTerminalInfo() {
        this.getTerminal()
            .then(response => {
                if (response.Mids !== null) {
                    console.log("mids: " + JSON.stringify(response.Mids))
                    const midnumbers = Object.values(response.Mids);
                    console.log("MIDNumber", midnumbers);
                    const acq = Object.keys(response.Mids);
                    console.log("MID-Object-Key", acq);
                    acq.reverse()

                    if (acq.length > 0) {
                        let acqGroups = [...this.state.AcquirerGroups]

                        acq.forEach((acquirer, index) => {
                            const groupContainingAcquirer = acqGroups.filter(group => group.includes(acquirer))[0]
                            const indexOfGroup = acqGroups.indexOf(groupContainingAcquirer)

                            if (indexOfGroup !== -1) {
                                acqGroups.splice(index, 0, acqGroups.splice(indexOfGroup, 1)[0]);
                            }
                        });

                        acqGroups = acqGroups.map(group => {
                            let tempGroup = [...group];
                            const selectedFromGroup = acq.filter(a => group.includes(a));

                            if (selectedFromGroup.length > 0) {
                                const selected = selectedFromGroup[0];
                                tempGroup = tempGroup.filter(item => item !== selected);
                                tempGroup.unshift(selected);
                            }
                            return tempGroup;
                        });
                        this.setState({AcquirerGroups: acqGroups});
                    }

                    this.setState({SelectedAcquirers: acq});
                    this.setState({Midnums: midnumbers.reverse()});
                }

                this.setState({
                    Tid: response.Tid,
                    TerminalType: response.TerminalType,
                    SerialNum: response.SerialNum,
                    MerchantName: response.MerchantName,
                    MerchantAddress1: response.MerchantAddress1,
                    MerchantAddress2: response.MerchantAddress2,
                    MerchantCategoryCode: response.MerchantCategoryCode,
                    TownCity: response.TownCity,
                    Postcode: response.Postcode,
                    County: response.County,
                    Country: response.Country,
                    PenniesEnabled: response.PenniesEnabled
                });
            })
            .catch(error => {
                console.log("Failed to get TID data " + error);
            });

        //Gets response from Acquirers then filters them into an Acquirers Array state
        this.getAcquirers()
            .then(response => {
                this.setState({Acquirers: response});
                this.sortAcquirers();
            })
            .catch(error => {
                console.log("Failed to get Acquirer data " + error);
            });
    }

    addSelectedAcquirer = (selected, index) => {
        let selectedAcqs = this.state.SelectedAcquirers;

        if (selectedAcqs.length === 0 && index !== 0) {
            selectedAcqs[0] = this.state.AcquirerGroups[0][0];
        }

        selectedAcqs[index] = selected;
        console.log(selectedAcqs);
        this.setState({SelectedAcquirers: selectedAcqs});
    }

    removeSelectedAcquirer = (index) => {
        let selectedAcqs = this.state.SelectedAcquirers;
        selectedAcqs.splice(index, 1);
        console.log(selectedAcqs);
        this.setState({SelectedAcquirers: selectedAcqs});
    }

    getRemainingAcquirerOptions = (index) => {
        let options = []

        if (index === 0 || this.state.SelectedAcquirers == null || this.state.SelectedAcquirers.length === 0) {
            this.state.AcquirerGroups.forEach(group => {
                options = options.concat(group);
            });
        } else {
            let prevAcquirers = this.state.SelectedAcquirers.slice(0, index);
            this.state.AcquirerGroups.forEach(group => {
                let addGroup = true;
                prevAcquirers.forEach(selected => {
                    if (group.includes(selected)) {
                        addGroup = false;
                    }
                });
                if (addGroup) {
                    options = options.concat(group);
                }
            });

        }
        return options
    }

    generateValidationSchema = () => {
        let schema = Yup.object({
            MerchantName: Yup.string()
                .min(
                    3,
                    "Must be at least 3 characters"
                )
                .required("Required")
                .trim(
                    "No trailing spaces for Merchant Name"
                )
                .strict(true),
            MerchantAddress1: Yup.string()
                .min(
                    3,
                    "Must be at least 3 characters"
                )
                .required("Required"),
            MerchantAddress2: Yup.string().max(
                50,
                "Cannot be longer than 50 characters"
            ),
            TownCity: Yup.string()
                .min(3, "Must be at least 3 char")
                .required("Required")
                .max(
                    50,
                    "Cannot be longer than 50 characters"
                ),
            Postcode: Yup.string()
                .min(
                    5,
                    "Must be at least 5 characters"
                )
                .max(
                    8,
                    "Cannot be longer than 8 characters"
                )
                .trim(),
            County: Yup.string()
                .min(
                    3,
                    "Must be at least 3 characters"
                )
                .max(
                    70,
                    "Cannot be longer than 70 characters"
                ),
            Country: Yup.string()
                .min(
                    2,
                    "Must be at least 2 characters"
                )
                .max(
                    25,
                    "Cannot be longer than 25 characters"
                ),
            MerchantCategoryCode: Yup.string()
                .min(
                    2,
                    "Must be atleast 2 Characters"
                )
                .max(
                    4,
                    "Please enter a valid code"
                ),
        })

        this.state.SelectedAcquirers.forEach((group, index) => {
            const midnumName = "Midnum" + index
            const midnumSchema = Yup.object({
                [midnumName]: Yup.string()
                    .trim(
                        "No trailing spaces for the MID"
                    )
                    .strict(true)
            });
            schema = schema.concat(midnumSchema);
        })

        return schema
    }

    render() {
        return (
            <MDBContainer style={{paddingTop: "50px", minHeight: "73.65vh"}}>
                <MDBAnimation type="fadeInLeft">
                    <MDBRow>
                        <InfoHide
                            info1={"Terminal ID : " + this.state.Tid}
                            info2={"Serial No. : " + this.state.SerialNum}
                            info3={"Type : " + this.state.TerminalType}
                        />
                    </MDBRow>

                    <Formik
                        enableReinitialize
                        initialValues={this.state}
                        validationSchema={this.generateValidationSchema()}
                        onSubmit={(values, {setSubmitting}) => {
                            setTimeout(() => {
                                console.log(
                                    "Acquirer",
                                    values.Acquirer
                                );

                                this.Submit(values, {
                                    setSubmitting
                                });
                            }, 400);
                        }}
                    >
                        <Form style={{display: "grid"}}>
                            {this.renderMidFields()}
                            <DefaultTextInput
                                label="Merchant Name"
                                name="MerchantName"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Merchant Address 1"
                                name="MerchantAddress1"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Merchant Address 2"
                                name="MerchantAddress2"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Town/City"
                                name="TownCity"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="County"
                                name="County"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Country"
                                name="Country"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Postcode"
                                name="Postcode"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <DefaultTextInput
                                label="Merchant Category Code"
                                name="MerchantCategoryCode"
                                type="text"
                                onChange={e => {
                                    this.setState({[e.target.name]: e.target.value})
                                }}
                            />
                            <label>Pennies Enabled
                                <Field
                                    style={{position: "inherit", marginRight: "10px"}}
                                    type="checkbox"
                                    name="PenniesEnabled"
                                    checked={this.state.PenniesEnabled}
                                    default={true}
                                    value={this.state.PenniesEnabled}
                                    onChange={(e) => this.handleCheckClick(e)}
                                />

                            </label>
                            {this.renderAcquirerDropdowns()}
                            <br/>
                            {this.renderAddAcquirerBtn()}
                            <MDBRow
                                style={{
                                    display: "inline",
                                    marginLeft: "0px"
                                }}
                            >
                                <MDBBtn color="success" type="submit">
                                    Submit
                                </MDBBtn>
                                <MDBBtn
                                    color="danger"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                >
                                    Cancel
                                </MDBBtn>
                            </MDBRow>
                        </Form>
                    </Formik>
                </MDBAnimation>
            </MDBContainer>
        );
    }

    renderMidFields = () => {
        let selectedAcqs = [...this.state.SelectedAcquirers]

        if (selectedAcqs.length === 0) {
            selectedAcqs.push(" ")
        }

        return selectedAcqs.map((selected, index) => {
            return (
                <DefaultTextInput
                    label={"MID " + (index + 1)}
                    name={"Midnum" + index}
                    key={"Midnum" + index}
                    type="text"
                    value={this.state.Midnums[index]}
                    onChange={e => {
                        const midnums = this.state.Midnums;
                        midnums[index] = e.target.value;
                        this.setState({
                            Midnums: midnums,
                            [e.target.name]: e.target.value
                        });
                    }}
                />
            )
        });
    }

    renderAcquirerDropdowns = () => {
        let selectedAcqs = [...this.state.SelectedAcquirers]

        if (selectedAcqs.length === 0) {
            selectedAcqs.push(" ")
        }

        return selectedAcqs.map((selected, index) => {
            let options = this.getRemainingAcquirerOptions(index);
            if (index === 0) {
                return (
                    <ClosableDefaultSelect
                        label={"Acquirer " + (index + 1)}
                        name={"Acquirer" + index}
                        key={"Acquirer" + index}
                        onChange={e => this.addSelectedAcquirer(e.target.value, index)}
                        closable={false}
                        selected={this.state.SelectedAcquirers[0]}>
                        {options.map((value, key) => {
                            return (
                                <option
                                    key={key}
                                    value={value}
                                >
                                    {" "}
                                    {value}{" "}
                                </option>
                            )
                        })}
                    </ClosableDefaultSelect>
                )
            }
            return (
                <ClosableDefaultSelect
                    label={"Acquirer " + (index + 1)}
                    name={"Acquirer" + index}
                    key={"Acquirer" + index}
                    onChange={e => this.addSelectedAcquirer(e.target.value, index)}
                    closable={true}
                    index={index}
                    onClose={this.removeSelectedAcquirer}
                    selected={this.state.SelectedAcquirers[index]}>
                    {options.map((value, key) => {
                        return (
                            <option
                                key={key}
                                value={value}
                            >
                                {" "}
                                {value}{" "}
                            </option>
                        )
                    })}
                </ClosableDefaultSelect>
            )
        })
    }

    renderAddAcquirerBtn = () => {
        if (this.state.SelectedAcquirers == null || (this.state.SelectedAcquirers.length < this.state.AcquirerGroups.length)) {
            return (
                <MDBRow style={{marginLeft: "0px"}}>
                    <MDBBtn
                        id="more-info"
                        color="primary"
                        onClick={() => {
                            if (this.state.SelectedAcquirers.length < this.state.AcquirerGroups.length) {
                                let index = this.state.SelectedAcquirers.length;
                                let options = this.getRemainingAcquirerOptions(index);
                                let selected = this.state.SelectedAcquirers;
                                selected.push(options[0]);
                                this.setState({SelectedAcquirers: selected});
                            }
                        }}>
                        <MDBIcon
                            icon="plus-circle"
                            className="buttonIcons"
                        />
                        Add Acquirer
                    </MDBBtn>
                </MDBRow>
            )
        }
    }

    Submit = async (values, {setSubmitting}) => {
        let apiName = "TennantAPI";
        let path = "manageterminals/tids/" + values.Tid;
        let mids = {};

        if (this.state.SelectedAcquirers.length > 0) {
            this.state.SelectedAcquirers.forEach((acq, index) => {
                mids[acq] = values.Midnums[index];
            })
        } else {
            const defaultSelected = this.state.AcquirerGroups[0][0];
            mids[defaultSelected] = values["Midnum0"];
        }

        console.log("Setting Mids", JSON.stringify(mids));

        let myInit = {
            headers: {
                Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                Mids: mids,
                MerchantName: values.MerchantName,
                MerchantAddress1: values.MerchantAddress1,
                MerchantAddress2: values.MerchantAddress2,
                Postcode: values.Postcode,
                TownCity: values.TownCity,
                County: values.County,
                Country: values.Country,
                PenniesEnabled: values.PenniesEnabled,
                MerchantCategoryCode: values.MerchantCategoryCode
            }
        };
        console.log("Calling ", apiName);
        return await Amplify.API.post(apiName, path, myInit)
            .then(() => {
                setSubmitting(false);
                this.props.history.goBack();
            })
            .catch(function (error) {
                setSubmitting(false);
                console.log("POST tids Error", error);
            });
    };
}

export default withRouter(EditTid);
