import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
      MDBContainer,
      MDBTabPane,
      MDBTabContent,
      MDBNav,
      MDBNavItem,
      MDBNavLink,
      MDBIcon,
      MDBAnimation,
      MDBRow
} from "mdbreact";

import "../Parameters/Parameters.css";
import InfoHide from "../InfoHide";
import Parameters from "../Parameters";
import NotifyAcq from "./NotifyAcq";

class Settings extends Component {
      state = {
            activeItemClassicTabs3: "1"
      };

      toggleClassicTabs3 = tab => () => {
            if (this.state.activeItemClassicTabs3 !== tab) {
                  this.setState({
                        activeItemClassicTabs3: tab
                  });
            }
      };

      render() {
            return (
                  <MDBContainer
                        style={{ paddingTop: "50px", minHeight: "73.65vh" }}
                  >
                        <MDBAnimation type="fadeInLeft">
                              <MDBRow>
                                    <InfoHide info1="Settings:" info2="On this page you can generate an Access Key, which you can then use to download a settlement file of all your transactions" />
                              </MDBRow>

                              <MDBRow>
                                    <div className="classic-tabs" style={{width:"100%"}}>
                                          <MDBNav
                                                classicTabs
                                                color="primary"
                                                className="mt-5"
                                          >
                                                <MDBNavItem>
                                                      <MDBNavLink
                                                            link
                                                            to="#"
                                                            active={
                                                                  this.state
                                                                        .activeItemClassicTabs3 ===
                                                                  "1"
                                                            }
                                                            onClick={this.toggleClassicTabs3(
                                                                  "1"
                                                            )}
                                                      >
                                                            <MDBIcon
                                                                  icon="cog"
                                                                  size="2x"
                                                            />
                                                            <br />
                                                            Settings
                                                      </MDBNavLink>
                                                </MDBNavItem>
                                              
                                                {/* Un-comment when backend work is complete for notifying acquirers */}
                                                {/* <MDBNavItem>
                                                      <MDBNavLink
                                                            link
                                                            to="#"
                                                            active={
                                                                  this.state
                                                                        .activeItemClassicTabs3 ===
                                                                  "2"
                                                            }
                                                            onClick={this.toggleClassicTabs3(
                                                                  "2"
                                                            )}
                                                      >
                                                            <MDBIcon
                                                                  icon="envelope"
                                                                  size="2x"
                                                            />
                                                            <br />
                                                            Notify acquirers
                                                      </MDBNavLink>
                                                </MDBNavItem> */}
                                          </MDBNav>
                                          <MDBTabContent
                                                className="card mb-5"
                                                activeItem={
                                                      this.state
                                                            .activeItemClassicTabs3
                                                }
                                          >
                                                <MDBTabPane tabId="1">
                                                      <Parameters />
                                                </MDBTabPane>
                                                <MDBTabPane tabId="2">
                                                      <NotifyAcq />
                                                </MDBTabPane>
                                                <MDBTabPane tabId="3">
                                                      {/* <Acquirers /> */}
                                                </MDBTabPane>
                                          </MDBTabContent>
                                    </div>
                              </MDBRow>
                        </MDBAnimation>
                  </MDBContainer>
            );
      }
}

export default withRouter(Settings);
