import React from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput, } from "mdbreact";
import { withRouter } from 'react-router-dom';
import Amplify from "../api.js";


class EditTerminal extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  data: [],
                  Tid: "",
                  SerialNum: "",
                  MerchantName: "",
                  MerchantAddress1: "",
                  MerchantAddress2: "",
                  MerchantCategoryCode:"",
                  PenniesEnabled:false
            };
            this.handleSerialNum = this.handleSerialNum.bind(this);
            this.handleMerchantName = this.handleMerchantName.bind(this);
            this.handleMerchantAddress1 = this.handleMerchantAddress1.bind(this);
            this.handleMerchantAddress2 = this.handleMerchantAddress2.bind(this);
            this.handleMerchantCategoryCode=this.handleMerchantCategoryCode.bind(this);
            this.handlePenniesEnabled=this.handlePenniesEnabled.bind(this);

      }

      async getData() {
            let apiName = 'TennantAPI';
            let path = 'manageterminals/tids';
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      async getTid(Tid) {
            let apiName = 'TennantAPI';
            let path = 'manageterminals/tids/' + Tid;
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      async postData(Tid, SerialNo, MerchantName, MerchantAddress1, MerchantAddress2,MerchantCategoryCode,PenniesEnabled) {
            let apiName = 'TennantAPI';
            let path = 'manageterminals/tids/' + Tid;
            let line1 = { 'MerchantName': MerchantName, 'SerialNo': SerialNo };
            let orders = [line1];
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
                  body: { Mids: orders, 'MerchantAddress1': MerchantAddress1, 'MerchantAddress2': MerchantAddress2 ,'MerchantCategoryCode':MerchantCategoryCode,'PenniesEnabled':PenniesEnabled}
            }
            console.log('Calling ', apiName)
            console.log('Merchant Category Code:',MerchantCategoryCode)
            return await Amplify.API.post(apiName, path, myInit).then(() => { console.log('refresh',MerchantCategoryCode); this.redirect() }).catch(function (error) {
                  console.log(JSON.stringify(error))
            }
            )
      }

      // handleAlpha handles the input box
      handleSerialNum(event) {
            this.setState({ SerialNum: event.target.value });
      }
      handleMerchantCategoryCode(event){
            this.setState({MerchantCategoryCode:event.target.value});
      }
      handlePenniesEnabled(event){
            this.setState({PenniesEnabled:!this.state.PenniesEnabled});
      }

      handleMerchantName(event) {
            this.setState({ MerchantName: event.target.value });
      }

      handleMerchantAddress1(event) {
            this.setState({ MerchantAddress1: event.target.value });
      }

      handleMerchantAddress2(event) {
            this.setState({ MerchantAddress2: event.target.value });
      }

      getUsers() {
            this.getData().then(response => this.setState({ data: response })).catch(error => { console.log('Failed to get data ' + error) })
      }

      componentDidMount() {
            window.scrollTo(0, 0)
            this.getUsers();
      }

      render() {

            return (

                  <MDBContainer style={{ paddingTop: "50px" }}>

                        <div className="Manage">
                              <MDBRow>
                                    <MDBInput
                                          label="Terminal ID"
                                          group
                                          type="number"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{ padding: "5px" }}
                                          value={this.state.user}
                                          onChange={this.handleUser}
                                    />
                                    <MDBInput
                                          label="Serial No."
                                          group
                                          type="number"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{ padding: "5px" }}
                                          value={this.state.SerialNum}
                                          onChange={this.handleSerialNum}
                                    />
                                    <MDBInput
                                          label="Merchant Name"
                                          group
                                          type="text"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{ padding: "5px" }}
                                          value={this.state.MerchantName}
                                          onChange={this.handleMerchantName}
                                    />
                                    <MDBInput
                                          label="Merchant Address"
                                          group
                                          type="text"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{ padding: "5px" }}
                                          value={this.state.MerchantAddress1}
                                          onChange={this.handleMerchantAddress1}
                                    />

                                    <MDBInput
                                          label="Merchant Address"
                                          group
                                          type="text"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{ padding: "5px" }}
                                          value={this.state.MerchantAddress2}
                                          onChange={this.handleMerchantAddress2}
                                    />

                                    <MDBInput
                                          label="MerchantCategoryCode"
                                          group
                                          type="text"
                                          outline
                                          validate
                                          error="wrong"
                                          success="right"
                                          style={{padding:"5px"}}
                                          value={this.state.handleMerchantCategoryCode}
                                          onChange={this.handleMerchantCategoryCode}
                                          />
                                    <MDBBtn type="submit" color="success" rounded className="float-right" onClick={this.handleSubmit}>Submit</MDBBtn>

                                    <MDBCol md="5" style={{ justifyContent: "center" }}>

                                    </MDBCol>


                              </MDBRow>
                        </div>

                  </MDBContainer >

            );
      }
}


export default withRouter(EditTerminal);


