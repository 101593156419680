// import Products from "../Products";
// import Company from "../Company";
// import Orders from "../Orders";
// import NewOrders from "../NewOrders";
// import MyOrganisation from "../MyOrganisation";
// import Transactions from "../Transactions"
// import Batches from "../Batches"
import ManageTerminals from "../components/ManageTids";
import EditTerminal from "../components/EditTerminal";
// import OrderDetail from "../OrderDetail"
// import Developer from "../Developer"
// import EditTid from "../EditTid"
// import GeneratePIN from "../GeneratePIN"
// import EditUser from "../EditUser"
// import ChangePassword from "../ChangePassword"
// import Support from "../Support";
// import Stock from "../Stock";
// import Settings from "../Settings"
// import Login from "../Login";

export const pages = {

    home: "/",
    authproducts: "/auth/products",
    authcompany: "/auth/company",
    myorganisation: "/auth/myorganisation",
    transactions: "/auth/transactions",
    batches: "/auth/batches",
    product: "/auth/product/",
    authSupport: "/auth/support",
    adminproducts: "/admin/products",
    admincompany: "/admin/company",
    adminmyorganisation: "/admin/myorganisation",
    admintransactions: "/admin/transactions",
    orders: "/auth/orders",
    neworders: "/auth/orders/new-orders",
    editTerminal: EditTerminal,
    mTerminals: ManageTerminals,
    detail: "/auth/orderdetail",
    developer: "/auth/developer",
    parameters: "/auth/settings",
    editTid: "/auth/edit-tid",
    generatePIN: "/auth/generate-PIN",
    editUser: "/auth/edit-user",
    changePassword: "/auth/changepassword",
    stock: "/auth/stock"

};
