import Component from "./Home";
// import "rsuite/dist/styles/rsuite-default.css";//Need to change this to fix the CSS bug with the site - Jira
import { connect } from "react-redux";

export default connect(
    ({ auth: { loggedIn } }) => ({ loggedIn }),
    ({ auth: { logout } }) => ({ logout })
)(Component);


